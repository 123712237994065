import { Icons } from '@packages/sk8/icons'
import { Tooltip } from '@packages/sk8/tooltip'
import { PrestaShopOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import usePrestashopProxyService from './../../hooks/usePrestashopProxyService'

type PrestashopOnlineStoreConnectionStatusProps = {
  onlineStore: PrestaShopOnlineStore
}

const PrestashopOnlineStoreConnectionStatus = ({ onlineStore }: PrestashopOnlineStoreConnectionStatusProps) => {
  const prestashopService = usePrestashopProxyService()

  const { isError } = useQuery(
    [...prestashopService.test.queryKeys, onlineStore.id],
    () => {
      return prestashopService.test(onlineStore.id)
    },
    {
      retry: false,
    }
  )
  if (isError) {
    return (
      <Tooltip content="Connection issue">
        <Icons.AlertCircle aria-label="Connection issue" className="fill-secondary-orange-200 ml-1 relative h-4 w-4" />
      </Tooltip>
    )
  }
  return null
}

export default PrestashopOnlineStoreConnectionStatus
