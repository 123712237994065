import { Alert } from '@packages/sk8/alert'
import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Input } from '@packages/sk8/input'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Tooltip } from '@packages/sk8/tooltip'
import {
  AllowedTransform,
  LogoPart,
  PartType,
  PrintArea,
  PrintAreaLogoPositionAnswer,
  PrintAreaTextPositionAnswer,
  Question,
  TextPart,
} from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import * as answersActions from 'builder/build/answers/actions'
import { answersSelector } from 'builder/build/answers/selectors'
import Card from 'builder/build/common/components/cards/Card'
import HorizontalField from 'builder/build/common/components/HorizontalField'
import MultiIconsButton from 'builder/build/common/components/MultiIconsButton'
import Separator from 'builder/build/common/components/Separator'
import TypeIcons from 'builder/build/common/components/TypeIcons'
import VerticalFields from 'builder/build/common/components/VerticalFields'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import { bulkOrderGroupSelector } from 'builder/build/groups/selectors'
import * as partsActions from 'builder/build/parts/actions'
import * as printAreasActions from 'builder/build/printAreas/actions'
import PrintAreaPanel from 'builder/build/printAreas/components/PrintAreaPanel'
import * as printAreasSelectors from 'builder/build/printAreas/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import Switch from 'common/components/Switch'
import * as customizationActions from 'customizer/customization/actions'
import isNullOrEmpty from 'utils/isNullOrEmpty'

interface SelectPrintAreaSubMenuProps {
  printAreas: PrintArea[]
  onCreatePrintArea: React.MouseEventHandler<HTMLButtonElement>
  onChange: (printAreaId: string) => void
}

const SelectPrintAreaSubMenu = ({ printAreas, onCreatePrintArea, onChange }: SelectPrintAreaSubMenuProps) => {
  const [filter, setFilter] = useState('')

  const filteredPrintArea = printAreas.filter(printArea => printArea.name.includes(filter))

  return (
    <div className="w-[224px]">
      <div className="p-4">
        <MultiIconsButton onClick={onCreatePrintArea} Icon={<Icons.Printer className="w-3.5" />}>
          Add print area
        </MultiIconsButton>
      </div>
      <Separator>or</Separator>
      <div className="p-4 space-y-2">
        <Input
          placeholder="Search print areas..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          rightAddon={
            filter !== '' && (
              <IconButton
                small
                variant="subtle"
                className="-mr-2"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setFilter('')
                }}
                Icon={Icons.XMark}
              />
            )
          }
        />
      </div>
      <div className="flex flex-col max-h-[400px] overflow-y-auto space-y-2 pb-4">
        {filteredPrintArea.map(printArea => (
          <Card className="mx-2" key={printArea.id} onClick={() => onChange(printArea.id)}>
            <TypeIcons entity={printArea} />
            <span className="ml-2">{printArea.name}</span>
          </Card>
        ))}
      </div>
    </div>
  )
}

interface PrintAreaSectionProps {
  part: LogoPart | TextPart
  question: Question
  isClosing?: boolean
}

const PrintAreaSection = ({ part, question, isClosing }: PrintAreaSectionProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const history = useHistory()
  const location = useLocation()

  const match = useRouteMatch('/questions/:id/print-area')

  const printAreas = useSelector(printAreasSelectors.printAreasSelector)
  const printArea = printAreas[part.printArea!]
  const dropdownButton = usePopover({ placement: 'bottom-end' })
  const answers = useSelector(answersSelector)
  const bulkOrderGroup = useSelector(bulkOrderGroupSelector)
  const position = answers[part.position!] as PrintAreaLogoPositionAnswer | PrintAreaTextPositionAnswer
  const disableTransforms = part.type === PartType.Text && !(position as PrintAreaTextPositionAnswer).position?.shape
  const isBulkOrderQuestion = !!bulkOrderGroup?.children.includes(question.id)
  const isPrintAreaOpen = match?.isExact
  const setIsPrintAreaOpen = (isOpen: boolean) => {
    if (isOpen) return history.push(location.pathname + '/print-area')
    history.push(location.pathname.replace('/print-area', ''))
  }

  const handleCreatePrintArea: React.MouseEventHandler<HTMLButtonElement> = async event => {
    dropdownButton.close()
    event.stopPropagation()
    customizerDispatch(customizationActions.stopEditPart())
    const { payload } = dispatch(printAreasActions.addPrintArea(part.id))
    dispatch(partsActions.convertPartPositionToPrintAreaPosition(part, payload.printArea))
    setIsPrintAreaOpen(true)
  }

  const handleSelectPrintArea = (id: string) => {
    customizerDispatch(customizationActions.stopEditPart())
    dropdownButton.close()

    if (!part.printArea) {
      dispatch(partsActions.convertPartPositionToPrintAreaPosition(part, printAreas[id]))
      dispatch(
        partsActions.patchPart(part.id, {
          printArea: id,
          allowedTransforms: { move: false, resize: false, rotate: false },
        })
      )
    }
  }

  const toggleAllowedTransforms = (transformName: AllowedTransform) => {
    dispatch(
      partsActions.patchPart(part.id, {
        allowedTransforms: { [transformName]: !part.allowedTransforms?.[transformName] },
      })
    )
  }

  const handleUpdateAllowedMove = () => {
    toggleAllowedTransforms(AllowedTransform.Move)
  }

  const handleUpdateAllowedResize = () => {
    toggleAllowedTransforms(AllowedTransform.Resize)

    if (part.type === PartType.Text) {
      dispatch(
        answersActions.patchAnswer(position, {
          position: part.allowedTransforms?.resize
            ? { maxWidth: 250, maxHeight: 250 }
            : { maxWidth: null, maxHeight: null },
        })
      )
    }
  }

  const handleUpdateAllowedRotate = () => {
    toggleAllowedTransforms(AllowedTransform.Rotate)
  }

  const handleRemovePrintArea: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation()
    setIsPrintAreaOpen(false)
    customizerDispatch(customizationActions.stopEditPart())
    dispatch(partsActions.removePrintAreaFromParts(printArea.id, [part]))
  }

  return (
    <>
      <div className="flex justify-between items-center space-x-2 mt-4">
        <div className="fill-neutral-600 flex flex-col items-center justify-center text-[10px] shrink-0">
          <Icons.ArrowRightDown className="w-4 h-4 -rotate-90 -scale-x-100" />
          Apply on
        </div>
        {printArea ? (
          <div
            className={classNames(
              'bg-neutral-75 hover:bg-neutral-100 h-8 rounded-md flex items-center text-xs flex-grow pl-2 pr-1 hover:cursor-pointer',
              {
                'text-primary-500 font-medium': isPrintAreaOpen,
              }
            )}
            title={printArea.name}
            onClick={() => setIsPrintAreaOpen(true)}
          >
            <TypeIcons entity={printArea} />
            <div className="ml-2 mr-auto">{printArea.name}</div>
            <IconButton variant="subtle" onClick={handleRemovePrintArea} Icon={Icons.XMark} small />
          </div>
        ) : isNullOrEmpty(printAreas) ? (
          <MultiIconsButton
            variant="subtle"
            Icon={<Icons.Printer className="w-5 p-1 rounded bg-neutral-75" />}
            onClick={handleCreatePrintArea}
          >
            Print area
          </MultiIconsButton>
        ) : (
          <>
            <MultiIconsButton
              variant="subtle"
              Icon={<Icons.Printer className="w-5 p-1 rounded bg-neutral-75" />}
              {...dropdownButton.referenceProps}
            >
              Print area
            </MultiIconsButton>
            <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen} className="builder">
              <SelectPrintAreaSubMenu
                printAreas={Object.values(printAreas)}
                onChange={handleSelectPrintArea}
                onCreatePrintArea={handleCreatePrintArea}
              />
            </Popover>
          </>
        )}
      </div>
      {part.printArea != null && (
        <>
          {isBulkOrderQuestion && (
            <Alert variant="info" className="mt-4">
              <Alert.Body>Bulk order experience</Alert.Body>
              <Alert.Details className="mb-4">All items will have the same position and size.</Alert.Details>
            </Alert>
          )}

          <VerticalFields className="mt-6">
            <label className="font-medium">Select allowed transforms</label>
            <Tooltip
              content="These settings are only possible with multiline text, for now."
              disabled={!disableTransforms}
            >
              <HorizontalField>
                <label className="flex items-center space-x-2">
                  <Icons.Move className="w-3.5 h-3.5" />
                  <span>Move</span>
                </label>
                <Switch
                  disabled={disableTransforms}
                  checked={!!part.allowedTransforms?.move}
                  onChange={handleUpdateAllowedMove}
                />
              </HorizontalField>
            </Tooltip>
            <Tooltip
              content="These settings are only possible with multiline text, for now."
              disabled={!disableTransforms}
            >
              <HorizontalField>
                <label className="flex items-center space-x-2">
                  <Icons.Resize className="w-3.5 h-3.5" />
                  <span>Resize</span>
                </label>
                <Switch
                  disabled={disableTransforms}
                  checked={!!part.allowedTransforms?.resize}
                  onChange={handleUpdateAllowedResize}
                />
              </HorizontalField>
            </Tooltip>
            <Tooltip
              content="These settings are only possible with multiline text, for now."
              disabled={!disableTransforms}
            >
              <HorizontalField>
                <label className="flex items-center space-x-2">
                  <Icons.Rotate className="w-3.5 h-3.5" />
                  <span>Rotate</span>
                </label>
                <Switch
                  disabled={disableTransforms}
                  checked={!!part.allowedTransforms?.rotate}
                  onChange={handleUpdateAllowedRotate}
                />
              </HorizontalField>
            </Tooltip>
          </VerticalFields>
        </>
      )}
      {printArea && isPrintAreaOpen && !isClosing && (
        <PrintAreaPanel printArea={printArea} onClose={() => setIsPrintAreaOpen(false)} />
      )}
    </>
  )
}

export default PrintAreaSection
