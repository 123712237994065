import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

export interface ActivateAppEmbedProps {
  isCompleted: boolean
  onlineStoreUrl: string
}

const ActivateAppEmbed = ({ onlineStoreUrl, isCompleted }: ActivateAppEmbedProps) => {
  const popover = usePopover({ placement: 'right', offsetConfig: 32, useArrow: true })

  return (
    <>
      <button
        {...popover.referenceProps}
        className={classNames('font-p2 flex items-center justify-between h-4', {
          'text-tertiary-green-300': isCompleted,
          'text-primary-600 font-bold': popover.isOpen,
        })}
        disabled={isCompleted}
      >
        4. Activate Shopify app embed
        {isCompleted && <Icons.CheckCircle className="w-4 h-4 fill-tertiary-green-300" aria-label="completed" />}
      </button>
      <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="!w-80 shadow-lg">
        <div>
          <div className="flex flex-col p-4 space-y-4">
            <div className="w-[45px] h-[45px] rounded-lg bg-neutral-75 p-2">
              <Icons.OnlineShop />
            </div>
            <span className="text-xs font-medium text-neutral-600">
              Activate Kickflip's App Embed in your Shopify main theme to complete your setup.
            </span>
          </div>
          <div className="px-4 py-3 flex justify-end border-t border-t-neutral-75">
            <Link to={onlineStoreUrl}>
              <Button type="button" variant="primary" className="px-4">
                Activate
              </Button>
            </Link>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default ActivateAppEmbed
