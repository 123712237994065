import { Icons } from '@packages/sk8/icons'
import { Table } from '@packages/sk8/table'
import { InventoryManagementStrategy, Variant } from '@packages/types'
import classNames from 'classnames'
import { useFlags } from 'flagsmith/react'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import ContinueSellingTag from 'builder/variants/components/kickflip/tags/ContinueSellingTag'
import { BulkSelection } from 'common/hooks/useBulkSelection'

import type { InventoryFormikContext } from './../types/form'
import type { GetVariantsInput } from './../types/queries'
import AvailableHeader from './AvailableHeader'
import AvailableQuantity from './AvailableQuantity'
import BulkAdjustButton from './BulkAdjustButton'
import CommittedHeader from './CommittedHeader'
import SaveRowButton from './SaveRowButton'
import SelectRowCell from './SelectRowCell'
import SharedCell from './SharedCell'
import StockCellWithSharedData from './StockCellWithSharedData'
import TotalHeader from './TotalHeader'

type ColumnDef = {
  key: string
  title: (formik: InventoryFormikContext, bulkSelection: BulkSelection, filteredVariants: Variant[]) => React.ReactNode
  style?: React.CSSProperties
  className: string
  cellClassName?: string
  render: (
    variant: Variant,
    formik: InventoryFormikContext,
    bulkSelection: BulkSelection,
    focusedSku?: string
  ) => React.ReactNode
  isSortable: boolean
}

const useInventoryColumns = (
  locationId: string,
  getVariantsInput: GetVariantsInput,
  notScrolled: boolean,
  fullyScrolled: boolean
) => {
  const flags = useFlags(['inventory_management'])

  return useMemo((): ColumnDef[] => {
    const baseColumnDefs: ColumnDef[] = [
      {
        key: 'inventoryItem',
        title: () => 'Inventory item',
        className: 'w-[260px] min-w-[260px]',
        cellClassName: 'font-medium',
        render: variant => {
          return <span>{variant.name}</span>
        },
        isSortable: true,
      },
      {
        key: 'combinationName',
        title: () => 'Combination',
        className: 'w-[220px] min-w-[220px]',
        cellClassName: 'text-xs text-neutral-400 w-[220px] max-w-[220px] overflow-hidden',
        render: variant => {
          return (
            <Link
              to={`products/${variant.product}/variants?combination=${variant.answersRefs
                .map(answerRef => answerRef.questionId)
                .join(',')}`}
              className="max-w-full"
            >
              <div className="inline-flex space-x-1 items-center group/combination w-full overflow-hidden">
                <div className="group-hover/combination:text-primary-500 whitespace-nowrap text-ellipsis max-w-full overflow-hidden">
                  {variant.combinationName}
                </div>
                <Icons.OpenLink className="shrink-0 w-2.5 h-2.5 text-neutral-300 group-hover/combination:text-primary-500" />
              </div>
            </Link>
          )
        },

        isSortable: true,
      },
      {
        key: 'product',
        title: () => 'Product',
        className: 'w-[220px] min-w-[220px]',
        cellClassName: 'text-xs text-neutral-400',
        render: variant => {
          return <span>{variant.productName}</span>
        },

        isSortable: true,
      },
      {
        key: 'spacing',
        title: () => '',
        className: '!p-0 w-full',
        render: () => null,
        isSortable: false,
      },
    ]

    if (
      flags.inventory_management.enabled &&
      flags.inventory_management.value === InventoryManagementStrategy.Shopify
    ) {
      return [
        {
          key: 'filler-spacing',
          title: () => '',
          className: 'pl-2 w-[32px] min-w-[32px]',
          render: () => null,
          isSortable: false,
        },
        ...baseColumnDefs,
      ]
    }

    return [
      {
        key: 'selected',
        title: (formik, bulkSelection, filteredVariants) => {
          return (
            <Table.BulkSelectionHeader bulkSelection={bulkSelection} disabled={filteredVariants.length === 0}>
              <BulkAdjustButton ids={bulkSelection.selectedIds} formik={formik} locationId={locationId} />
            </Table.BulkSelectionHeader>
          )
        },
        style: {},
        className: classNames('pl-6 w-[58px] min-w-[58px] sticky left-0 bg-white', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:right-0 after:h-full before:h-full before:top-0 before:right-0 before:w-2 before:absolute before:shadow-[6px_0px_12px_rgba(0,0,0,0.05)]':
            notScrolled !== true,
        }),
        cellClassName: classNames('pl-6 sticky left-0 bg-white', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:right-0 after:h-full before:h-full before:top-0 before:right-0 before:w-2 before:absolute before:shadow-[6px_0px_12px_rgba(0,0,0,0.05)]':
            notScrolled !== true,
        }),
        render: (variant, _formik, bulkSelection) => {
          return <SelectRowCell variant={variant} bulkSelection={bulkSelection} />
        },
        isSortable: false,
      },
      ...baseColumnDefs,
      {
        key: 'shared',
        title: () => null,
        className: 'min-w-[0.75rem] w-3 sticky right-0 bg-white !p-0',
        cellClassName: 'w-3 !p-0',
        render: (variant: Variant, _formik, _bulkSelection, focusedSku) => {
          return <SharedCell sku={variant.inventoryItem.sku} focusedSku={focusedSku} />
        },
        isSortable: false,
      },
      {
        key: 'sku',
        title: () => 'SKU',
        className: 'w-[152px] min-w-[152px]',
        cellClassName: 'text-neutral-400',
        render: (variant: Variant, _formik, _bulkSelection, focusedSku) => {
          return (
            <span className={classNames({ 'text-primary-500': focusedSku === variant.inventoryItem.sku })}>
              {variant.inventoryItem.sku ? variant.inventoryItem.sku : <Table.EmptyCellIndicator />}
            </span>
          )
        },

        isSortable: false,
      },
      {
        key: 'inventory-status',
        title: () => '',
        className: 'w-[152px] min-w-[152px]',
        cellClassName: 'pr-6',
        render: variant => {
          return <span className="flex space-x-1">{variant.continueSelling ? <ContinueSellingTag /> : null}</span>
        },

        isSortable: false,
      },
      {
        key: 'commmitted',
        title: () => <CommittedHeader />,
        className: 'w-[152px] min-w-[152px]',
        render: (variant, formik) => {
          const inventoryItem = formik.values[variant.inventoryItem.id]

          if (inventoryItem?.stock == null) return <Table.EmptyCellIndicator />

          return <div className="flex space-x-1">{inventoryItem.committed}</div>
        },

        isSortable: false,
      },
      {
        key: 'available',
        title: () => <AvailableHeader />,
        className: 'w-[152px] min-w-[152px]',
        render: (variant, formik) => {
          const inventoryItem = formik.values[variant.inventoryItem.id]

          return <AvailableQuantity inventoryItem={inventoryItem} />
        },
        isSortable: false,
      },
      {
        key: 'stock',
        title: () => <TotalHeader />,
        className: classNames('pl-2 w-[152px] min-w-[152px] sticky right-0 bg-white', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:left-0 after:h-full before:h-full before:top-0 before:left-0 before:w-2 before:absolute before:shadow-[-6px_0px_12px_rgba(0,0,0,0.05)]':
            fullyScrolled === false && !(notScrolled && fullyScrolled),
        }),
        cellClassName: classNames('pl-2 sticky right-0 bg-white focus-within:z-[2] ', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:left-0 after:h-full before:h-full before:top-0 before:left-0 before:w-2 before:absolute before:shadow-[-6px_0px_12px_rgba(0,0,0,0.05)]':
            fullyScrolled === false && !(notScrolled && fullyScrolled),
        }),
        render: (variant, formik) => {
          const inventoryItem = formik.values[variant.inventoryItem.id]

          return (
            <div className="flex space-x-1">
              <StockCellWithSharedData
                variantId={variant.id}
                initialValue={formik.initialValues[variant.inventoryItem.id]?.stock ?? null}
                sku={inventoryItem?.sku}
                value={inventoryItem?.stock}
                onChange={stock => {
                  formik.setFieldTouched(`${inventoryItem.id}.stock`)
                  formik.setFieldValue(`${inventoryItem.id}.stock`, stock)
                }}
              />
              <SaveRowButton
                locationId={locationId}
                getVariantsInput={getVariantsInput}
                variant={variant}
                formik={formik}
              />
            </div>
          )
        },
        isSortable: false,
      },
    ]
  }, [
    locationId,
    getVariantsInput,
    notScrolled,
    fullyScrolled,
    flags.inventory_management.enabled,
    flags.inventory_management.value,
  ])
}

export default useInventoryColumns
