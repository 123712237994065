import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { useModal } from '@packages/sk8/modal'
import React, { useState } from 'react'

import BulkUnsavedChangesModal from 'common/components/BulkUnsavedChangesModal'
import { trpc } from 'common/hooks/trpc'

import StockAdjustModal from './../modals/StockAdjustModal'
import type { InventoryFormikContext } from './../types/form'

interface BulkAdjustButtonProps {
  ids: string[]
  locationId: string
  formik: InventoryFormikContext
}

const BulkAdjustButton = ({ ids, formik, locationId }: BulkAdjustButtonProps) => {
  const stockAdjustModal = useModal()
  const bulkUnsavedChangesModal = useModal()
  const [bulkAction, setBulkAction] = useState<undefined | (() => void)>()

  const trpcContext = trpc.useContext()

  const handleBulkActionClick = (bulkAction: () => void) => {
    if (formik.dirty) {
      setBulkAction(() => bulkAction)
      bulkUnsavedChangesModal.open()
    } else {
      bulkAction()
    }
  }

  const handleSaveSuccess = () => {
    formik.setStatus('submitted')
    trpcContext.variant.get.invalidate()
    trpcContext.variant.getByIds.invalidate()
  }

  return (
    <>
      <Button
        tabIndex={-1}
        variant="subtle"
        small
        icon={<Icons.SettingSlider className="w-4 h-4 fill-neutral-500" />}
        onClick={() => handleBulkActionClick(stockAdjustModal.open)}
      >
        Update stocks
      </Button>
      {stockAdjustModal.isVisible && (
        <StockAdjustModal
          modalProps={stockAdjustModal.modalProps}
          handleClose={stockAdjustModal.close}
          ids={ids}
          locationId={locationId}
          onSuccess={handleSaveSuccess}
        />
      )}
      {bulkUnsavedChangesModal.isVisible && (
        <BulkUnsavedChangesModal
          onCancelClick={() => {
            setBulkAction(undefined)
            bulkUnsavedChangesModal.close()
          }}
          onContinueClick={() => {
            setBulkAction(undefined)
            bulkAction?.()
            bulkUnsavedChangesModal.close()
          }}
          modalProps={bulkUnsavedChangesModal.modalProps}
        />
      )}
    </>
  )
}

export default BulkAdjustButton
