import { BlankState } from '@packages/sk8/blank-state'
import React from 'react'

import { Icons } from '@packages/sk8/icons'

export type ActiveBlankStateProps = {
  onClick: () => void
}

const ActiveBlankState = ({ onClick }: ActiveBlankStateProps) => {
  return (
    <>
      <BlankState.Icon Icon={Icons.Skateboard} className="text-neutral-300" />
      <BlankState.Title>There are no products, yet</BlankState.Title>
      <BlankState.Details>
        Create your first product from scratch or explore all the features and possibilities with our demos.
      </BlankState.Details>
      <BlankState.Action onClick={onClick} aria-label="product-tour-hint">
        Create product
      </BlankState.Action>
    </>
  )
}

export default ActiveBlankState
