import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { Icons } from '@packages/sk8/icons'
import { Spinner } from '@packages/sk8/spinner'
import { ToastType, useToast } from '@packages/sk8/toast'
import { CustomizationDesign, PrintFilesRequestStatus } from '@packages/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'

import useCurrentUser from 'common/users/hooks/useCurrentUser'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import useDesignFiles from './../hooks/useDesignFiles'
import useDesignService from './../hooks/useDesignService'

interface DesignCardAssetsSectionProps {
  designId: string
}

const DesignCardAssetsSection = ({ designId }: DesignCardAssetsSectionProps) => {
  const { openToast, openGenericErrorToast } = useToast()
  const designService = useDesignService()
  const user = useCurrentUser()

  const {
    data: design,
    isLoading: isLoadingDesign,
    refetch: refetchDesign,
  } = useQuery(
    [...designService.fetch.queryKeys, designId],
    () => designService.fetch(designId) as Promise<CustomizationDesign>,
    {
      refetchInterval: result => (result?.printFilesRequestStatus === PrintFilesRequestStatus.Pending ? 5000 : false),
    }
  )

  const { download, generateAllDesignImages } = useDesignFiles({ design })

  const { mutate: regenerateAssets, isLoading: isRegeneratingAssets } = useMutation(async () => {
    if (!design) return

    await Promise.all([
      generateAllDesignImages(design),
      ...(!isNullOrEmpty(design.prints) ? [designService.generatePrintFiles(design!.id)] : []),
    ])

    refetchDesign()
  })

  const { mutate: downloadAssets, isLoading: isDownloadingAssets } = useMutation(download, {
    onSuccess: () => openToast('Design assets were successfully downloaded!', ToastType.success),
    onError: () => openGenericErrorToast('Design assets were not downloaded.'),
  })

  const isCreatingPrintFiles = design?.printFilesRequestStatus === PrintFilesRequestStatus.Pending
  const hasPrintError = design?.printFilesRequestStatus === PrintFilesRequestStatus.Error

  return (
    <Card.Section className="w-full md:w-[300px] md:flex-col p-0 m-6 print:w-full">
      {isLoadingDesign && <div className="animate-pulse h-64 bg-neutral-75 rounded max-w-xs w-full" />}
      {!isLoadingDesign && design && (
        <>
          <div className="relative flex justify-center pb-6 border-b border-neutral-100 print:border-b-0 print:pb-0">
            <img aria-label={`design ${design.productId} image`} src={design.designImage.url} className="rounded-lg" />
            {hasPrintError && (
              <Alert variant="error" className="absolute bottom-6 print:hidden">
                <Alert.Body>
                  <Alert.Title>An error occured while generating print files</Alert.Title>
                </Alert.Body>
              </Alert>
            )}
          </div>

          <div className="py-6 space-y-6 print:hidden">
            {isCreatingPrintFiles || isRegeneratingAssets || isDownloadingAssets ? (
              <Button icon={<Spinner className="w-4 h-4 fill-white" />} variant="primary" disabled>
                {isDownloadingAssets ? 'Downloading assets' : 'Creating assets'}
              </Button>
            ) : (
              <Button
                variant={hasPrintError ? 'default' : 'primary'}
                icon={<Icons.Download className="w-4 h-4 fill-white" />}
                onClick={() => downloadAssets()}
              >
                {hasPrintError ? 'Download assets (incomplete)' : 'Download assets'}
              </Button>
            )}
            {user.isMCZRUser && (
              <>
                {isRegeneratingAssets && (
                  <Button icon={<Spinner className="w-4 h-4 fill-white" />} disabled>
                    Regenerating assets
                  </Button>
                )}
                {!isRegeneratingAssets && (
                  <Button icon={<Icons.SyncArrows className="w-4 h-4 fill-black" />} onClick={() => regenerateAssets()}>
                    Regenerate assets
                  </Button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Card.Section>
  )
}

export default DesignCardAssetsSection
