import { Icons } from '@packages/sk8/icons'
import React, { forwardRef } from 'react'

import Card from 'builder/build/common/components/cards/Card'
import CardList, { ReorderDetails } from 'builder/build/common/components/cards/CardList'
import { useDispatch } from 'cms/hooks'
import classMerge from 'utils/classMerge'

import { moveLayer } from '../actions'
import { PrintAreaLayer } from '../types'
import PartLayerCard from './PartLayerCard'

export type PrintAreaLayerCardProps = {
  isDragged: boolean
  isDragOverlay: boolean
  layer: PrintAreaLayer
}

const PrintAreaLayerCard = forwardRef<HTMLDivElement, PrintAreaLayerCardProps>(
  ({ isDragged, isDragOverlay, layer, ...props }, ref) => {
    const dispatch = useDispatch()

    const handleReorder = (reorderDetails: ReorderDetails) => dispatch(moveLayer(reorderDetails))

    return (
      <div ref={ref} {...props}>
        <Card className={classMerge('bg-white mx-2', { 'opacity-50': isDragged, 'opacity-80': isDragOverlay })}>
          <Icons.Printer className="w-5 h-5 rounded p-1 mr-2 bg-neutral-75" aria-label="print area icon" />
          <span>{layer?.name}</span>
        </Card>
        <CardList
          className={classMerge('space-y-2 ml-6', { 'opacity-80': isDragOverlay })}
          onReorder={handleReorder}
          CardComponent={PartLayerCard}
          items={layer.children.map(layer => ({ id: layer.id, props: { layer } }))}
        />
      </div>
    )
  }
)

export default PrintAreaLayerCard
