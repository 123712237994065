import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Tooltip } from '@packages/sk8/tooltip'
import { Part, PartType } from '@packages/types'
import React from 'react'

import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import * as displayerSelectors from 'builder/build/editor/selectors'
import * as previewSelectors from 'builder/build/preview/selectors'
import * as printAreasSelectors from 'builder/build/printAreas/selectors'
import { useSelector } from 'cms/hooks'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'

import ExtraSettings from './ExtraSettings'
import TextDefaultSettings from './TextDefaultSettings'
import TextPositionSettings from './TextPositionSettings'
import VisibilitySetting from './VisibilitySetting'

interface VisualEditorActionBarProps {
  part: Part
  onCloseClick: () => void
  onMoveClick: () => void
}

const VisualEditorActionBar = ({ part, onCloseClick, onMoveClick }: VisualEditorActionBarProps) => {
  const currentView = customizerHooks.useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const isPreviewReady = useSelector(previewSelectors.isPreviewReadySelector)
  const printAreas = useSelector(printAreasSelectors.printAreasSelector)
  const printArea = part.printArea ? printAreas[part.printArea] : null
  const partVisibility = useSelector(state =>
    displayerSelectors.visibilitySelector(state, printArea ? '' : part.id, currentView)
  )
  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)
  const printAreaVisibility = !printArea?.productPreview.views[currentView].hidden
  const visibility = printArea != null ? printAreaVisibility : partVisibility

  return (
    <>
      <VisibilitySetting part={part} />
      {isPreviewReady && part.type === PartType.Text && (
        <>
          <TextPositionSettings part={part} currentView={currentView} disableAll={!visibility} />
          <div className="h-8 w-[1px] bg-neutral-75 ml-1 mr-1" />
          <TextDefaultSettings part={part} disableAll={!visibility} />
        </>
      )}
      {part.type !== PartType.Logo && <div className="h-8 w-[1px] bg-neutral-75 ml-2 mr-1" />}
      <ExtraSettings part={part} visibility={!!visibility} />
      <Tooltip content={isEditing ? 'Stop editing' : 'Edit'}>
        <IconButton
          variant="subtle"
          onClick={isEditing ? onCloseClick : onMoveClick}
          Icon={isEditing ? Icons.Checkmark : Icons.Move}
          smallIcon={isEditing}
          disabled={printArea ? printArea.productPreview.designView !== currentView : false}
          aria-label={isEditing ? 'Stop editing question' : 'Edit question'}
        />
      </Tooltip>
    </>
  )
}

export default VisualEditorActionBar
