import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { NormalizedCustomizerProduct } from '@packages/types'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'

import { BulkSelection } from 'common/hooks/useBulkSelection'

import { PricingFormValues } from '../types'
import DeleteExtraPriceModal from './DeleteExtraPriceModal'
import ExtraPricesUnsavedChangesModal from './ExtraPricesUnsavedChangesModal'
import UpdateExtraPricesModal from './UpdateExtraPricesModal'

interface BulkExtraPriceButtonProps {
  bulkSelection: BulkSelection
  customizerProduct: NormalizedCustomizerProduct
}

const BulkExtraPriceButton = ({ bulkSelection, customizerProduct }: BulkExtraPriceButtonProps) => {
  const { dirty } = useFormikContext<PricingFormValues>()
  const [bulkAction, setBulkAction] = useState<undefined | (() => void)>()
  const popover = usePopover({ placement: 'bottom-start' })
  const updateExtraPricesModal = useModal()
  const deleteExtraPriceModal = useModal()
  const extraPricesUnsavedChangesModal = useModal()

  const withClose = (callback: () => void) => () => {
    callback()
    popover.close()
  }

  const handleBulkActionClick = (bulkAction: () => void) => {
    if (dirty) {
      setBulkAction(() => bulkAction)
      extraPricesUnsavedChangesModal.open()
    } else {
      bulkAction()
    }
  }
  return (
    <>
      <Button
        tabIndex={-1}
        variant="subtle"
        small
        icon={<Icons.VerticalEllipsis className="w-2.5 h-2.5" />}
        {...popover.referenceProps}
      >
        Bulk action
      </Button>
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        <Popover.Action onClick={withClose(() => handleBulkActionClick(updateExtraPricesModal.open))}>
          Update extra prices
        </Popover.Action>
        <Popover.Action onClick={withClose(() => handleBulkActionClick(deleteExtraPriceModal.open))}>
          Delete extra prices
        </Popover.Action>
      </Popover>
      {updateExtraPricesModal.isVisible && (
        <UpdateExtraPricesModal
          modalProps={updateExtraPricesModal.modalProps}
          handleClose={updateExtraPricesModal.close}
          customizerProduct={customizerProduct}
          selectedIds={bulkSelection.selectedIds}
        />
      )}
      {deleteExtraPriceModal.isVisible && (
        <DeleteExtraPriceModal
          modalProps={deleteExtraPriceModal.modalProps}
          handleClose={deleteExtraPriceModal.close}
          selectedIds={bulkSelection.selectedIds}
        />
      )}
      {extraPricesUnsavedChangesModal.isVisible && (
        <ExtraPricesUnsavedChangesModal
          onCancelClick={() => {
            setBulkAction(undefined)
            extraPricesUnsavedChangesModal.close()
          }}
          onContinueClick={() => {
            setBulkAction(undefined)
            bulkAction?.()
            extraPricesUnsavedChangesModal.close()
          }}
          modalProps={extraPricesUnsavedChangesModal.modalProps}
        />
      )}
    </>
  )
}

export default BulkExtraPriceButton
