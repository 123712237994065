import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { ToastType, useToast } from '@packages/sk8/toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useContext } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { ArrayParam, DateParam, NumberParam, StringParam, withDefault } from 'use-query-params'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import useOrderService from 'cms/orders/hooks/useOrderService'
import { defaultPersistenceState } from 'cms/orders/utils'
import type { Paginated } from 'common/api/types/pagination'
import useDataTableQuery from 'common/hooks/dataTable/persistence/useDataTableQuery'
import useDataTable from 'common/hooks/dataTable/useDataTable'
import useTableDateFilters from 'common/hooks/dataTable/useTableDateFilters'
import useTableFilters from 'common/hooks/dataTable/useTableFilters'
import useTablePagination from 'common/hooks/dataTable/useTablePagination'
import useTableSearch from 'common/hooks/dataTable/useTableSearch'
import useTableSort from 'common/hooks/dataTable/useTableSort'
import TenantContext from 'common/tenant/TenantContext'
import downloadContent from 'utils/downloadContent'

import { OrdersFilters, OrdersSortKeys } from '../types/datatable'
import type { Order } from './../types/order'
import OrdersEmptyBlankState from './OrdersEmptyBlankState'
import OrdersFilterList from './OrdersFilterList'
import OrdersTable from './OrdersTable'

const Orders = () => {
  const { openToast, openGenericErrorToast } = useToast()
  const tenant = useContext(TenantContext)
  const orderService = useOrderService()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const persistence = useDataTableQuery(defaultPersistenceState, {
    startDate: DateParam,
    endDate: DateParam,
    count: withDefault(NumberParam, 25),
    lastIndex: withDefault(NumberParam, 0),
    filter: withDefault(StringParam, ''),
    sortKey: StringParam,
    sortOrder: StringParam,
    paymentStatus: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
    onlineStores: withDefault(ArrayParam, []),
    various: withDefault(ArrayParam, []),
  })

  const goToFirstPage = () => persistence.setState(state => ({ ...state, lastIndex: 0 }))

  const dataTable = useDataTable(persistence, [
    useTableSearch({ onChange: goToFirstPage }),
    useTableSort<OrdersSortKeys>({ onChange: goToFirstPage }),
    useTablePagination(),
    useTableFilters<OrdersFilters>({ onChange: goToFirstPage }),
    useTableDateFilters({ onChange: goToFirstPage }),
  ])

  const { mutate: handleExport } = useMutation(() => orderService.export(persistence.state), {
    onSuccess: res => {
      const exportContentType = 'text/csv'
      const exportFileName = `${tenant}_orders.csv`

      downloadContent(res, exportContentType, exportFileName)
      openToast(`Orders were successfully exported!`, ToastType.success)
    },
    onError: () => openGenericErrorToast(`Orders were not exported.`),
  })

  const {
    data: orders,
    isLoading,
    isFetching,
  } = useQuery(
    [...orderService.fetchAll.queryKeys, persistence.state],
    () => orderService.fetchAll(persistence.state) as Promise<Paginated<Order>>,
    {
      keepPreviousData: true,
      onSuccess: result => {
        dataTable.setCollectionSize(result.pagination.collectionSize)
        dataTable.setResultSize(result.pagination.resultSize)
      },
    }
  )

  const isBlank = !isLoading && !orders?.results.length && !location.search

  const handleRowClick = (order: Order) => history.push(`${match.url}/${order.id}`, { persistence: persistence.state })

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <Page.Header>
          <h1>Orders</h1>
          {!isBlank && (
            <Button icon={<Icons.Download className="w-3 h-3" />} onClick={() => handleExport()}>
              Export
            </Button>
          )}
        </Page.Header>

        {!isBlank && (
          <OrdersFilterList clearAll={persistence.reset} hasAppliedFilters={!!location.search} {...dataTable} />
        )}

        {isBlank && <OrdersEmptyBlankState />}

        <OrdersTable
          data={orders}
          isLoading={isLoading}
          isFetching={isFetching}
          onOrderClick={handleRowClick}
          onClearFilters={persistence.reset}
          hasAppliedFilters={!!location.search}
          {...dataTable}
        />
      </Page>
    </main>
  )
}

export default Orders
