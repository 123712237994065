import { Icons } from '@packages/sk8/icons'
import { DenormalizedQuestion, EntityType } from '@packages/types'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import AnswersDimensionsWarning from 'builder/build/answers/components/AnswersDimensionsWarning'
import TypeIcons from 'builder/build/common/components/TypeIcons'
import * as partsSelectors from 'builder/build/parts/selectors'
import * as printAreaSelectors from 'builder/build/printAreas/selectors'
import { useSelector } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'

import QuestionActionsPopover from './QuestionActionsPopover'
export interface QuestionTreeItemProps {
  question: DenormalizedQuestion
}

const QuestionTreeItem = ({ question }: QuestionTreeItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const history = useHistory()
  const match = useRouteMatch(`/questions/${question.id}`)
  const isSelected = !!match
  const part = useSelector(state => partsSelectors.partFromQuestionIdSelector(state, question.id))
  const printArea = useSelector(state => printAreaSelectors.printAreaSelector(state, part?.printArea))
  const isMainQuestion = !!part && constants.parts.mainQuestionFields[part.type] === question.type

  const hasPartSubtitle = !isMainQuestion && part?.name
  const hasPrintAreaSubtitle = isMainQuestion && printArea?.name
  const hasSubtitle = hasPartSubtitle || hasPrintAreaSubtitle

  const handleClick = () => history.push(`/questions/${question.id}`)

  useEffect(() => {
    if (isSelected && ref.current) ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' })
  }, [isSelected])

  return (
    <div
      ref={ref}
      className={classNames(
        'flex pl-[9px] py-[9px] pr-2 relative w-full group hover:bg-neutral-50 aria-selected:bg-neutral-75',
        {
          'items-center': !hasSubtitle,
        }
      )}
      onClick={handleClick}
      aria-selected={isSelected}
    >
      <TypeIcons entity={question} />
      <div className="w-full ml-2.5 text-xs overflow-hidden">
        <div className="h-5 flex items-center overflow-hidden">
          <span className='whitespace-nowrap text-ellipsis overflow-hidden group-[[aria-selected="true"]]:font-medium'>
            {question.name}
          </span>
        </div>
        {hasPartSubtitle && (
          <div className="flex items-center overflow-hidden">
            <Icons.ArrowRightDown
              className={classNames('flex-shrink-0 w-2.5 h-2.5 -rotate-90 scale-x-[-1] mr-[4px]', {
                'fill-current': isSelected,
                'fill-neutral-600': !isSelected,
              })}
            />
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">{part.name}</span>
          </div>
        )}
        {hasPrintAreaSubtitle && (
          <div className="flex items-center overflow-hidden">
            <Icons.ArrowRightDown
              className={classNames('flex-shrink-0 w-2.5 h-2.5 -rotate-90 scale-x-[-1] mr-[4px]', {
                'fill-current': isSelected,
                'fill-neutral-600': !isSelected,
              })}
            />
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">{printArea.name}</span>
          </div>
        )}
      </div>
      <QuestionActionsPopover question={question} part={part} isMainQuestion={isMainQuestion} />
      <AnswersDimensionsWarning entityId={question.id} entityType={EntityType.Question} />
    </div>
  )
}

export default QuestionTreeItem
