import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Input } from '@packages/sk8/input'
import React, { ChangeEventHandler, createRef } from 'react'

import Page from 'cms/layout/page/Page'
import useCurrentUser from 'common/users/hooks/useCurrentUser'

export interface ProductsHeaderProps {
  createProduct: () => void
  importProduct: ChangeEventHandler<HTMLInputElement>
  openDemo: () => void
  isInBlankState?: boolean
}

const ProductsHeader = ({ createProduct, importProduct, openDemo, isInBlankState }: ProductsHeaderProps) => {
  const fileInputRef = createRef<HTMLInputElement>()
  const { isMCZRUser } = useCurrentUser()

  return (
    <Page.Header>
      <h1 role="heading">My Products</h1>
      <div className="flex space-x-3">
        {isMCZRUser && (
          <>
            <Button
              aria-label="import product"
              role="button"
              variant="default"
              onClick={() => {
                fileInputRef.current?.click()
              }}
              icon={<Icons.Download className="w-3 h-3" />}
            />
            <Input
              type="file"
              accept="application/json,application/zip"
              aria-label="file input"
              ref={fileInputRef}
              className="hidden"
              onChange={importProduct}
            />
          </>
        )}

        <Button aria-label="view demos" role="button" variant="default" onClick={openDemo}>
          View demos
        </Button>
        <Button
          aria-label="add product"
          role="button"
          variant="primary"
          onClick={createProduct}
          icon={<Icons.Add className="w-3 h-3 fill-white" />}
          data-is-blank-state={isInBlankState}
        >
          Product
        </Button>
      </div>
    </Page.Header>
  )
}

export default ProductsHeader
