import { AutoresizeSelect } from '@packages/sk8/input'
import {
  NormalizedCustomizerProduct,
  Question,
  QuestionInputType,
  Rule,
  RuleAssertion,
  RuleThen,
  RuleWhen,
} from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import ProductQuestionsDropdown from 'common/components/ProductQuestionsDropdown'

import LeftOrRight from './LeftOrRight'
import QuestionsOrAnswersDropdown, { OnSelect, OnSelectMulti } from './QuestionsOrAnswersDropdown'

type IsAvailableParams = {
  predicateIndex: number
  rule: Rule
  customizerProduct: NormalizedCustomizerProduct
}

type Assertion = {
  label: string
  value: RuleAssertion
  default: {
    assertion: RuleAssertion
    value?: string[] | string
  }
  isAvailable: (params: IsAvailableParams) => boolean
}

const thenOrAnd = [
  { label: 'then', value: 'then' },
  { label: 'and', value: 'and' },
] as const

const assertions: Assertion[] = [
  {
    label: 'is',
    value: RuleAssertion.Is,
    default: { assertion: RuleAssertion.Is, value: undefined },
    isAvailable: () => true,
  },
  {
    label: 'is one of',
    value: RuleAssertion.IsOneOf,
    default: { assertion: RuleAssertion.IsOneOf, value: [] },
    isAvailable: ({ predicateIndex, rule, customizerProduct }) => {
      const questionId = rule.when[predicateIndex].path[1]
      return questionId ? !customizerProduct.questions[questionId]?.isMultiAnswer : false
    },
  },
  {
    label: 'includes',
    value: RuleAssertion.Includes,
    default: { assertion: RuleAssertion.Includes, value: [] },
    isAvailable: ({ predicateIndex, rule, customizerProduct }) => {
      const questionId = rule.when[predicateIndex].path[1]
      return questionId ? !!customizerProduct.questions[questionId]?.isMultiAnswer : false
    },
  },
  {
    label: "doesn't include",
    value: RuleAssertion.DoesNotInclude,
    default: { assertion: RuleAssertion.DoesNotInclude, value: [] },
    isAvailable: ({ predicateIndex, rule, customizerProduct }) => {
      const questionId = rule.when[predicateIndex].path[1]
      return questionId ? !!customizerProduct.questions[questionId]?.isMultiAnswer : false
    },
  },
  {
    label: 'is not',
    value: RuleAssertion.IsNot,
    default: { assertion: RuleAssertion.IsNot, value: undefined },
    isAvailable: () => true,
  },
  {
    label: 'matches',
    value: RuleAssertion.MatchesInState,
    default: { assertion: RuleAssertion.MatchesInState, value: [] },
    isAvailable: () => true,
  },
  {
    label: "doesn't match",
    value: RuleAssertion.DoesNotMatchInState,
    default: { assertion: RuleAssertion.DoesNotMatchInState, value: [] },
    isAvailable: () => true,
  },
]

const singleAnswerMultiSelectAssertions = [RuleAssertion.IsOneOf]

const multiAnswerMultiSelectAssertions = [
  RuleAssertion.Is,
  RuleAssertion.Includes,
  RuleAssertion.DoesNotInclude,
  RuleAssertion.IsNot,
]

export interface LeftHandProps {
  customizerProduct: NormalizedCustomizerProduct
  updateWhen: (predicateIndex: number, when: Partial<RuleWhen>) => void
  updateAfter: (predicateIndex: number, value: { value: 'then' | 'and' }) => void
  updateAction: (predicateIndex: number, then: RuleThen) => void
  predicateIndex?: number
  readonly?: boolean
  rule: Rule
  questionsToHighlight?: string[]
  answersToHighlight?: string[]
}

const LeftHand = (props: LeftHandProps) => {
  const { rule, predicateIndex = 0, readonly, customizerProduct, updateAfter, updateWhen } = props
  const availableAssertions = assertions.filter(assertion =>
    assertion.isAvailable({ predicateIndex, customizerProduct, rule })
  )
  const next = predicateIndex + 1 === rule.when.length ? 'then' : 'and'
  const questionId = rule.when[predicateIndex].path[1]
  const question = customizerProduct.questions[questionId]
  const currentAssertion = rule.when[predicateIndex].assertion
  const isMulti = question?.isMultiAnswer
    ? multiAnswerMultiSelectAssertions.includes(currentAssertion)
    : singleAnswerMultiSelectAssertions.includes(currentAssertion)

  const firstOptionDropdownRestrictions = []
  const secondOptionDropdownRestrictions = []

  if (!readonly) {
    Object.keys(customizerProduct.questions).forEach(id => {
      if (customizerProduct.questions[id].inputType === QuestionInputType.Text) {
        firstOptionDropdownRestrictions.push(id)
      }
    })
  }

  if ([RuleAssertion.Is, RuleAssertion.IsNot].includes(currentAssertion)) {
    for (let i = 0; i < predicateIndex; i++) {
      if (rule.when[i].path[1]) {
        firstOptionDropdownRestrictions.push(rule.when[i].path[1])
      }
    }
  } else if (question && [RuleAssertion.MatchesInState, RuleAssertion.DoesNotMatchInState].includes(currentAssertion)) {
    const questionsToExclude = Object.values(customizerProduct.questions)
      .filter(({ isMultiAnswer }) => question.isMultiAnswer !== isMultiAnswer)
      .map(({ id }) => id)

    secondOptionDropdownRestrictions.push(questionId, ...questionsToExclude)
  }

  const changeNextPart = (value: { value: 'then' | 'and' }) => updateAfter(predicateIndex, value)

  const getAnswerPath = (question: Question) => (question?.isMultiAnswer ? 'selectedAnswers' : 'selectedAnswer')

  const updatePath = ({ value }: { value: string }) => {
    const answerPath = getAnswerPath(customizerProduct.questions[value])
    updateWhen(predicateIndex, { ...assertions[0].default, path: ['questions', value, answerPath] })
  }

  const updateValue: OnSelect = ({ value }) =>
    updateWhen(predicateIndex, {
      path: [rule.when[predicateIndex].path[0], rule.when[predicateIndex].path[1], getAnswerPath(question)],
      value,
    })

  const updateValues: OnSelectMulti = values => {
    updateWhen(predicateIndex, {
      path: [rule.when[predicateIndex].path[0], rule.when[predicateIndex].path[1], getAnswerPath(question)],
      value: values ? values.map(({ value }) => value) : [],
    })
  }

  const updateAssertion = (value: Assertion) => updateWhen(predicateIndex, value.default)

  const getUnavailableAssertion = (assertion: RuleAssertion) => {
    return assertions.find(({ value }) => value === assertion)?.label
  }

  return (
    <>
      <ProductQuestionsDropdown
        customizerProduct={customizerProduct}
        className={classNames({
          'mb-2': !readonly,
        })}
        readonly={readonly}
        exclude={firstOptionDropdownRestrictions}
        value={questionId}
        onSelect={updatePath}
        questionsToHighlight={props.questionsToHighlight}
      />
      <AutoresizeSelect
        readonly={readonly}
        className={classNames('action', { 'mb-2': !readonly })}
        value={currentAssertion}
        options={availableAssertions}
        onChange={value => updateAssertion(value!)}
        styles={{ menu: provided => ({ ...provided, width: 'max-content' }) }}
        emptyReadonlyPlaceholder={<span>{getUnavailableAssertion(currentAssertion)}</span>}
      />
      <QuestionsOrAnswersDropdown
        readonly={readonly}
        questionsToHighlight={props.questionsToHighlight}
        answersToHighlight={props.answersToHighlight}
        className={classNames({ 'mb-2': !readonly })}
        customizerProduct={customizerProduct}
        exclude={secondOptionDropdownRestrictions}
        assertion={currentAssertion}
        questionId={questionId}
        value={rule.when[predicateIndex].value}
        onSelect={isMulti ? updateValues : updateValue}
        isMulti={isMulti}
      />
      <AutoresizeSelect
        readonly={readonly}
        className={classNames('action', { 'mb-2': !readonly })}
        value={next}
        options={thenOrAnd}
        onChange={value => changeNextPart(value!)}
      />
      <LeftOrRight {...props} value={next} predicateIndex={predicateIndex + 1} />
    </>
  )
}

export default LeftHand
