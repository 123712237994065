import { Icons } from '@packages/sk8/icons'
import { Input } from '@packages/sk8/input'
import { Popover, usePopover } from '@packages/sk8/popover'
import { generateId } from '@packages/unique-string'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { FormikVariants, VariantFormValue } from '../types'
import { filterUnusedInventoryItems, getInitialInventoryItem, getInventoryItem } from '../utils'
import KickflipVariantPopover from './KickflipVariantPopover'
import useSharedSkus from './useSharedSkus'

export interface KickflipSkuCellProps {
  variant: VariantFormValue
  formik: FormikVariants
  locationId: string
}

const KickflipSkuCell = ({ variant, locationId, formik }: KickflipSkuCellProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const inventoryItem = getInventoryItem(formik, variant)
  const initialInventoryItem = getInitialInventoryItem(formik, variant)
  const [sku, setSku] = useState(inventoryItem?.sku)
  const popover = usePopover({ placement: 'bottom', offsetConfig: { mainAxis: 4 } })
  const skuSharedData = useSharedSkus(locationId, sku, formik)
  const previousSkuSharedData = useSharedSkus(locationId, inventoryItem.sku, formik)
  const initialSkuSharedData = useSharedSkus(locationId, initialInventoryItem.sku, formik)
  const otherInventoryItem = skuSharedData.inventoryItem

  const willMoveToGroup =
    sku !== '' &&
    sku !== inventoryItem.sku &&
    otherInventoryItem != null &&
    skuSharedData.variantIds.length > 0 &&
    !(skuSharedData.variantIds.length === 1 && otherInventoryItem?.id === inventoryItem.id)

  const willMoveOutOfGroup =
    inventoryItem.sku !== '' &&
    sku !== inventoryItem.sku &&
    skuSharedData.variantIds.length === 0 &&
    previousSkuSharedData.variantIds.length > 1

  useEffect(() => {
    setSku(inventoryItem.sku)
  }, [inventoryItem.sku])

  useEffect(() => {
    if (inventoryItem.sku !== initialInventoryItem.sku && !formik.touched.variants?.[variant.id]) {
      formik.setFieldTouched(`variants.${variant.id}`)
    } else if (inventoryItem.sku === initialInventoryItem.sku && !!formik.touched.variants?.[variant.id]) {
      const touched = { ...formik.touched }
      delete touched.variants?.[variant.id]
      formik.setTouched(touched)
    }
  }, [inventoryItem.sku, initialInventoryItem.sku])

  const isDirty = inventoryItem.sku !== initialInventoryItem.sku

  return (
    <div className="flex items-center space-x-2">
      <Input
        small
        autoComplete="off"
        inputClassName={classNames({ 'placeholder-primary-500 text-primary-500': isDirty })}
        id={variant.id}
        aria-label="sku"
        value={sku}
        placeholder={isDirty && inventoryItem.sku === '' ? '—' : ''}
        onChange={e => setSku(e.target.value)}
        onFocus={() => setIsFocused(true)}
        ref={popover.referenceProps.ref}
        onBlur={() => {
          setIsFocused(false)
          if (willMoveToGroup) {
            formik.setValues(values => {
              const { inventoryItems, variants } = values
              const updatedInventoryItems = { ...inventoryItems }
              const updatedVariants = { ...variants }

              if (updatedInventoryItems[otherInventoryItem.id] == null)
                updatedInventoryItems[otherInventoryItem.id] = otherInventoryItem

              updatedVariants[variant?.id] = {
                ...updatedVariants[variant?.id],
                inventoryItem: otherInventoryItem.id,
              }

              return filterUnusedInventoryItems({ inventoryItems: updatedInventoryItems, variants: updatedVariants })
            })
          } else if (willMoveOutOfGroup) {
            if (sku === '' && initialInventoryItem.sku === '') {
              formik.setFieldValue(`inventoryItems.${initialInventoryItem?.id}`, {
                ...initialInventoryItem,
                stock: inventoryItem.stock,
              })
              formik.setFieldValue(`variants.${variant?.id}.inventoryItem`, initialInventoryItem.id)
            } else if (initialSkuSharedData.variantIds.length < 2) {
              formik.setFieldValue(`inventoryItems.${initialInventoryItem?.id}`, {
                ...initialInventoryItem,
                stock: inventoryItem.stock,
                committed: initialInventoryItem.committed,
                sku,
              })
              formik.setFieldValue(`variants.${variant?.id}.inventoryItem`, initialInventoryItem.id)
            } else {
              const id = generateId('new')
              formik.setFieldValue(`inventoryItems.${id}`, { ...inventoryItem, id, sku })
              formik.setFieldValue(`variants.${variant?.id}.inventoryItem`, id)
            }
          } else if (sku !== inventoryItem.sku) {
            formik.setFieldValue(`inventoryItems.${inventoryItem.id}.sku`, sku)
          }
        }}
      />
      <KickflipVariantPopover
        variant={variant}
        setContinueSelling={continueSelling =>
          formik.setFieldValue(`variants.${variant?.id}.continueSelling`, continueSelling)
        }
      />
      <Popover isOpen={isFocused && willMoveToGroup} {...popover.floatingProps}>
        <div className="text-xs flex-col w-52 text-neutral-600">
          <div className="p-2">This variant's stock will be synced with SKU {sku}</div>
          <div className="ml-auto p-2 flex items-center space-x-1 border-t border-neutral-100">
            <span className="font-medium">Total: </span>
            <span>{inventoryItem.stock === null ? 'N/A' : inventoryItem.stock}</span>
            <>
              <Icons.ArrowRight className="w-2 h-2 fill-neutral-600" />
              <span className="text-tertiary-green-500">
                {otherInventoryItem?.stock === null ? 'N/A' : otherInventoryItem?.stock}
              </span>
            </>
          </div>
        </div>
      </Popover>
      <Popover isOpen={isFocused && willMoveOutOfGroup} {...popover.floatingProps}>
        <div className="text-xs w-52 p-2">
          This variant's stock will no longer be synced with SKU {inventoryItem?.sku}.
        </div>
      </Popover>
    </div>
  )
}

export default KickflipSkuCell
