import { IconButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { Icons } from '@packages/sk8/icons'
import { Input, InputField } from '@packages/sk8/input'
import { Tooltip } from '@packages/sk8/tooltip'
import { GroupType, NormalizedCustomizerProduct } from '@packages/types'
import { FieldArray, useFormikContext } from 'formik'
import { get } from 'lodash'
import React from 'react'

import { PriceBreak, PricingFormValues } from '../types'
import AddBulkOrderPriceBreakPopover from './AddBulkOrderPriceBreakPopover'

interface BulkPricesProps {
  customizerProduct: NormalizedCustomizerProduct
  currenciesInputProps: { leftAddon?: string; rightAddon?: string }
}

const BulkPricingCard = ({ customizerProduct, currenciesInputProps }: BulkPricesProps) => {
  const formik = useFormikContext<PricingFormValues>()
  const hasABulkQuestion = Object.values(customizerProduct.groups).some(({ type }) => type === GroupType.BulkOrder)

  const currencySymbol = currenciesInputProps.rightAddon || currenciesInputProps.leftAddon

  return (
    <Card className="flex-[2] min-w-[26rem] ml-4 mb-8 h-fit">
      <FieldArray name="bulkPrices">
        {fieldArrayHelpers => (
          <div className="flex flex-col w-full">
            <div>
              <div className="flex px-6 mt-6 justify-between items-center">
                <div className="font-medium">
                  <label htmlFor="basePrice">Bulk pricing</label>
                </div>
                {hasABulkQuestion && (
                  <AddBulkOrderPriceBreakPopover
                    addPriceBreak={({ quantity, price }: PriceBreak) => {
                      const newItemPosition = [...formik.values.bulkPrices, { quantity, price }]
                        .sort((a, b) => parseInt(a.quantity) - parseInt(b.quantity))
                        .findIndex(priceBreak => priceBreak.quantity === quantity)

                      fieldArrayHelpers.insert(newItemPosition, { quantity, price })
                    }}
                    bulkPrices={formik.values.bulkPrices}
                    currenciesInputProps={currenciesInputProps}
                    disabledButton={!hasABulkQuestion}
                  />
                )}
              </div>
              <div className="px-6 my-2 text-neutral-400 text-xs">
                <span>Set different product price based on the volume your client buy.</span>
                {!hasABulkQuestion && <span className="ml-1">Requires a published bulk question to be available.</span>}
              </div>
            </div>

            <div className="w-full pb-6 px-6 [&>*:last-child]:border-b-0">
              {hasABulkQuestion && (
                <>
                  {formik.values.bulkPrices?.map((priceBreak: PriceBreak, i: number) => {
                    const nextBulkPrice = formik.values.bulkPrices[i + 1]

                    return (
                      <div
                        key={priceBreak.quantity}
                        className="flex w-full justify-between h-12 border-b border-b-neutral-100 items-center"
                      >
                        <div className="flex-[2]">
                          <span className="text-neutral-300 mr-2">From</span>
                          <span>{priceBreak.quantity}</span>
                        </div>
                        <div className="flex-[2]">
                          <span className="text-neutral-300 mr-2">To</span>
                          <span>{nextBulkPrice ? Number(nextBulkPrice.quantity) - 1 : '∞'}</span>
                        </div>
                        <div className="flex items-center justify-end flex-[2]">
                          <InputField className="basis-24 min-w-[8rem]">
                            <Input
                              small
                              name={`bulkPrices.${i}.price`}
                              id={`bulkPrices.${i}.price`}
                              onChange={formik.handleChange}
                              value={get(formik.values, `bulkPrices.${i}.price`)}
                              inputClassName="text-right pr-2"
                              onBlur={formik.handleBlur}
                              hasError={get(formik.errors, `bulkPrices.${i}.price`) != null}
                              rightAddon={
                                get(formik.errors, `bulkPrices.${i}.price`) != null ? (
                                  <Tooltip
                                    className="bg-error-default text-white"
                                    content={get(formik.errors, `bulkPrices.${i}.price`)}
                                    placement="bottom"
                                  >
                                    <Icons.AlertCircle
                                      className="w-3 h-3 fill-error-default"
                                      aria-label="Warning required"
                                    />
                                  </Tooltip>
                                ) : currencySymbol ? (
                                  `${currencySymbol}/unit`
                                ) : undefined
                              }
                            />
                          </InputField>
                        </div>
                        <div className="flex justify-end w-10">
                          <IconButton
                            small
                            aria-label="Delete bulk price"
                            onClick={() => fieldArrayHelpers.remove(i)}
                            Icon={Icons.Bin}
                            type="button"
                          />
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </FieldArray>
    </Card>
  )
}

export default BulkPricingCard
