import { BlankState } from '@packages/sk8/blank-state'
import React from 'react'

import { Icons } from '@packages/sk8/icons'

const EmptyPricing = () => {
  return (
    <div className="flex justify-center">
      <BlankState className="mt-[20%]">
        <BlankState.Icon Icon={Icons.Money} />
        <BlankState.Title>No online store</BlankState.Title>
        <BlankState.Details>You need to connect an online store in order to create your pricing</BlankState.Details>
      </BlankState>
    </div>
  )
}

export default EmptyPricing
