import { Icons } from '@packages/sk8/icons'
import { Modal } from '@packages/sk8/modal'
import { Variant } from '@packages/types'
import classNames from 'classnames'
import { flatten, times } from 'lodash'
import React, { useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { trpc } from 'common/hooks/trpc'
import { UpdateTypes } from 'common/variants/types/variant'

interface StockAdjustModalProps {
  ids: string[]
  locationId: string
  value: number
  updateType: UpdateTypes
}

const StockAdjustDetails = ({ ids, locationId, updateType, value }: StockAdjustModalProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const {
    data: variantsQueryData,
    isLoading: isLoadingVariants,
    isFetching: isFetchingVariants,
    fetchNextPage,
    hasNextPage,
  } = trpc.variant.getByIds.useInfiniteQuery(
    {},
    {
      initialCursor: ids.slice(0, 100),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: lastPage => {
        if (lastPage.length === 0) return ids.slice(0, 100)

        const lastIndex = !!lastPage ? ids.indexOf(lastPage[lastPage.length - 1]?.id) : -1

        if (lastIndex === ids.length - 1 || lastPage.length < 100) return undefined

        return ids.slice(lastIndex + 1, lastIndex + 100)
      },
    }
  )

  const getVariantStock = (variant: Variant, locationId: string) => {
    const stock = variant.inventoryItem.inventoryLevelByLocationId[locationId].stock

    return stock === null ? 0 : stock
  }

  const getNewValue = (variant: Variant, locationId: string) => {
    if (updateType === UpdateTypes.adjust) return getVariantStock(variant, locationId) + value

    return value
  }

  const variants = variantsQueryData ? flatten(variantsQueryData.pages) : []

  const skeletonLength = Math.min(ids.length, 20)

  const isInvalidUpdate = updateType === UpdateTypes.adjust && value === 0

  return (
    <Modal.Details ref={scrollRef} className="bg-neutral-50 pb-3">
      {isLoadingVariants || isFetchingVariants ? (
        <div role="ul">
          {times(skeletonLength).map((_, i) => (
            <div key={`skeleton-${i}`} role="li" className={classNames('h-[42.5px] py-1 flex items-center')}>
              <div className="animate-pulse h-full bg-neutral-75 rounded w-full" />
            </div>
          ))}
        </div>
      ) : (
        <Virtuoso
          customScrollParent={scrollRef.current === null ? undefined : scrollRef.current}
          data={variants}
          overscan={120}
          style={{ minHeight: `${42.5 * skeletonLength}px` }}
          endReached={() => {
            hasNextPage && fetchNextPage()
          }}
          itemContent={(index, variant) => {
            return (
              <div
                role="li"
                className={classNames('border-neutral-100 py-1 flex items-center', {
                  'border-b': index !== variants.length - 1,
                })}
              >
                <div>
                  <div className="text-xs text-neutral-500">{variant.productName}</div>
                  <span className="text-neutral-500">{variant.combinationName}</span>
                  <span> - </span>
                  <span className="font-medium">{variant.name}</span>
                </div>
                <div className="ml-auto flex items-center space-x-1">
                  <span>
                    {variant.inventoryItem.inventoryLevelByLocationId[locationId].stock === null
                      ? 0
                      : variant.inventoryItem.inventoryLevelByLocationId[locationId].stock}
                  </span>
                  {!isInvalidUpdate && (
                    <>
                      <Icons.ArrowRight className="w-2 h-2 fill-neutral-600" />
                      <span className="text-tertiary-green-500">{getNewValue(variant, locationId)}</span>
                    </>
                  )}
                </div>
              </div>
            )
          }}
        />
      )}
    </Modal.Details>
  )
}

export default StockAdjustDetails
