import { BlankState } from '@packages/sk8/blank-state'
import { Icons } from '@packages/sk8/icons'
import { Price } from '@packages/sk8/price'
import { Table } from '@packages/sk8/table'
import { Design } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import type { Paginated } from 'common/api/types/pagination'
import { SortOrder } from 'common/hooks/dataTable/useTableSort'
import { formatSmallDate } from 'utils/dateUtils'

import { DesignsSortKeys } from '../types/datatable'

type DesignColumn = {
  key: DesignsSortKeys
  title: string
  className: string
  isSortable: boolean
  filterClassName?: string
}

const designColums: DesignColumn[] = [
  {
    key: 'designId',
    title: 'Design id',
    className: 'w-3/12',
    isSortable: true,
  },
  {
    key: 'productName',
    title: 'Product',
    className: 'w-4/12',
    isSortable: true,
  },
  {
    key: 'price',
    title: 'Price',
    className: 'w-2/12',
    isSortable: true,
  },
  {
    key: 'createdAt',
    title: 'Date',
    className: 'w-3/12',
    isSortable: true,
  },
]

export interface DesignsTableProps {
  sortKey?: DesignsSortKeys
  sortOrder?: SortOrder
  handleSort: (key: DesignsSortKeys) => void
  setCount: (value: number) => void
  nextPage: () => void
  previousPage: () => void
  count: number
  lastIndex: number
  resultSize?: number
  collectionSize?: number
  data?: Paginated<Design>
  isLoading?: boolean
  isFetching?: boolean
  onClearFilters: () => void
  onRowClick: (id: string) => void
  hasAppliedFilters: boolean
}

const DesignsTable = ({
  sortKey,
  sortOrder,
  handleSort,
  setCount,
  count,
  previousPage,
  nextPage,
  data,
  lastIndex,
  collectionSize,
  resultSize,
  isLoading,
  isFetching,
  onClearFilters,
  hasAppliedFilters,
  onRowClick,
}: DesignsTableProps) => {
  const isEmpty = !data?.results.length
  const numberOfColumns = designColums.length
  const onlineStoreService = useOnlineStoreService()
  const { data: onlineStores = [] } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll)

  return (
    <Table>
      {(!isEmpty || hasAppliedFilters) && (
        <Table.Header>
          <Table.HeaderRow>
            {designColums.map(column => {
              return (
                <Table.HeaderCell key={column.key} className={column.className}>
                  <Table.HeaderFilter
                    onClick={() => handleSort(column.key)}
                    sortOrder={sortOrder}
                    isSortable={column.isSortable}
                    isSorted={sortKey === column.key}
                    className={column.filterClassName}
                  >
                    {column.title}
                  </Table.HeaderFilter>
                </Table.HeaderCell>
              )
            })}
          </Table.HeaderRow>
        </Table.Header>
      )}
      {isLoading && <Table.BodyLoader numberOfColumns={numberOfColumns} numberOfRows={count} />}
      {!isLoading && isEmpty && hasAppliedFilters && (
        <Table.Body>
          <Table.Row className="hover:bg-white border-b-0">
            <Table.Cell colSpan={numberOfColumns}>
              <div className="flex items-center justify-center py-32">
                <BlankState>
                  <BlankState.Icon Icon={Icons.Sheet} />
                  <BlankState.Title>No Designs to display with the specified filters</BlankState.Title>
                  <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
                  <BlankState.Action onClick={onClearFilters}>Clear all filters</BlankState.Action>
                </BlankState>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}
      {!isLoading && isEmpty && !hasAppliedFilters && (
        <Table.Body>
          <Table.Row className="hover:bg-white border-b-0">
            <Table.Cell colSpan={5}>
              <div className="flex items-center justify-center py-32 ">
                <BlankState className="max-w-xs">
                  <BlankState.Icon Icon={Icons.Sheet} />
                  <BlankState.Title>Customers haven't created designs, yet.</BlankState.Title>
                  <BlankState.Details>
                    Designs will appear here as soon as your customers add your products to their carts or share them on
                    social media.
                  </BlankState.Details>
                </BlankState>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}
      {!isLoading && !isEmpty && (
        <Table.Body>
          {isFetching && <Table.Loader colSpan={numberOfColumns} />}
          {data?.results.map(design => {
            const onlineStore = onlineStores.find(onlineStore => onlineStore.id === design.store)
            return (
              <Table.Row className="cursor-pointer" key={design.designId} onClick={() => onRowClick(design.id)}>
                <Table.Cell>{design.designId}</Table.Cell>
                <Table.Cell>{design.productName}</Table.Cell>
                <Table.Cell>
                  <Price currency={onlineStore?.currency ?? 'USD'} amount={design.price ?? 0} />
                </Table.Cell>
                <Table.Cell>
                  {design.createdAt ? formatSmallDate(design.createdAt) : <Table.EmptyCellIndicator />}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      )}
      {!isEmpty && (
        <Table.Footer>
          <Table.FooterRow>
            <Table.FooterCell colSpan={numberOfColumns}>
              <Table.Pagination>
                <Table.PaginationRowsPerPage onChange={setCount} rowsPerPage={count} />
                <Table.PaginationNavigation
                  onNextPage={nextPage}
                  onPreviousPage={previousPage}
                  lastIndex={lastIndex}
                  resultSize={resultSize || 0}
                  count={count}
                  collectionSize={collectionSize || 0}
                />
              </Table.Pagination>
            </Table.FooterCell>
          </Table.FooterRow>
        </Table.Footer>
      )}
    </Table>
  )
}

export default DesignsTable
