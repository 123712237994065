import { Icons } from '@packages/sk8/icons'
import { ECommerce } from '@packages/types'
import React from 'react'

import classMerge from 'utils/classMerge'

const logos = {
  shopify: Icons.Shopify,
  woocommerce: Icons.WooCommerce,
  prestashop: Icons.Prestashop,
  wix: Icons.Wix,
  'custom-store': Icons.OnlineShop,
}

export const labels = {
  shopify: 'Shopify logo',
  woocommerce: 'WooCommerce logo',
  prestashop: 'PrestaShop logo',
  wix: 'Wix logo',
  'custom-store': 'Custom store logo',
}

interface ECommerceLogoProps {
  eCommerce: ECommerce
  circle?: boolean
  className?: string
  logoClassName?: string
}

const ECommerceLogo = ({ eCommerce, circle, className, logoClassName }: ECommerceLogoProps) => {
  const Logo = logos[eCommerce]

  return (
    <div className={classMerge('relative flex justify-center items-center text-center', className)}>
      {circle && <div className="absolute w-full h-full rounded-full bg-neutral-100 content-['']" />}
      {Logo && (
        <Logo
          className={classMerge(
            'relative',
            {
              'p-2': circle && eCommerce !== ECommerce.CustomStore,
              'p-3': circle && eCommerce === ECommerce.CustomStore,
              'h-full w-full': circle,
              'h-4 w-4': !circle,
            },
            logoClassName
          )}
          data-testid="ecommerceLogo"
          aria-label={labels[eCommerce]}
        />
      )}
    </div>
  )
}

export default ECommerceLogo
