import { Button, IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Tooltip } from '@packages/sk8/tooltip'
import { Asset, FontType, Answer, FontView, Part, QuestionType } from '@packages/types'
import React from 'react'
import { useHistory } from 'react-router'
import { SingleValue } from 'react-select'

import * as answersActions from 'builder/build/answers/actions'
import { updateFont } from 'builder/build/answers/actions'
import Panel from 'builder/build/common/components/panels/Panel'
import Separator from 'builder/build/common/components/Separator'
import { useDispatch } from 'cms/hooks'
import AsyncFontSelect from 'common/assets/components/AsyncFontSelect'
import type { FontOption } from 'common/assets/types/font'
import { formatFontAssetsOption, getFontValue } from 'common/assets/utils/formatFont'
import { trpc } from 'common/hooks/trpc'
import { downloadResource } from 'utils/forceDownload'

import AddQuestionButton from './AddQuestionButton'

const { updateAssets } = answersActions

interface DefaultFontSectionProps {
  part: Part
  answer?: Answer
  disabled?: boolean
  questionId?: string
}

const DefaultFontSection = ({ part, answer, disabled, questionId }: DefaultFontSectionProps) => {
  const dropdownButton = usePopover({ placement: 'bottom' })
  const trpcUtils = trpc.useContext()
  const history = useHistory()
  const dispatch = useDispatch()
  const { openToast } = useToast()

  const view = (answer?.views?.[0] || {}) as FontView
  const { fontType = FontType.Custom, assets = {} } = view

  const handleOpenQuestionClick = () => history.push(`/questions/${questionId}`)

  const handleChange = (option: SingleValue<FontOption>) => {
    dispatch(updateFont(answer, option?.value, option?.type, option?.asset))
  }

  const handleUpload = async (asset: Asset | null) => {
    if (!asset) return

    await dispatch(updateAssets(answer, [asset]))
    await dispatch(updateFont(answer, getFontValue(asset), FontType.Custom, asset))
    trpcUtils.asset.listCustomFonts.invalidate()
    openToast(`${asset.originalFilename} was successfully uploaded`, ToastType.success)
  }

  const value = formatFontAssetsOption(assets, fontType)

  return (
    <>
      <Tooltip
        content={
          questionId ? (
            <div className="flex items-center space-x-2">
              <span>Fonts are handled by a question.</span>
              <Button onClick={handleOpenQuestionClick} variant="text">
                Open
              </Button>
            </div>
          ) : (
            'Font'
          )
        }
      >
        <IconButton
          variant="subtle"
          disabled={disabled || !!questionId}
          Icon={Icons.Font}
          {...dropdownButton.referenceProps}
        />
      </Tooltip>
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
        <Panel side="right" className="builder">
          <div className="default-font-section w-72">
            <div className="p-4">
              <AsyncFontSelect
                onUpload={handleUpload}
                onChange={handleChange}
                fontType={fontType}
                value={value}
                className="w-full"
              />

              {value && fontType !== FontType.Google && (
                <button className="text-primary-600 mt-3" onClick={() => downloadResource(value!.asset!.url)}>
                  Download font
                </button>
              )}
            </div>
            <Separator>or</Separator>

            <div className="p-4">
              <AddQuestionButton onClick={dropdownButton.close} part={part} type={QuestionType.Font} />
            </div>
          </div>
        </Panel>
      </Popover>
    </>
  )
}

export default DefaultFontSection
