import { Icons } from '@packages/sk8/icons'
import { Asset } from '@packages/types'
import React from 'react'

import { FileType, getFileType } from 'common/assets/utils/getFileType'
import withFlag from 'common/users/components/withFlag'
import ImageLoader, { dimensionStrings } from 'utils/loaders/ImageLoader'

interface FileThumbnailProps {
  file: Asset
}

const FileThumbnail = ({ file }: FileThumbnailProps) => {
  const splittedUrl = file.url.split('.')
  const extension = splittedUrl[splittedUrl.length - 1]

  const type = getFileType(extension)

  return (
    <span className="w-6 h-6 mr-2 shrink-0 rounded overflow-hidden border border-solid border-neutral-75">
      {type === FileType.Audio && <Icons.AudioFile className="object-center p-1 h-full w-full border-neutral-100" />}
      {type === FileType.Video && <Icons.VideoFile className="object-center p-1 h-full w-full border-neutral-100" />}
      {type === FileType.Image && (
        <img
          src={ImageLoader.buildSrc(file.url, { preventResize: false, dimensionString: dimensionStrings.regular })}
          className="object-cover h-full w-full"
        />
      )}
    </span>
  )
}

export default withFlag({
  Component: FileThumbnail,
  feature: 'audio_video_uploader',
})
