import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { HelperText, Input, InputField, Label } from '@packages/sk8/input'
import { Popover, usePopover } from '@packages/sk8/popover'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import React, { useContext, useEffect } from 'react'
import * as yup from 'yup'

import QuoteContext from 'cms/quotes/QuoteContext'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import type { QuoteFormValues } from './Quote'

type QuoteCustomExtraPricePopoverFormValues = {
  description?: string
  quantity?: number
  price?: number
}

const validationSchema = yup.object().shape({
  description: yup.string().required('Description is required'),
  quantity: yup.number().required('Quantity is required').min(0, 'Quantity must be greater than 0'),
  price: yup.number().required('Price is required').min(0, 'Price must be greater than 0'),
})

interface AddQuoteCustomExtraPricePopoverProps {
  itemIndex: number
}

const AddQuoteCustomExtraPricePopover = ({ itemIndex }: AddQuoteCustomExtraPricePopoverProps) => {
  const { currenciesInputProps } = useContext(QuoteContext)
  const quoteFormik = useFormikContext<QuoteFormValues>()

  const popover = usePopover()

  const formik = useFormik<QuoteCustomExtraPricePopoverFormValues>({
    initialValues: {
      quantity: 1,
    },
    onSubmit: values => {
      quoteFormik.setFieldValue(`items.${itemIndex}.design.customExtraPrices`, [
        ...(quoteFormik.values.items?.[itemIndex]?.design?.customExtraPrices || []),
        {
          description: values.description,
          quantity: values.quantity,
          price: values.price,
        },
      ])
    },
    validationSchema,
  })

  useEffect(() => {
    if (!popover.isOpen) {
      formik.resetForm()
    }
  }, [popover.isOpen])

  const handleAdd = async () => {
    const errors = await formik.validateForm(formik.values)
    formik.setTouched({ description: true, quantity: true, price: true })
    if (isNullOrEmpty(errors)) {
      await formik.submitForm()
      popover.close()
    }
  }

  const handleAddAnother = async () => {
    const errors = await formik.validateForm(formik.values)
    await formik.setTouched({ description: true, quantity: true, price: true })
    if (isNullOrEmpty(errors)) {
      await formik.submitForm()
      await formik.setValues({ quantity: 1, description: '', price: 0 })
      formik.resetForm()
    }
  }

  return (
    <>
      <Button
        small
        type="button"
        className="normal-case"
        aria-label="add price"
        iconPosition="left"
        icon={<Icons.Add className="w-2" />}
        {...popover.referenceProps}
      >
        Add price
      </Button>
      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        <FormikProvider value={formik}>
          <form>
            <div className="flex flex-col p-2 space-y-2">
              <InputField>
                <Input
                  id="description"
                  name="description"
                  aria-label="description"
                  placeholder="Details"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  hasError={formik.touched.description && !!formik.errors.description}
                />
                {formik.touched.description && formik.errors.description && (
                  <HelperText hasError>{formik.errors.description}</HelperText>
                )}
              </InputField>
              <InputField>
                <div className="flex items-center space-x-3">
                  <Label htmlFor="quantity" className="mb-0 ml-2 text-xs text-neutral-400 font-medium">
                    Qty
                  </Label>
                  <Input
                    id="quantity"
                    name="quantity"
                    aria-label="quantity"
                    type="number"
                    placeholder="1"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    hasError={formik.touched.quantity && !!formik.errors.quantity}
                  />
                </div>
                {formik.touched.quantity && formik.errors.quantity && (
                  <HelperText hasError>{formik.errors.quantity}</HelperText>
                )}
              </InputField>
              <InputField>
                <Input
                  id="price"
                  name="price"
                  aria-label="price"
                  type="number"
                  placeholder="0"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  hasError={formik.touched.price && !!formik.errors.price}
                  {...currenciesInputProps}
                />
                {formik.touched.price && formik.errors.price && <HelperText hasError>{formik.errors.price}</HelperText>}
              </InputField>
            </div>
            <div className="flex py-4 justify-center bg-neutral-50">
              <span className="flex flex-row items-center justify-start">
                <span className="block h-4">{formik.values.quantity || 'Quantity'}</span>
                <Icons.OperatorMultiply className="w-3 h-3 mx-3 fill-neutral-400 mt-1" />
                <span className="block h-4">{formik.values.price || 'Price'}</span>
                <Icons.Equal className="w-3 h-3 mx-3 fill-neutral-400 mt-1" />
                <span className="block h-4">
                  {formik.values.quantity && formik.values.price
                    ? formik.values.quantity * formik.values.price
                    : 'Amount'}
                </span>
              </span>
            </div>
            <div className="flex space-x-3 p-2 justify-end">
              <Button type="button" onClick={handleAddAnother}>
                Add another
              </Button>
              <Button variant="primary" type="button" onClick={handleAdd}>
                Add
              </Button>
            </div>
          </form>
        </FormikProvider>
      </Popover>
    </>
  )
}

export default AddQuoteCustomExtraPricePopover
