import { BlankState } from '@packages/sk8/blank-state'
import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Modal, useModal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Tooltip } from '@packages/sk8/tooltip'
import { DenormalizedBaseVersion, ProductVersionOrigin, ThemeVersionOrigin, VersionOrigin } from '@packages/types'
import classNames from 'classnames'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import * as ResizeObserver from 'resize-observer-polyfill'

import ErrorPage from 'common/components/ErrorPage'

const formatDate = (date: string) => {
  const dateToFormat = new Date(date)
  const currentYear = new Date().getFullYear()

  return dateToFormat.toLocaleDateString('en', {
    year: dateToFormat.getFullYear() !== currentYear ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

type BaseItem<VersionOrigin> = { id: string; versions: DenormalizedBaseVersion<VersionOrigin>[]; archived?: boolean }

interface HistoryManagerProps<ItemType extends BaseItem<VersionOrigin>, VersionOrigin> {
  item?: ItemType
  texts: {
    item: string
    items: string
    ref: string
    extraTitle?: string
  }
  onReturnAction: () => void
  onBackAction: () => void
  isVersionLastLive: (version: ItemType['versions'][number], ref: ItemType['versions'][number]) => boolean
  getIframeSrc: (versions: ItemType['versions'], selectedVersion: string | null) => string
  restoreVersion: (itemId: string, versionId: string) => Promise<ItemType | null | void>
  isLoading?: boolean
  topBarContent?: React.ReactNode
}

const HistoryManager = <ItemType extends BaseItem<VersionOrigin>, VersionOrigin>({
  item,
  texts,
  onReturnAction,
  onBackAction,
  isVersionLastLive,
  getIframeSrc,
  restoreVersion,
  isLoading,
  topBarContent,
}: HistoryManagerProps<ItemType, VersionOrigin>) => {
  const restoreConfirmationModal = useModal()
  const [versions, setVersions] = useState<ItemType['versions']>([])
  const [selectedVersion, setSelectedVersion] = useState<string | null>(null)
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>()
  const { openToast } = useToast()

  useEffect(() => {
    if (item?.versions && item.versions.length > 0) {
      const ordered = orderBy(item.versions, 'createdAt', 'desc')
      setVersions(ordered)
      const versionToSelect = ordered.find(version => version.origin !== VersionOrigin.DraftPublished)
      setSelectedVersion(versionToSelect?.id || null)
    }
  }, [item?.versions])

  const handleVersionRestore = async () => {
    try {
      if (!item || !selectedVersion) return
      await restoreVersion(item.id, selectedVersion)
      onReturnAction()
    } catch (e) {
      openToast(
        `Oops something went wrong! The ${texts.item} version was not restored. Please try again later or contact us if the error persists.`,
        ToastType.warning
      )
    }
  }

  const getRestoreOrigin = (version: ItemType['versions'][number]) => {
    const origin = item!.versions.find(v => v.id === version.fromVersionId)
    if (!origin) return ''
    return formatDate(origin!.createdAt)
  }

  // https://stackoverflow.com/a/25365973/5103346
  useEffect(() => {
    const iframe = document.getElementById('preview-iframe') as HTMLIFrameElement

    if (!iframe) return

    const container = iframe.parentNode!
    iframe.remove()
    iframe.src = getIframeSrc(item?.versions || [], selectedVersion)
    container.append(iframe)
  }, [selectedVersion])

  useEffect(() => {
    const resizeObserver = new ResizeObserver.default(info => {
      const { width, height } = info[0].contentRect
      setDimensions({ width, height })
    })
    resizeObserver.observe(document.getElementById('root')!)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  if (isLoading || !item || !dimensions) return null

  if (item.archived) {
    return (
      <ErrorPage
        action={{ label: 'Go back', onClick: onBackAction }}
        title="Forbidden action"
        description={`Restoring a version for an archived ${texts.item} is not allowed`}
      />
    )
  }

  const versionsToDisplay = versions.filter(version => version.origin !== VersionOrigin.DraftPublished)

  const lastLiveVersion = versions.find(version =>
    [
      VersionOrigin.DraftPublished,
      VersionOrigin.DraftDiscarded,
      ProductVersionOrigin.ProductCreated,
      ThemeVersionOrigin.ThemeCreated,
    ].includes(version.origin as any)
  )

  const lastLiveVersionIndex = versionsToDisplay.findIndex(version => isVersionLastLive(version, lastLiveVersion!))

  const iFrameStyle = {
    minWidth: '1000px',
    transform: dimensions.width - 346 < 1000 ? `scale(${(dimensions.width - 346) / 1000})` : '',
  }

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <nav className="flex h-[54px] justify-between items-center w-full border-b border-neutral-100 px-6">
          <div className="flex items-center">
            <button className="mr-4" onClick={onReturnAction}>
              <Icons.ArrowLeft width={13} height={13} />
            </button>
            <span className="font-medium">
              Version history
              {texts.extraTitle && ` : ${texts.extraTitle}`}
            </span>
            <Tooltip
              content={`Restore an old version of your ${texts.item} in your ${texts.ref}. This won't be shown in your ecommerce until you publish it!`}
              placement="right"
            >
              <Icons.Help className="ml-2" width={16} height={16} />
            </Tooltip>
          </div>
          {topBarContent}
          <div>
            <Button
              variant="primary"
              data-testid="restore-button"
              onClick={restoreConfirmationModal.open}
              isLoading={isLoading}
              disabled={versionsToDisplay.length === 0 || selectedVersion === versionsToDisplay[0].id || isLoading}
            >
              Restore
            </Button>
          </div>
        </nav>
        {versionsToDisplay.length === 0 && (
          <BlankState className="mt-[20%] self-center">
            <BlankState.Icon Icon={Icons.SyncArrowClock} />
            <BlankState.Title>No history</BlankState.Title>
            <BlankState.Details>
              You need to save or publish your {texts.item} at least once before being able to see the {texts.item}'s
              history
            </BlankState.Details>
            <BlankState.Action onClick={onReturnAction}>Go to {texts.ref}</BlankState.Action>
          </BlankState>
        )}
        {versionsToDisplay.length > 0 && (
          <div className="flex h-[calc(100vh-54px)]">
            <aside className="w-[252px] overflow-y-auto py-1.5 px-1 border-r border-neutral-100">
              {versionsToDisplay.map((version, index) => (
                <div
                  key={version.id}
                  className={classNames('flex justify-between items-center p-2 rounded-md mb-4 text-neutral-600', {
                    'bg-neutral-75': version.id === selectedVersion,
                    'cursor-pointer hover:bg-neutral-50': version.id !== selectedVersion,
                  })}
                  onClick={() => setSelectedVersion(version.id)}
                  aria-label="version"
                >
                  <div className="flex flex-col min-w-0 w-full">
                    <div className="flex justify-between items-center mb-1 w-full">
                      <span className="text-xs">{formatDate(version.createdAt)}</span>
                      {index === lastLiveVersionIndex && (
                        <Tooltip content="Version available in your online store">
                          <Icons.OnlineShop width={12} height={12} className="fill-current" />
                        </Tooltip>
                      )}
                    </div>
                    {index === 0 && <span className="text-xs mb-1">Current version</span>}
                    {version.origin === VersionOrigin.VersionRestored && (
                      <span className="text-xs mb-1">
                        <span className="text-primary-400 ">Restored</span> from {getRestoreOrigin(version)}
                      </span>
                    )}
                    {version.user && (
                      <span className="text-ellipsis overflow-hidden whitespace-nowrap text-xs">
                        {version.user.firstName && version.user.lastName
                          ? `${version.user.firstName} ${version.user.lastName}`
                          : version.user.email}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </aside>
            <main
              className="flex flex-1 z-10 px-12 py-10 bg-neutral-50 items-center justify-center"
              style={{ width: 'calc(100vw - 252px)' }}
            >
              {selectedVersion && (
                <iframe
                  data-testid="preview-iframe"
                  id="preview-iframe"
                  title={`${texts.item.charAt(0).toUpperCase() + texts.item.substring(1)} preview`}
                  src={getIframeSrc(item?.versions || [], selectedVersion)}
                  width="100%"
                  height="100%"
                  className="rounded-2xl drop-shadow-lg"
                  style={iFrameStyle}
                  allow="clipboard-write"
                />
              )}
            </main>
          </div>
        )}
      </div>
      {restoreConfirmationModal.isVisible && (
        <Modal onBackdropClick={restoreConfirmationModal.close} {...restoreConfirmationModal.modalProps}>
          <Modal.CloseButton onClick={restoreConfirmationModal.close} />
          <Modal.Title>Restore version</Modal.Title>
          <Modal.Details>Are you sure you want to restore this version in the {texts.ref}?</Modal.Details>
          <Modal.Actions>
            <Button onClick={restoreConfirmationModal.close}>Cancel</Button>
            <Button
              variant="primary"
              onClick={handleVersionRestore}
              disabled={isLoading}
              isLoading={isLoading}
              data-testid="restore-final-action"
            >
              Restore
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default HistoryManager
