import { BlankState } from '@packages/sk8/blank-state'
import { Icons } from '@packages/sk8/icons'
import { Table } from '@packages/sk8/table'
import React from 'react'

interface OrdersFiltersBlankStateProps {
  numberOfColumns: number
  onClearFilters: () => void
}

const OrdersFiltersBlankState = ({ numberOfColumns, onClearFilters }: OrdersFiltersBlankStateProps) => (
  <Table.Body>
    <Table.Row className="hover:bg-white border-b-0">
      <Table.Cell colSpan={numberOfColumns}>
        <div className="flex items-center justify-center py-32">
          <BlankState>
            <BlankState.Icon Icon={Icons.Sheet} />
            <BlankState.Title>No orders to display with the specified filters</BlankState.Title>
            <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
            <BlankState.Action onClick={onClearFilters}>Clear all filters</BlankState.Action>
          </BlankState>
        </div>
      </Table.Cell>
    </Table.Row>
  </Table.Body>
)

export default OrdersFiltersBlankState
