import { Icons } from '@packages/sk8/icons'
import { Price } from '@packages/sk8/price'
import { Table } from '@packages/sk8/table'
import { Tooltip } from '@packages/sk8/tooltip'
import { DesignType } from '@packages/types'
import { useFormikContext } from 'formik'
import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import { selectQuoteTotalPrices } from 'cms/quotes/utils'
import Switch from 'common/components/Switch'
import classMerge from 'utils/classMerge'

import type { QuoteFormValues } from './Quote'
import QuoteTableEditableCell from './QuoteTableEditableCell'

const QuoteTableTaxes = () => {
  const { quote, isEditing } = useContext(QuoteContext)
  const formik = useFormikContext<QuoteFormValues>()
  if (!quote) return null

  const totalPriceBeforeTaxes = selectQuoteTotalPrices(quote, {
    ...formik,
    values: { ...formik.values, applyTaxes: false },
  })

  const isBulk = formik?.values?.items?.[0]?.design?.type === DesignType.BulkOrderDesign

  return (
    <>
      {isEditing && (
        <Table.HeaderRow className="bg-neutral-50 border-y border-neutral-100">
          <Table.HeaderCell className="px-6 " colSpan={3}>
            Taxes calculation
          </Table.HeaderCell>
          <Table.HeaderCell className="flex justify-end items-center px-6 w-full text-right">
            <div className="h-6 flex items-center">
              <Switch
                name="applyTaxes"
                id="applyTaxes"
                checked={!!formik.values.applyTaxes}
                onChange={e => formik.setFieldValue('applyTaxes', e.target.checked)}
              />
            </div>
          </Table.HeaderCell>
        </Table.HeaderRow>
      )}
      {formik.values.applyTaxes && (
        <Table.Row className="border-t-0" data-testid="quote-tax-row">
          <Table.Cell
            className={classMerge('py-3 px-6', {
              'pl-10': isBulk,
            })}
            colSpan={2}
          >
            <span className="flex flex-row gap-2 items-center">
              <span>Tax</span>
              <Tooltip content="Taxation is always handled by your e-com store. The amount that is entered here is only an estimate and can change once the order is created.">
                <Icons.InformationCircle className="w-3.5 h-3.5 fill-neutral-400" />
              </Tooltip>
            </span>
          </Table.Cell>
          <QuoteTableEditableCell
            type="percentage"
            name="taxes"
            id="taxes"
            value={formik.values.taxes}
            onChange={formik.handleChange}
          />
          <Table.Cell className="text-right py-3 px-6" colSpan={1}>
            <Price
              amount={totalPriceBeforeTaxes * ((formik.values.taxes ?? 0) / 100)}
              currency={quote.currency ?? 'USD'}
              className="font-medium text-sm pl-1 text-neutral-900"
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  )
}

export default QuoteTableTaxes
