import VerticalEllipsisIcon from './bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import DoubleCogIcon from './bold/01-Interface Essential/12-Settings/cog-double.svg'
import AlertCircleIcon from './bold/01-Interface Essential/14-Alerts/alert-circle.svg'
import InformationCircleIcon from './bold/01-Interface Essential/14-Alerts/information-circle.svg'
import FilterIcon from './bold/01-Interface Essential/15-Filter/filter-2.svg'
import EditIcon from './bold/01-Interface Essential/22-Edit/pencil-write-alternate.svg'
import ErrorCircleIcon from './bold/01-Interface Essential/23-Delete/delete-2.svg'
import SearchIcon from './bold/01-Interface Essential/25-Search/search-1.svg'
import Hyperlink from './bold/01-Interface Essential/27-Link-Unlink/hyperlink-2.svg'
import PrinterIcon from './bold/01-Interface Essential/30-Print/print-picture.svg'
import CheckIcon from './bold/01-Interface Essential/33-Form-Validation/check.svg'
import VerticalAlignBottomIcon from './bold/01-Interface Essential/34-Text-Formating/align-text-bottom.svg'
import VerticalAlignMiddleIcon from './bold/01-Interface Essential/34-Text-Formating/align-text-middle.svg'
import VerticalAlignTopIcon from './bold/01-Interface Essential/34-Text-Formating/align-text-top.svg'
import XIcon from './bold/01-Interface Essential/43-Remove-Add/remove.svg'
import SubtractCircleIcon from './bold/01-Interface Essential/43-Remove-Add/subtract-circle-bold.svg'
import SyncArrowClockIcon from './bold/01-Interface Essential/44-Synchronize/synchronize-arrow-clock.svg'
import SyncArrowsIcon from './bold/01-Interface Essential/44-Synchronize/synchronize-arrows-1.svg'
import FlashIcon from './bold/01-Interface Essential/47-Connect/flash-1.svg'
import ResizeIcon from './bold/01-Interface Essential/53-Resize/expand-diagonal-3.svg'
import LegoBlockIcon from './bold/04-Programing-Apps-Websites/02-Plugins-Modules/module-four.svg'
import ArrowsDiagonalIcon from './bold/05-Internet-Networks-Servers/07-Data-Transfer/data-transfer-diagonal.svg'
import DownloadIcon from './bold/05-Internet-Networks-Servers/08-Upload-Download/download-bottom.svg'
import UploadIcon from './bold/05-Internet-Networks-Servers/08-Upload-Download/upload-bottom.svg'
import AnalyticsIcon from './bold/06-Business-Products/12-Analytics/analytics-pie-1.svg'
import MoneyIcon from './bold/08-Money-Payments-Finance/02-Money/accounting-coins-stack.svg'
import CreditCardIcon from './bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-1-alternate.svg'
import AmexCardIcon from './bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-amex.svg'
import ValidCardIcon from './bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-check.svg'
import InvalidCardIcon from './bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-disable.svg'
import MastercardCardIcon from './bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-mastercard.svg'
import VisaCardIcon from './bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-visa.svg'
import InventoryIcon from './bold/10-Shipping-Delivery/01-Warehouse-Fullfilment/warehouse-packages.svg'
import BoundingBoxIcon from './bold/12-Design/04-Vectors/vectors-anchor-square-1.svg'
import PathIcon from './bold/12-Design/04-Vectors/vectors-line-path.svg'
import CurvedPathIcon from './bold/12-Design/04-Vectors/vectors-path-flat.svg'
import ColorPaletteIcon from './bold/12-Design/05-Colors-Painting/color-painting-palette.svg'
import TimerResetIcon from './bold/13-Images-Photography/17-Timer/timer-0.svg'
import AudioFileIcon from './bold/14-Music-Audio/18-Audio-Files/audio-file-volume-alternate.svg'
import VideoFileIcon from './bold/15-Video-Movies-TV/08-Video-Files/video-file-play-alternate.svg'
import EmptyDrawerIcon from './bold/19-Emails/06-Drawers/drawer-open.svg'
import StarIcon from './bold/22-Social-Medias-Rewards-Rating/06-Rating/rating-star.svg'
import ChevronDownIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'
import ArrowDownIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-down.svg'
import ChevronLeftIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import ArrowleftIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-left.svg'
import ChevronRightIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'
import ArrowRightIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-right.svg'
import ChevronUpIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-up-1.svg'
import ArrowUpIcon from './bold/52-Arrows-Diagrams/01-Arrows/arrow-up.svg'
import DesktopIcon from './core-solid/computer-devices/computer-screen-1.svg'
import LayersIcon from './core-solid/interface-essential/interface-align-layers-1.svg'
import MoveIcon from './core-solid/interface-essential/interface-arrows-all-direction.svg'
import ArrowRightDownIcon from './core-solid/interface-essential/interface-arrows-bend-down-right-1.svg'
import ArrowDownRight from './core-solid/interface-essential/interface-arrows-bend-down-right-2.svg'
import ResetIcon from './core-solid/interface-essential/interface-arrows-round-left.svg'
import BlockIcon from './core-solid/interface-essential/interface-block.svg'
import TagIcon from './core-solid/interface-essential/interface-bookmark-tag.svg'
import Squares4x4Icon from './core-solid/interface-essential/interface-dashboard-layout-square.svg'
import ColorDropIcon from './core-solid/interface-essential/interface-edit-color-drop-pick.svg'
import GridIcon from './core-solid/interface-essential/interface-edit-grid.svg'
import ShowIcon from './core-solid/interface-essential/interface-edit-view.svg'
import FolderIcon from './core-solid/interface-essential/interface-folder.svg'
import LogicIcon from './core-solid/interface-essential/interface-hierarchy-10.svg'
import CenterIcon from './core-solid/interface-essential/interface-layout-border-center.svg'
import CogIcon from './core-solid/interface-essential/interface-setting-cog.svg'
import HamburgerIcon from './core-solid/interface-essential/interface-setting-menu-1.svg'
import CenterAlignIcon from './core-solid/interface-essential/interface-text-formatting-center-align.svg'
import LeftAlignIcon from './core-solid/interface-essential/interface-text-formatting-left-align.svg'
import RightAlignIcon from './core-solid/interface-essential/interface-text-formatting-right-align.svg'
import TextIcon from './core-solid/interface-essential/interface-text-formatting-text-style.svg'
import TextColorIcon from './core-solid/interface-essential/interface-text-formatting-text.svg'
import UserAddIcon from './core-solid/interface-essential/interface-user-add.svg'
import ProductIcon from './core-solid/money-shopping/catergories-shirt.svg'
import MobileIcon from './core-solid/phone/phone-mobile-phone.svg'
import SettingSlider from './custom/adjust.svg'
import StarsIcon from './custom/beta-stars.svg'
import SkateboardIcon from './custom/bold-skateboard.svg'
import CapterraIcon from './custom/capterra.svg'
import CheckCircleIcon from './custom/check-circle.svg'
import ColorPickerIcon from './custom/color-picker.svg'
import MoveSheetIcon from './custom/convert-to-order.svg'
import DragHandleIcon from './custom/drag-handle.svg'
import CopyIcon from './custom/duplicate.svg'
import EqualIcon from './custom/equals.svg'
import EquationIcon from './custom/equation.svg'
import PriceTagIcon from './custom/extraprice.svg'
import FourPointFiveStarIcon from './custom/four-point-five-star.svg'
import G2Icon from './custom/g2.svg'
import OpenLinkIcon from './custom/interface-link-square.svg'
import LabelIcon from './custom/label-button.svg'
import ColumnLargeIcon from './custom/large-width.svg'
import ColumnMediumIcon from './custom/medium-width.svg'
import LibraryIcon from './custom/new-library.svg'
import FontIcon from './custom/new-project.svg'
import NumberIcon from './custom/number.svg'
import OperatorAddIcon from './custom/operation-add.svg'
import OperatorDivideIcon from './custom/operation-divide.svg'
import OperatorMultiplyIcon from './custom/operation-multiply.svg'
import OperatorSubtractIcon from './custom/operation-substract.svg'
import TextOutlineIcon from './custom/outline.svg'
import PictureLandscapeIcon from './custom/picture-landscape.svg'
import PrestashopIcon from './custom/prestashop.svg'
import QuestionIcon from './custom/question.svg'
import RadioIcon from './custom/radio-button.svg'
import RotateIcon from './custom/rotate.svg'
import ShopifyIcon from './custom/shopify-icon.svg'
import ColumnSmallIcon from './custom/small-width.svg'
import AddIcon from './custom/streamlinehq-interface-add-1-interface-essential-250.svg'
import LockIcon from './custom/streamlinehq-interface-lock-interface-essential-250.svg'
import UsersIcon from './custom/streamlinehq-interface-user-multiple-interface-essential-250.svg'
import InboxIcon from './custom/streamlinehq-mail-inbox-content-mail-48.svg'
import OnlineShopIcon from './custom/streamlinehq-money-shopping-online-business-money-shopping-250.svg'
import TextInputIcon from './custom/text-input.svg'
import TextSizeIcon from './custom/Text-Size--Streamline-Nova.svg'
import FontSizeIcon from './custom/text-size.svg'
import WixIcon from './custom/wix-icon.svg'
import WooCommerceIcon from './custom/woocommerce-icon.svg'
import ExpandHorizontalIcon from './custom/xlarge-width.svg'
import KeyIcon from './regular/01-Interface Essential/04-Login-Logout/login-key.svg'
import HelpIcon from './regular/01-Interface Essential/14-Alerts/question-circle.svg'
import BinIcon from './regular/01-Interface Essential/23-Delete/bin.svg'
import TranslateIcon from './regular/01-Interface Essential/35-Text-Options/translate.svg'
import DuplicateIcon from './regular/01-Interface Essential/51-Paginate/paginate-filter-plus.svg'
import ExpandVerticalIcon from './regular/01-Interface Essential/53-Resize/expand-vertical.svg'
import SheetIcon from './regular/07-Work-Office-Companies/07-Office-Files/office-file-sheet.svg'
import ClipIcon from './regular/09-Shopping-Ecommerce/05-Discount-Coupons/coupon-cut.svg'
import CameraRotateIcon from './regular/13-Images-Photography/13-Camera-Settings/camera-settings-rotate.svg'

export const Icons = {
  VerticalEllipsis: VerticalEllipsisIcon,
  Cog: CogIcon,
  DoubleCog: DoubleCogIcon,
  XMark: XIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  ChevronDown: ChevronDownIcon,
  ChevronUp: ChevronUpIcon,
  Search: SearchIcon,
  Add: AddIcon,
  Download: DownloadIcon,
  Upload: UploadIcon,
  Show: ShowIcon,
  CheckCircle: CheckCircleIcon,
  AlertCircle: AlertCircleIcon,
  InformationCircle: InformationCircleIcon,
  ErrorCircle: ErrorCircleIcon,
  SubtractCircle: SubtractCircleIcon,
  DragHandle: DragHandleIcon,
  AudioFile: AudioFileIcon,
  VideoFile: VideoFileIcon,
  Edit: EditIcon,
  Hamburger: HamburgerIcon,
  Layers: LayersIcon,
  FlashIcon: FlashIcon,
  Checkmark: CheckIcon,
  Move: MoveIcon,
  Resize: ResizeIcon,
  Center: CenterIcon,
  Grid: GridIcon,
  VerticalAlignBottom: VerticalAlignBottomIcon,
  VerticalAlignMiddle: VerticalAlignMiddleIcon,
  VerticalAlignTop: VerticalAlignTopIcon,
  BoundingBox: BoundingBoxIcon,
  CenterAlign: CenterAlignIcon,
  LeftAlign: LeftAlignIcon,
  RightAlign: RightAlignIcon,
  Cut: ClipIcon,
  ExpandVertical: ExpandVerticalIcon,
  Path: PathIcon,
  CurvedPath: CurvedPathIcon,
  Bin: BinIcon,
  ArrowRightDown: ArrowRightDownIcon,
  ArrowDownRight: ArrowDownRight,
  ArrowUp: ArrowUpIcon,
  ArrowDown: ArrowDownIcon,
  ArrowRight: ArrowRightIcon,
  ArrowLeft: ArrowleftIcon,
  Font: FontIcon,
  TextOutline: TextOutlineIcon,
  TextSize: TextSizeIcon,
  Text: TextIcon,
  TextColor: TextColorIcon,
  FontSize: FontSizeIcon,
  Folder: FolderIcon,
  Rotate: RotateIcon,
  Duplicate: DuplicateIcon,
  CameraRotate: CameraRotateIcon,
  Copy: CopyIcon,
  OpenLink: OpenLinkIcon,
  OperatorAdd: OperatorAddIcon,
  OperatorDivide: OperatorDivideIcon,
  OperatorMultiply: OperatorMultiplyIcon,
  OperatorSubtract: OperatorSubtractIcon,
  Desktop: DesktopIcon,
  Product: ProductIcon,
  Mobile: MobileIcon,
  Help: HelpIcon,
  Star: StarIcon,
  Stars: StarsIcon,
  Printer: PrinterIcon,
  Cancel: BlockIcon,
  ColorPicker: ColorPickerIcon,
  Label: LabelIcon,
  PictureLandscape: PictureLandscapeIcon,
  Radio: RadioIcon,
  TextInput: TextInputIcon,
  UserAdd: UserAddIcon,
  Squares4x4: Squares4x4Icon,
  ColorDrop: ColorDropIcon,
  ColumnLarge: ColumnLargeIcon,
  ColumnMedium: ColumnMediumIcon,
  ColumnSmall: ColumnSmallIcon,
  ExpandHorizontal: ExpandHorizontalIcon,
  Logic: LogicIcon,
  Sheet: SheetIcon,
  SyncArrows: SyncArrowsIcon,
  SyncArrowClock: SyncArrowClockIcon,
  ArrowsDiagonal: ArrowsDiagonalIcon,
  SettingSlider: SettingSlider,
  Reset: ResetIcon,
  OnlineShop: OnlineShopIcon,
  Filter: FilterIcon,
  Hyperlink: Hyperlink,
  Equal: EqualIcon,
  Library: LibraryIcon,
  Number: NumberIcon,
  Question: QuestionIcon,
  Equation: EquationIcon,
  PriceTag: PriceTagIcon,
  Money: MoneyIcon,
  Tag: TagIcon,
  TimerReset: TimerResetIcon,
  EmptyDrawer: EmptyDrawerIcon,
  ColorPalette: ColorPaletteIcon,
  Lock: LockIcon,
  Users: UsersIcon,
  Inbox: InboxIcon,
  Key: KeyIcon,
  Translate: TranslateIcon,
  MoveSheet: MoveSheetIcon,
  Analytics: AnalyticsIcon,
  LegoBlock: LegoBlockIcon,
  Shopify: ShopifyIcon,
  Wix: WixIcon,
  WooCommerce: WooCommerceIcon,
  Prestashop: PrestashopIcon,
  AmexCard: AmexCardIcon,
  ValidCard: ValidCardIcon,
  InvalidCard: InvalidCardIcon,
  MastercardCard: MastercardCardIcon,
  CreditCard: CreditCardIcon,
  VisaCard: VisaCardIcon,
  Capterra: CapterraIcon,
  FourPointFiveStar: FourPointFiveStarIcon,
  G2: G2Icon,
  Skateboard: SkateboardIcon,
  Inventory: InventoryIcon,
} as const
