import { BlankState } from '@packages/sk8/blank-state'
import React from 'react'

import { Icons } from '@packages/sk8/icons'

interface NoVariantsBlankStateProps {
  onCreateVariants: () => void
}

const NoVariantsBlankState = ({ onCreateVariants }: NoVariantsBlankStateProps) => {
  return (
    <BlankState className="self-center mt-12">
      <BlankState.Icon Icon={Icons.Tag} />
      <BlankState.Title>You don't have any variants, yet</BlankState.Title>
      <BlankState.Details>
        In order to create your variants you have to select a combination of questions from your product
      </BlankState.Details>
      <BlankState.Action onClick={onCreateVariants}>Create variants</BlankState.Action>
    </BlankState>
  )
}

export default NoVariantsBlankState
