import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import TopBarEnvTag from 'common/components/topBar/TopBarEnvTag'

const SettingsHeader = () => {
  const history = useHistory()
  const params = useParams<{ brandName: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''

  const handleLogOut = () => {
    localStorage.removeItem('bearer')
    history.push('/login')
  }

  return (
    <header className="bg-white fixed w-full top-0 left-0 flex justify-between items-center border-b border-b-neutral-100 h-[52px] py-4 pl-5 pr-5 md:pr-[52px] z-[2]">
      <TopBarEnvTag />
      <Link to={`${baseUrl}/products`}>
        <Button variant="text" className="flex items-center" icon={<Icons.ArrowLeft className="w-3 h-3" />}>
          <span className="mt-0.5">Leave Settings</span>
        </Button>
      </Link>
      <button onClick={handleLogOut}>Log out</button>
    </header>
  )
}

export default SettingsHeader
