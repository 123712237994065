import React from 'react'
import { Options, PropsValue, SingleValue } from 'react-select'

import Select, { SelectProps } from './Select'

type AutoresizeOption = {
  value: string
  label: React.ReactNode
  readonlyClassName?: string
  selectedValue?: string | string[]
  options?: { value: string; label: string }[]
}

function getValueFromOptions<Option extends AutoresizeOption = AutoresizeOption>(
  options: Options<Option>,
  selectedOptionOrValue?: PropsValue<Option> | PropsValue<Option['value']>
) {
  if (selectedOptionOrValue == null) return undefined

  if (Array.isArray(selectedOptionOrValue)) {
    return options.filter(o => selectedOptionOrValue.includes(o.value))
  }

  const selectedValue = (selectedOptionOrValue as Option).value || (selectedOptionOrValue as string)

  return options.find(o => o.value === selectedValue)
}

function buildPhrase<Option extends AutoresizeOption = AutoresizeOption>(
  values: Option['value'][],
  options: Options<Option>,
  logicalOperator: string
) {
  return values
    .map(v => options.find(o => o.value === v))
    .reduce((phrase, current, index) => {
      if (!current) return phrase

      const { value, selectedValue, readonlyClassName } = current as Option
      const isLast = selectedValue!.indexOf(value) === selectedValue!.length - 1
      const beforeLast = selectedValue!.indexOf(value) === selectedValue!.length - 2

      return [
        ...phrase,
        <span key={`label-${index}`} className={readonlyClassName}>
          {current.label}
        </span>,
        ...(isLast ? [] : beforeLast ? [<span key={`operator-${index}`}> {logicalOperator} </span>] : [', ']),
      ]
    }, [] as React.ReactNode[])
}

interface ReadOnlyValueProps<Option extends AutoresizeOption = AutoresizeOption> {
  value?: SingleValue<Option> | Option['value']
  options: Options<Option>
  className?: string
  emptyReadonlyPlaceholder?: React.ReactNode
}

function ReadOnlyValue<Option extends AutoresizeOption = AutoresizeOption>({
  value,
  options,
  className,
  emptyReadonlyPlaceholder,
}: ReadOnlyValueProps<Option>) {
  let label: React.ReactNode | string | undefined
  let readonlyClassName: string | undefined

  if ((value as SingleValue<Option> | undefined)?.value) {
    label = (value as SingleValue<Option>)!.value
  } else {
    const option = options.find(o => o.value === value)
    option && (label = option.label)
    option && (readonlyClassName = option.readonlyClassName)
  }

  return (
    <div className={`${className || ''} ${readonlyClassName || ''}`}>{label ? label : emptyReadonlyPlaceholder}</div>
  )
}

interface ReadonlyMultiValuesProps<Option extends AutoresizeOption = AutoresizeOption> {
  values: Option['value'][]
  options: Options<Option>
  className?: string
  emptyReadonlyPlaceholder?: React.ReactNode
  logicalOperator?: string
}

function ReadonlyMultiValues<Option extends AutoresizeOption = AutoresizeOption>({
  values,
  options,
  className,
  emptyReadonlyPlaceholder,
  logicalOperator = 'or',
}: ReadonlyMultiValuesProps<Option>) {
  if (!values) return emptyReadonlyPlaceholder

  const choicesPhrase = buildPhrase(values, options, logicalOperator)

  return <span className={className}>{choicesPhrase || emptyReadonlyPlaceholder}</span>
}

interface AutoresizeSelectProps<Option extends AutoresizeOption, IsMulti extends boolean = false>
  extends Omit<SelectProps<Option, IsMulti>, 'value' | 'options'> {
  options: Options<Option>
  value?: PropsValue<Option> | PropsValue<Option['value']>
  readonly?: boolean
  emptyReadonlyPlaceholder?: React.ReactNode
  logicalOperator?: string
}

function AutoresizeSelect<Option extends AutoresizeOption = AutoresizeOption, IsMulti extends boolean = false>({
  value,
  options,
  readonly,
  isMulti,
  className,
  emptyReadonlyPlaceholder,
  logicalOperator,
  ...rest
}: AutoresizeSelectProps<Option, IsMulti>) {
  if (readonly) {
    return isMulti ? (
      <ReadonlyMultiValues<Option>
        className={className}
        values={Array.isArray(value) ? (value as Option['value'][]) : []}
        options={options}
        emptyReadonlyPlaceholder={emptyReadonlyPlaceholder}
        logicalOperator={logicalOperator}
      />
    ) : (
      <ReadOnlyValue<Option>
        className={className}
        value={value as SingleValue<Option>}
        options={options}
        emptyReadonlyPlaceholder={emptyReadonlyPlaceholder}
      />
    )
  }

  return (
    <Select
      options={options}
      value={getValueFromOptions(options, value)}
      disabled={readonly}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      isMulti={isMulti}
      className={className}
      {...rest}
    />
  )
}

export default AutoresizeSelect
