import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Group } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import GroupAddSection from '../GroupAddSection'
import NewQuestionMenu from '../NewQuestionMenu'
import TitleSection from '../TitleSection'
import FolderElementList from './FolderElementList'

export interface FolderTabProps {
  group: Group
}

const FolderTab = ({ group }: FolderTabProps) => {
  const popover = usePopover({
    placement: 'left-start',
    offsetConfig: 8,
    shiftConfig: { mainAxis: true, crossAxis: false, padding: 16 },
  })

  return (
    <>
      <TitleSection group={group} />
      <hr className="border-neutral-100" />
      <div className="py-6">
        <GroupAddSection label="Group elements">
          <IconButton
            iconClassName={classNames('transition-transform', { 'rotate-45': popover.isOpen })}
            Icon={Icons.Add}
            smallIcon
            {...popover.referenceProps}
          />

          <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
            <NewQuestionMenu groupId={group.id} onClose={popover.close} gifPlacement="left-start" />
          </Popover>
        </GroupAddSection>
        <FolderElementList group={group} />
      </div>
    </>
  )
}

export default FolderTab
