import { Icons } from '@packages/sk8/icons'
import classNames from 'classnames'
import React from 'react'

import './MovablePanelHeader.scss'

interface MovablePanelHeaderProps {
  setDragRef: (e: HTMLDivElement) => void
  children: React.ReactNode
}

const MovablePanelHeader = ({ setDragRef, children }: MovablePanelHeaderProps) => {
  return (
    <div className={classNames('popper-menu-header-section', 'popper-menu-header-section--draggable')} ref={setDragRef}>
      <Icons.DragHandle
        className={classNames('icon drag-handle min-w-[20px] popper-menu-header-section__drag-handle', {
          'popper-menu-header-section__drag-handle--hide': setDragRef == null,
        })}
        setRef={undefined}
      />
      {children}
    </div>
  )
}

export default MovablePanelHeader
