import { StripeCardBrand } from '@packages/types'
import React from 'react'

import { Icons } from '@packages/sk8/icons'

export interface StripeFakeStaticCardInputProps {
  last4: number
  brand: StripeCardBrand
  expMonth: number
  expYear: number
  postalCode?: string | null
}

const CardIcon = {
  [StripeCardBrand.Visa]: <Icons.VisaCard className="w-6" />,
  [StripeCardBrand.Mastercard]: <Icons.MastercardCard className="w-6" />,
  [StripeCardBrand.Amex]: <Icons.AmexCard className="w-6" />,
}

const StripeFakeStaticCardInput = ({ brand, last4, expMonth, expYear, postalCode }: StripeFakeStaticCardInputProps) => {
  return (
    <div className="bg-neutral-50 text-neutral-300 inline-flex justify-between items-center py-2 px-3 rounded-[4px] drop-shadow-[0_1px_3px_rgba(230,235,241,0.25)]">
      <div className="flex space-x-3 items-center">
        {CardIcon[brand]}
        <span>{`⋅⋅⋅⋅ ⋅⋅⋅⋅ ⋅⋅⋅⋅ ${last4}`}</span>
      </div>
      <div className="flex space-x-3">
        <span>{`${expMonth} / ${expYear.toString().slice(-2)}`}</span>
        <span>⋅⋅⋅</span>
        <span>{postalCode || '⋅⋅⋅⋅⋅'}</span>
      </div>
    </div>
  )
}
export default StripeFakeStaticCardInput
