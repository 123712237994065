import { Alert } from '@packages/sk8/alert'
import { Button, IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Modal, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { CardTabs } from '@packages/sk8/tab'
import { InventoryManagementStrategy, NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import { useFlags } from 'flagsmith/react'
import React from 'react'

import { trpc } from 'common/hooks/trpc'

import { getCombinationName, getCombinationVariantsCount } from '../utils'
import NewTooltip from './NewTooltip'

export interface CombinationCardProps {
  combination: string[]
  selected: boolean
  isDeletingCombination: boolean
  customizerProduct: NormalizedCustomizerProduct
  onClick: (e: React.MouseEvent) => void
  onClickChangeQuestions: (e: React.MouseEvent) => void
  onClickDelete: (e: React.MouseEvent) => void
  productId: string
  customizerProductId: string
}

const CombinationCard = ({
  combination,
  isDeletingCombination,
  customizerProduct,
  selected,
  onClick,
  onClickChangeQuestions,
  onClickDelete,
  productId,
  customizerProductId,
}: CombinationCardProps) => {
  const flags = useFlags(['inventory_management'])

  const popover = usePopover({
    placement: 'bottom-end',
  })

  const deleteModal = useModal()

  const withClose = (callback: (e: React.MouseEvent) => void) => (e: React.MouseEvent) => {
    callback(e)
    popover.close()
  }

  const variantsCount = getCombinationVariantsCount(combination, customizerProduct)

  const { data: newVariantsCountData } = trpc.variant.getNewCount.useQuery({
    productId: productId,
    questionIds: combination,
    customizerProductId,
  })

  const combinationName = getCombinationName(combination, customizerProduct)

  const handleDelete = (e: React.MouseEvent) => {
    deleteModal.close()
    onClickDelete(e)
  }

  return (
    <>
      <CardTabs.Tab isSelected={selected} onClick={onClick}>
        <CardTabs.TextContainer>
          <CardTabs.PrimaryText>{combinationName}</CardTabs.PrimaryText>
          <span className="text-xs text-neutral-400">
            {variantsCount} variant{variantsCount > 1 ? 's' : ''}
            {newVariantsCountData != null && newVariantsCountData?.count > 0 && (
              <>
                ,
                <NewTooltip>
                  <span className="text-tertiary-green-400"> {newVariantsCountData.count} new</span>
                </NewTooltip>
              </>
            )}
          </span>
        </CardTabs.TextContainer>
        <div className={classNames('absolute right-2 invisible group-hover:visible', { '!visible': popover.isOpen })}>
          <IconButton
            {...popover.referenceProps}
            Icon={Icons.VerticalEllipsis}
            smallIcon
            aria-label={`${combinationName} actions`}
          />
        </div>
        <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
          <Popover.Action onClick={withClose(onClickChangeQuestions)}>Change questions</Popover.Action>
          <Popover.Action className="text-tertiary-red-700" onClick={withClose(deleteModal.open)}>
            Delete
          </Popover.Action>
        </Popover>
      </CardTabs.Tab>
      {deleteModal.isVisible && (
        <Modal {...deleteModal.modalProps}>
          <Modal.CloseButton onClick={deleteModal.close} />
          <Modal.Title>Delete question combination</Modal.Title>

          {flags.inventory_management.value === InventoryManagementStrategy.Shopify && (
            <Modal.Details className="pb-2">
              <Alert variant="warning">
                <Alert.Title>Some variants might be connected to Shopify</Alert.Title>
                <Alert.Details>
                  If some of the variants included in this combination are connected to a Shopify store, they will be
                  permanently deleted. You will need to re-connect them and all of your previous work will be lost.
                </Alert.Details>
              </Alert>
            </Modal.Details>
          )}

          <Modal.Details>
            Are you sure you want to delete the combination {combinationName}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button
              type="button"
              variant="default"
              className="px-4"
              onClick={deleteModal.close}
              disabled={isDeletingCombination}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="error"
              className="px-4"
              onClick={handleDelete}
              disabled={isDeletingCombination}
              isLoading={isDeletingCombination}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default CombinationCard
