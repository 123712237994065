import { Button, IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'

import { PreviewMode } from 'common/preview/types/previewMode'

const iconsByMode = {
  [PreviewMode.DESKTOP]: Icons.Desktop,
  [PreviewMode.MOBILE]: Icons.Mobile,
  [PreviewMode.PRODUCT]: Icons.Product,
}

const labelsByMode = {
  [PreviewMode.DESKTOP]: 'Select preview current desktop',
  [PreviewMode.MOBILE]: 'Select preview current mobile',
  [PreviewMode.PRODUCT]: 'Select preview current product',
}

interface PreviewModeControlProps {
  selectedMode: PreviewMode
  availableModes: PreviewMode[]
  disabledModes?: PreviewMode[]
  onChange: (mode: PreviewMode) => void
  disabled?: boolean
}

const PreviewModeControl = ({
  selectedMode,
  availableModes,
  disabledModes = [],
  onChange,
  disabled,
}: PreviewModeControlProps) => {
  const controlPopover = usePopover({ placement: 'bottom' })
  const IconComponent = iconsByMode[selectedMode]

  const handlePreviewModeClick = (previewMode: PreviewMode) => {
    onChange(previewMode)
    controlPopover.close()
  }

  return (
    <>
      <Button
        {...controlPopover.referenceProps}
        disabled={disabled}
        icon={<IconComponent className="w-3.5 fill-current" aria-label={labelsByMode[selectedMode]} />}
        variant="subtle"
        type="button"
      >
        <Icons.ChevronDown className="w-2 fill-current" />
      </Button>
      <Popover {...controlPopover.floatingProps} isOpen={controlPopover.isOpen} className="!min-w-0">
        <div className="flex">
          {availableModes.includes(PreviewMode.DESKTOP) && (
            <IconButton
              variant="subtle"
              onClick={() => handlePreviewModeClick(PreviewMode.DESKTOP)}
              disabled={disabled || disabledModes.includes(PreviewMode.DESKTOP)}
              key="preview-desktop"
              Icon={Icons.Desktop}
              aria-label="Preview desktop"
            />
          )}
          {availableModes.includes(PreviewMode.MOBILE) && (
            <IconButton
              variant="subtle"
              onClick={() => handlePreviewModeClick(PreviewMode.MOBILE)}
              disabled={disabled || disabledModes.includes(PreviewMode.MOBILE)}
              Icon={Icons.Mobile}
              aria-label="Preview mobile"
            />
          )}
          {availableModes.includes(PreviewMode.PRODUCT) && (
            <IconButton
              variant="subtle"
              onClick={() => handlePreviewModeClick(PreviewMode.PRODUCT)}
              disabled={disabled || disabledModes.includes(PreviewMode.PRODUCT)}
              Icon={Icons.Product}
              aria-label="Preview product"
            />
          )}
        </div>
      </Popover>
    </>
  )
}

export default PreviewModeControl
