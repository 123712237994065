import { Icons } from '@packages/sk8/icons'
import classNames from 'classnames'
import React from 'react'

import { FormikVariants, VariantFormValue } from '../types'
import { getInventoryItem } from '../utils'
import useSharedSkus from './useSharedSkus'

export interface KickflipSharedCellProps {
  variant: VariantFormValue
  formik: FormikVariants
  focusedSku?: string
  locationId: string
}

const KickflipSharedCell = ({ variant, formik, focusedSku, locationId }: KickflipSharedCellProps) => {
  const inventoryItem = getInventoryItem(formik, variant)

  const skuSharedData = useSharedSkus(locationId, inventoryItem.sku, formik)

  if (inventoryItem.sku === '' || skuSharedData == null || skuSharedData?.variantIds.length < 2) return null

  return (
    <Icons.ArrowsDiagonal
      className={classNames('w-3 h-3 fill-neutral-300', { 'fill-primary-500': focusedSku === inventoryItem?.sku })}
    />
  )
}

export default KickflipSharedCell
