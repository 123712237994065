import { Icons } from '@packages/sk8/icons'
import { Tooltip } from '@packages/sk8/tooltip'
import React from 'react'

import * as coreSelectors from 'builder/build/core/selectors'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'

import { setLeftPanel } from '../actions'
import { LeftPanel } from './../types/leftPanel'

const ViewSettings = () => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const currentView = useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const numberOfViews = useSelector(coreSelectors.viewsSelector)

  const handleViewClick = () => dispatch(dispatch(setLeftPanel(LeftPanel.settings)))

  return (
    <>
      <Tooltip content="Manage views">
        <label
          className="mr-2 mt-0.5 hover:cursor-pointer hover:font-medium w-6 justify-center flex"
          onClick={handleViewClick}
        >
          View
        </label>
      </Tooltip>
      <button
        disabled={numberOfViews === 1}
        onClick={() =>
          customizerDispatch(twoDDisplayerActions.switchView(currentView === 0 ? numberOfViews - 1 : currentView - 1))
        }
      >
        <Icons.ChevronLeft className="mt-1 w-2 h-2" />
      </button>
      <span className="mt-0.5 ml-0.5 mr-0.5">{currentView + 1}</span>
      <button
        className="mr-2"
        disabled={numberOfViews === 1}
        onClick={() =>
          customizerDispatch(twoDDisplayerActions.switchView(currentView === numberOfViews - 1 ? 0 : currentView + 1))
        }
      >
        <Icons.ChevronRight className="mt-1 w-2 h-2" />
      </button>
    </>
  )
}

export default ViewSettings
