import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'
import { useHistory, useParams } from 'react-router'

interface TopBarThemeActionsProps {
  onThemeChangeClick: () => void
  themeId: string
}

const TopBarThemeActions = ({ onThemeChangeClick, themeId }: TopBarThemeActionsProps) => {
  const history = useHistory()
  const popover = usePopover({ placement: 'bottom', offsetConfig: 8 })
  const params = useParams<{ brandName?: string }>()
  const baseUrl = params?.brandName ? `/brands/${params.brandName}` : ''

  const handleGoToVersionHistory = () => {
    popover.close()
    history.push(`${baseUrl}/themes/${themeId}/history`)
  }

  const handleThemeChange = () => {
    popover.close()
    onThemeChangeClick()
  }

  return (
    <>
      <IconButton
        Icon={Icons.ChevronDown}
        variant="subtle"
        small
        smallIcon
        aria-label="Theme actions"
        {...popover.referenceProps}
      />
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        <Popover.Action onClick={handleThemeChange} aria-label="change active theme">
          Change theme
        </Popover.Action>
        <Popover.Action onClick={handleGoToVersionHistory} aria-label="go to version history" disabled={!themeId}>
          Version history
        </Popover.Action>
      </Popover>
    </>
  )
}

export default TopBarThemeActions
