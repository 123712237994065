import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Product } from '@packages/types'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import ProductLimitReachedToast from 'common/components/toast/ProductLimitReachedToast'
import { trpc } from 'common/hooks/trpc'
import ArchiveProductModal from 'common/products/components/ArchiveProductModal'
import DeleteProductModal from 'common/products/components/DeleteProductModal'
import ExportProductModal from 'common/products/components/ExportProductModal'
import RestoreProductModal from 'common/products/components/RestoreProductModal'
import useProductExportImport from 'common/products/hooks/useProductExportImport'
import useProductService from 'common/products/hooks/useProductService'
import useCurrentUser from 'common/users/hooks/useCurrentUser'
import classMerge from 'utils/classMerge'

export interface ProductCardActionsProps {
  product: Product
}

const ProductCardActions = ({ product }: ProductCardActionsProps) => {
  const history = useHistory()
  const match = useRouteMatch<{ brandName: string }>()
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''
  const previewBaseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : '/admin'
  const trpcContext = trpc.useContext()
  const productService = useProductService()
  const popover = usePopover({ placement: 'top-start', offsetConfig: 0 })
  const { openGenericErrorToast, openCustomToast, openToast } = useToast()
  const archiveModal = useModal()
  const exportModal = useModal()
  const restoreModal = useModal()
  const deleteModal = useModal()
  const user = useCurrentUser()
  const { exportProduct } = useProductExportImport()

  const displayProductLimitToast = useCallback(
    () =>
      openCustomToast(props => (
        <ProductLimitReachedToast onUpgradePlan={() => history.push(`${baseUrl}/subscription`)} {...props} />
      )),
    [baseUrl]
  )

  const { mutate: duplicateProduct } = useMutation(() => productService.duplicate(product.id), {
    onSuccess: () => {
      openToast(`Product "${product.name}" was successfully duplicated!`, ToastType.success)
      trpcContext.product.list.invalidate()
    },
    onError(error: AxiosError & { data?: { status: number; name: string } }) {
      if (error.data?.status == 403 && error.data?.name === 'MaximumActiveProductsException') {
        return displayProductLimitToast()
      }
      openGenericErrorToast(`Product "${product.name}" has not been duplicated.`)
    },
  })

  const { mutate: archiveProduct } = useMutation(() => productService.update(product.id, { archived: true }), {
    onSuccess: () => {
      openToast(`Product "${product!.name}" was successfully archived!`, ToastType.success)
      trpcContext.product.list.invalidate()
    },
    onError() {
      openGenericErrorToast(`Product "${product.name}" has not been archived.`)
    },
  })

  const { mutate: restoreProduct } = useMutation(
    () => productService.update(product.id, { archived: false, deleted: false }),
    {
      onSuccess: () => {
        openToast(`Product "${product.name}" was successfully restored`, ToastType.success)
        trpcContext.product.list.invalidate()
      },
      onError(error: AxiosError & { data?: { status: number; name: string } }) {
        if (error.data?.status == 403 && error.data?.name === 'MaximumActiveProductsException') {
          return displayProductLimitToast()
        }
        openGenericErrorToast(`Product "${product.name}" has not been restored.`)
      },
    }
  )

  const onExport = async (field: 'live' | 'draft') => {
    try {
      await exportProduct(product.id, field)
    } catch (error) {
      openGenericErrorToast(`Product "${product.name}" has not been exported.`)
    }
  }

  const withClosePopopver = (callback: () => void) => () => {
    popover.close()
    callback()
  }

  const handleDuplicate = withClosePopopver(() => duplicateProduct())
  const handleArchive = withClosePopopver(() => archiveModal.open())
  const handleDelete = withClosePopopver(() => deleteModal.open())
  const handleExport = withClosePopopver(() => exportModal.open())
  const handleRestore = withClosePopopver(() => restoreModal.open())

  return (
    <>
      <IconButton
        {...popover.referenceProps}
        onClick={e => {
          e.stopPropagation()
          popover.referenceProps.onClick(e)
        }}
        Icon={Icons.VerticalEllipsis}
        role="button"
        aria-label="Open product actions"
        className={classMerge('absolute right-4 fill-black p-[9px] opacity-0 group-hover:opacity-100', {
          'opacity-100': popover.isOpen,
        })}
      />
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        {product.deleted && (
          <Popover.Action onClick={handleRestore} aria-label="restore product">
            Restore
          </Popover.Action>
        )}

        {!product.deleted && product.archived && (
          <>
            <Popover.Action onClick={handleRestore} aria-label="restore product">
              Restore
            </Popover.Action>

            <Popover.Action className="text-tertiary-red-700" onClick={handleDelete} aria-label="delete product">
              Delete
            </Popover.Action>
          </>
        )}

        {!product.deleted && !product.archived && (
          <>
            <Popover.Action
              onClick={withClosePopopver(() => history.push(`${baseUrl}/products/${product.id}/history`))}
              aria-label="version history"
            >
              Version history
            </Popover.Action>
            <Popover.Action
              onClick={withClosePopopver(() =>
                window.open(`${previewBaseUrl}/preview?productId=${product.id}`, '_blank')
              )}
              aria-label="preview product"
            >
              Preview
            </Popover.Action>
            <Popover.Action onClick={handleDuplicate} aria-label="duplicate product">
              Duplicate
            </Popover.Action>
            {user.isMCZRUser && (
              <Popover.Action onClick={handleExport} aria-label="export product">
                Export
              </Popover.Action>
            )}
            <Popover.Action className="text-tertiary-red-700" onClick={handleArchive} aria-label="archive product">
              Archive
            </Popover.Action>
            <Popover.Action className="text-tertiary-red-700" onClick={handleDelete} aria-label="delete product">
              Delete
            </Popover.Action>
          </>
        )}
      </Popover>
      {archiveModal.isVisible && (
        <ArchiveProductModal
          productName={product!.name}
          onArchive={archiveProduct}
          onClose={archiveModal.close}
          {...archiveModal.modalProps}
        />
      )}
      {restoreModal.isVisible && (
        <RestoreProductModal
          productName={product.name}
          onRestore={restoreProduct}
          onClose={restoreModal.close}
          {...restoreModal.modalProps}
        />
      )}
      {exportModal.isVisible && (
        <ExportProductModal
          productName={product.name}
          hasDraft={!!product.draft}
          onExport={onExport}
          onClose={exportModal.close}
          {...exportModal.modalProps}
        />
      )}
      {deleteModal.isVisible && (
        <DeleteProductModal product={product} onClose={deleteModal.close} {...deleteModal.modalProps} />
      )}
    </>
  )
}

export default ProductCardActions
