import { Button } from '@packages/sk8/button'
import { Quote, ECommerce, WixOnlineStore } from '@packages/types'
import React from 'react'

import { Icons } from '@packages/sk8/icons'

import QuoteWixCheckoutLink from './QuoteWixCheckoutLink'

const convertToOrderLabel: Record<ECommerce, string> = {
  [ECommerce.Wix]: 'Generate checkout link',
  [ECommerce.Shopify]: 'Generate Shopify order',
  [ECommerce.CustomStore]: 'Convert to order',
  [ECommerce.Prestashop]: 'Convert to order',
  [ECommerce.Woocommerce]: 'Convert to order',
}

type QuoteWithWixStore = Omit<Quote, 'store'> & { store: WixOnlineStore }

export interface QuoteConvertToOrderSectionProps {
  quote: Quote
  onConvertToOrder: React.MouseEventHandler<HTMLButtonElement>
  isLoading: boolean
}

const QuoteConvertToOrderSection = ({ quote, onConvertToOrder, isLoading }: QuoteConvertToOrderSectionProps) => {
  const isCheckoutUrlAvailable = quote.integration?.wix?.checkoutUrl

  if (isCheckoutUrlAvailable) {
    return (
      <>
        <QuoteWixCheckoutLink quote={quote as QuoteWithWixStore} />
        <hr className="h-auto border border-neutral-100" />
      </>
    )
  }

  return (
    <Button onClick={onConvertToOrder} disabled={isLoading} isLoading={isLoading} type="button">
      <Icons.MoveSheet className="w-3 h-3 mr-2" />
      {convertToOrderLabel[quote.store.eCommerce]}
    </Button>
  )
}

export default QuoteConvertToOrderSection
