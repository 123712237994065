import { Icons } from '@packages/sk8/icons'
import { DenormalizedGroup, EntityType } from '@packages/types'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import AnswersDimensionsWarning from 'builder/build/answers/components/AnswersDimensionsWarning'
import TypeIcons from 'builder/build/common/components/TypeIcons'

import GroupActionsPopover from './GroupActionsPopover'

export interface GroupTreeItemProps {
  group: DenormalizedGroup
  onToggleCollapse: () => void
  isCollapsed: boolean
}

const GroupTreeItem = ({ group, onToggleCollapse, isCollapsed }: GroupTreeItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const history = useHistory()

  const match = useRouteMatch(`/groups/${group.id}`)

  const handleClick = () => history.push(`/groups/${group.id}`)

  const handleNewItem = () => {
    if (isCollapsed) onToggleCollapse()
  }

  const isSelected = !!match

  useEffect(() => {
    if (isSelected && ref.current) ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' })
  }, [isSelected])

  return (
    <div
      ref={ref}
      className="flex group items-center h-full w-full pr-2 hover:bg-neutral-50 aria-selected:bg-neutral-75"
      aria-selected={isSelected}
    >
      <button
        aria-label={`Expand ${group.name}`}
        onClick={e => {
          e.preventDefault()
          onToggleCollapse()
        }}
        className="shrink-0 w-4 mr-0.5 hover:bg-neutral-100 flex items-center justify-center self-stretch"
      >
        <Icons.ChevronDown
          className={classNames('h-2 w-2 transition-transform', {
            '-rotate-90': isCollapsed,
          })}
        />
      </button>
      <div className="w-full flex py-[9px] items-center" onClick={handleClick}>
        <TypeIcons entity={group} />
        <div className="w-full ml-2.5 text-xs overflow-hidden">
          <div className="h-5 flex items-center overflow-hidden">
            <span className='whitespace-nowrap text-ellipsis overflow-hidden group-[[aria-selected="true"]]:font-medium'>
              {group.name}
            </span>
          </div>
        </div>
      </div>
      <GroupActionsPopover group={group} onNewItem={handleNewItem} />
      <div className="ml-1.5">
        {isCollapsed && <AnswersDimensionsWarning entityId={group.id} entityType={EntityType.Group} />}
      </div>
    </div>
  )
}

export default GroupTreeItem
