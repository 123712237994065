import { Card } from '@packages/sk8/card'
import { Icons } from '@packages/sk8/icons'
import { DesignType } from '@packages/types'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import QuoteContext from 'cms/quotes/QuoteContext'

import { selectQuoteTotalItems } from '../../utils'

interface QuoteCardHeaderProps {
  itemIndex: number
  baseUrl: string
}

const QuoteCardHeader = ({ itemIndex, baseUrl }: QuoteCardHeaderProps) => {
  const { isLoadingQuotes, quote } = useContext(QuoteContext)

  const design = quote?.items[itemIndex]?.design
  const isFromBulk = design?.type === DesignType.BulkOrderDesign

  return (
    <Card.Section className="md:flex-col lg:flex-row" data-testid="quote-card-header">
      {!isLoadingQuotes && design ? (
        <div className="rounded-sm w-[4rem] min-w-[4rem] h-14 flex items-center">
          <img
            aria-label={`design ${design.productId} image`}
            src={design.designImage?.url}
            className="rounded-sm w-full"
          />
        </div>
      ) : (
        <div className="animate-pulse w-[4rem] min-w-[4rem] h-14 bg-neutral-75 rounded max-w-xs" />
      )}

      <div className="w-full lg:flex print:flex justify-stretch">
        {isFromBulk && (
          <div className="flex flex-col border-b border-neutral-100 lg:border-r lg:border-b-0 py-4 lg:py-0 px-4 lg:w-1/4">
            <span className="text-xs font-medium text-neutral-600 mb-4">Total items</span>
            {!isLoadingQuotes && design ? (
              <span data-testid="bulk-order-quote-total">{selectQuoteTotalItems(design)}</span>
            ) : (
              <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />
            )}
          </div>
        )}

        <div className="flex flex-col border-b border-neutral-100 lg:border-r lg:border-b-0 py-4 lg:py-0 px-4 lg:w-2/4">
          <span className="text-xs font-medium text-neutral-600 mb-4">Product</span>
          {!isLoadingQuotes && design ? (
            <span>{design.productName}</span>
          ) : (
            <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />
          )}
        </div>

        <div
          className={classNames('flex flex-col lg:text-right print:text-right py-4 lg:py-0', {
            'lg:w-1/4': isFromBulk,
            'lg:w-2/4': !isFromBulk,
          })}
        >
          <span className="text-xs font-medium text-neutral-600 mb-4">Design id</span>
          {!isLoadingQuotes && design ? (
            <Link
              to={`${baseUrl}/designs/${design.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex ml-auto"
              data-testid="design-link"
            >
              {design.designId}
              <Icons.OpenLink className="ml-2 w-4 h-4 print:hidden" />
            </Link>
          ) : (
            <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />
          )}
        </div>
      </div>
    </Card.Section>
  )
}

export default QuoteCardHeader
