import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Group, Question, QuestionBulkConfig } from '@packages/types'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

import AnswersDimensionsWarning from 'builder/build/answers/components/AnswersDimensionsWarning'
import Card from 'builder/build/common/components/cards/Card'
import TypeIcons from 'builder/build/common/components/TypeIcons'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import { patchQuestion } from 'builder/build/questions/actions'
import { useDispatch, useSelector } from 'cms/hooks'
import useMouseOver from 'common/hooks/useMouseOver'
import * as bulkOrderActions from 'customizer/bulkOrder/actions'

import { moveChild } from '../../actions'
import { parentSelector } from '../../selectors'
import BulkOrderElementCardConfig from './BulkOrderElementCardConfig'

export interface BulkOrderElementCardProps {
  id: string
  index: number
  groupId: string
  onClick: () => void
  bulkConfig?: QuestionBulkConfig
  text: string
  entity: Group | Question
  isDragged: boolean
  isDragOverlay: boolean
}

const BulkOrderElementCard = forwardRef<HTMLDivElement, BulkOrderElementCardProps>(
  ({ id, index, groupId, onClick, bulkConfig, text, entity, isDragged, isDragOverlay, ...props }, ref) => {
    const customizerDispatch = useCustomizerDispatch()

    const { isMouseOver, onMouseEnter, onMouseLeave } = useMouseOver()
    const parent = useSelector(state => parentSelector(state, groupId))

    const dispatch = useDispatch()
    const moreDropdownButton = usePopover({ placement: 'bottom-end' })

    const handleRemoveFromBulkClick = async () => {
      moreDropdownButton.close()
      dispatch(patchQuestion(id, { bulkConfig: {} }))
      dispatch(moveChild({ index, parentId: groupId }, { index: 0, parentId: parent!.id }))
    }

    const handleUpdateBulkOptions = <TKey extends keyof QuestionBulkConfig>(
      key: TKey,
      value: QuestionBulkConfig[TKey]
    ) => {
      customizerDispatch(bulkOrderActions.openModal())
      return dispatch(patchQuestion(id, { bulkConfig: { [key]: value } }))
    }

    return (
      <Card
        onClick={onClick}
        ref={ref}
        className={classNames('mx-2 border-neutral-100 border flex-col items-start p-2 space-y-2 bg-white', {
          'opacity-80': isDragOverlay,
          'opacity-50': isDragged,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...props}
      >
        <div className="flex items-center w-full space-x-2">
          <div className="flex flex-1 items-center h-6">
            <TypeIcons entity={entity} />
            <span className="ml-2 mr-auto">{text}</span>
            <AnswersDimensionsWarning entityId={entity.id} entityType={entity.entityType} placement="top-start" />
          </div>
          {(isMouseOver || moreDropdownButton.isOpen) && (
            <>
              <IconButton
                aria-label="More actions"
                variant="subtle"
                small
                Icon={Icons.VerticalEllipsis}
                {...moreDropdownButton.referenceProps}
              />
              <Popover isOpen={moreDropdownButton.isOpen} {...moreDropdownButton.floatingProps}>
                <Popover.Action key="remove" onClick={handleRemoveFromBulkClick}>
                  Remove from bulk order
                </Popover.Action>
              </Popover>
            </>
          )}
        </div>
        <BulkOrderElementCardConfig
          bulkConfig={bulkConfig}
          onDimensionSelect={value => handleUpdateBulkOptions('dimension', value)}
          onLabelChange={value => handleUpdateBulkOptions('label', value)}
          placeholder={text}
        />
      </Card>
    )
  }
)

export default BulkOrderElementCard
