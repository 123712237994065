import { Button, IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { InputField, Select } from '@packages/sk8/input'
import { Modal, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Part, PartType, Question, QuestionType, QuestionInputType } from '@packages/types'
import classNames from 'classnames'
import { findKey } from 'lodash'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

import MultiIconsButton from 'builder/build/common/components/MultiIconsButton'
import TypeIcon from 'builder/build/common/components/TypeIcon'
import TypeIcons from 'builder/build/common/components/TypeIcons'
import * as coreSelectors from 'builder/build/core/selectors'
import * as groupsActions from 'builder/build/groups/actions'
import * as partsActions from 'builder/build/parts/actions'
import * as partsSelector from 'builder/build/parts/selectors'
import * as questionsSelectors from 'builder/build/questions/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'

import ApplyOnSubMenu from './ApplyOnSubMenu'

const PART_QUESTION_FIELDS = constants.parts.questionFields
const RESTRICTED_TYPES = constants.questions.restrictedTypesByInputType
const PART_NEEDING_ASSIGMENT = constants.questions.partNeedingAssignmentByType
const MAIN_QUESTIONS = constants.parts.mainQuestionFields

export const creatableTypes = [
  QuestionType.Value,
  QuestionType.Image,
  QuestionType.Material,
  QuestionType.Logo,
  QuestionType.Text,
  QuestionType.Font,
  QuestionType.FontSize,
  QuestionType.Color,
  QuestionType.Outline,
]

export const namesByType = {
  [QuestionType.Material]: 'Color',
  [QuestionType.Image]: 'Image',
  [QuestionType.Logo]: 'Logo',
  [QuestionType.Color]: 'Text color',
  [QuestionType.Text]: 'Text',
  [QuestionType.Font]: 'Font',
  [QuestionType.FontSize]: 'Font size',
  [QuestionType.Value]: 'None',
  [QuestionType.Outline]: 'Text outline',
} as { [key in QuestionType]: string }

export const descriptionsByType = {
  [QuestionType.Material]: 'Apply colors to discolored product images',
  [QuestionType.Image]: 'Use to display full products or its parts',
  [QuestionType.Color]: 'Change the text color',
  [QuestionType.Text]: 'Use to display text embroidery, prints or engravings',
  [QuestionType.Logo]: 'Use to display logo prints or engravings',
  [QuestionType.Font]: 'Add fonts choices to a text',
  [QuestionType.FontSize]: 'Change the text size',
  [QuestionType.Value]: 'Use when your question does not affect the product visually',
  [QuestionType.Outline]: 'Add an outline to a text',
} as { [key in QuestionType]: string }

const getOptions = (question: Question) =>
  creatableTypes
    .filter(type => !RESTRICTED_TYPES[question.inputType]?.includes(type))
    .map(type => ({
      value: type,
      label: (
        <div className="flex items-center">
          <TypeIcon className="w-[22px] h-[22px] p-[3px] mr-2 rounded" entityType="DISPLAY_TYPE" specificType={type} />

          <div>
            <div className="popper-menu-item__title">{namesByType[type]}</div>
          </div>
        </div>
      ),
    }))

const DisplayTypeSection = ({
  question,
  children = null,
  settings = null,
}: {
  question: Question
  children?: React.ReactNode
  settings?: React.ReactNode
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const modal = useModal()
  const [newDisplayType, setNewDisplayType] = useState<QuestionType | null>(null)
  const rootId = useSelector(coreSelectors.rootIdSelector)
  const part: Part = useSelector(state => partsSelector.partFromQuestionIdSelector(state, question.id))!
  const mainQuestion: Question = useSelector(state =>
    questionsSelectors.questionByIdSelector(state, { id: part?.[MAIN_QUESTIONS[part?.type]] })
  )
  const applyOnButton = usePopover({ placement: 'bottom-end' })
  const settingsButton = usePopover({ placement: 'left' })
  const parts: Part[] = useSelector(partsSelector.partsAsArraySelector)

  const handleModalClose = () => {
    setNewDisplayType(null)
    modal.close()
  }

  const handleDisplayTypeChange = () => {
    dispatch(partsActions.updateQuestionDisplayType(question, newDisplayType))
    handleModalClose()
  }

  const handleApplyChange = (newPart: Part) => {
    applyOnButton.close()

    if (newPart.id !== part?.id) {
      dispatch(partsActions.assignQuestionToADifferentPart(newPart, question))
    }
  }

  const handleRemoveFromPart = () => dispatch(partsActions.removeQuestionFromPart(part, question))

  const handleCreateQuestion = () => {
    const partType = findKey(PART_QUESTION_FIELDS, fields => fields.find(field => field.type === question.type))
    let questionInputType = QuestionInputType.Thumbnail
    if (partType === PartType.Logo) questionInputType = QuestionInputType.File
    if (partType === PartType.Text) questionInputType = QuestionInputType.Text

    const { payload } = dispatch(groupsActions.createPartByType(partType!, rootId, questionInputType))
    dispatch(partsActions.assignQuestionToADifferentPart(payload, question))
    history.push(`/questions/${partType === PartType.Text ? payload.text : payload.image}`)
  }

  const selectableParts = parts.filter(
    ({ id, type }) => id !== part?.id && type === PART_NEEDING_ASSIGMENT[question.type]
  )

  const options = getOptions(question)

  const value = options.find(option => option.value === question.type)

  return (
    <div className="relative px-4 py-6">
      <InputField>
        <div
          className={classNames('font-medium flex justify-between items-center', {
            'mb-2': settings != null,
            'mb-4': settings == null,
          })}
        >
          Display type
          {settings && (
            <>
              <IconButton variant="subtle" Icon={Icons.Cog} {...settingsButton.referenceProps} />
              <Popover
                {...settingsButton.floatingProps}
                isOpen={settingsButton.isOpen}
                className="popper-menu__container builder"
              >
                {settings}
              </Popover>
            </>
          )}
        </div>
        <Select
          value={value}
          options={options}
          onChange={option => {
            setNewDisplayType(option!.value)
            modal.open()
          }}
          menuPlacement="auto"
        />
      </InputField>
      {PART_NEEDING_ASSIGMENT[question.type] && part == null && (
        <div className="flex justify-between items-center space-x-2 mt-4">
          <div className="fill-neutral-600 text-neutral-400 flex flex-col items-center justify-center text-[10px] shrink-0">
            <Icons.ArrowRightDown className="w-4 h-4 -rotate-90 -scale-x-100" />
            Apply on
          </div>
          <MultiIconsButton
            {...(selectableParts.length === 0 ? { onClick: handleCreateQuestion } : applyOnButton.referenceProps)}
            variant="subtle"
            Icon={
              <TypeIcon
                entityType="DISPLAY_TYPE"
                specificType={PART_NEEDING_ASSIGMENT[question.type]}
                className="w-5 p-1 rounded"
              />
            }
          >
            {namesByType[PART_NEEDING_ASSIGMENT[question.type]]} question
          </MultiIconsButton>
          <Popover {...applyOnButton.floatingProps} isOpen={applyOnButton.isOpen} className="builder">
            <ApplyOnSubMenu
              parts={selectableParts}
              questionType={MAIN_QUESTIONS[PART_NEEDING_ASSIGMENT[question.type]] as QuestionType}
              onChange={handleApplyChange}
              onCreateQuestion={handleCreateQuestion}
            />
          </Popover>
        </div>
      )}
      {PART_NEEDING_ASSIGMENT[question.type] && part != null && (
        <div className="flex justify-between items-center space-x-2 mt-4">
          <div className="fill-neutral-600 text-neutral-400 flex flex-col items-center justify-center text-[10px]">
            <Icons.ArrowRightDown className="w-4 h-4 -rotate-90 -scale-x-100" />
            Apply on
          </div>
          <div className="bg-neutral-75 h-8 rounded-md flex items-center text-xs flex-grow pl-2 pr-1" title={part.name}>
            <TypeIcons entity={mainQuestion} />
            <div className="ml-2 mr-auto">{part.name}</div>
            <IconButton variant="subtle" onClick={handleRemoveFromPart} Icon={Icons.XMark} small />
          </div>
        </div>
      )}
      {children}
      {modal.isVisible && (
        <Modal onBackdropClick={handleModalClose} {...modal.modalProps}>
          <Modal.CloseButton onClick={handleModalClose} />
          <Modal.Title>Change display type</Modal.Title>

          <Modal.Details>
            Are you sure you want to change the display type? This will modify your answers.
          </Modal.Details>

          {question.inputType === QuestionInputType.File && newDisplayType === QuestionType.Logo && (
            <Modal.Details className="italic">
              Changing the display type to Logo will remove the current File values.
            </Modal.Details>
          )}

          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button type="button" variant="error" className="px-4" onClick={handleDisplayTypeChange}>
              Change
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  )
}

export default DisplayTypeSection
