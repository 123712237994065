import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Tooltip } from '@packages/sk8/tooltip'
import { Answer, AnswerType, FontSizeView, Part, QuestionType } from '@packages/types'
import classNames from 'classnames'
import { throttle } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import * as answersActions from 'builder/build/answers/actions'
import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import { useDispatch } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'
import getFontSizeFromFontStyle from 'utils/getFontSizeFromFontStyle'
import { min } from 'utils/validators'

import AddQuestionButton from './AddQuestionButton'

interface DefaultFontSizeSectionProps {
  part: Part
  answer?: Answer
  disabled?: boolean
  questionId?: string
}

const defaultAnswer = constants.answers.default[AnswerType.FontSize] as FontSizeView

const DefaultFontSizeSection = ({ part, answer, disabled = false, questionId }: DefaultFontSizeSectionProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dropdownButton = usePopover({ placement: 'bottom' })
  const currentView = useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const answerView = (answer?.views?.[currentView] as FontSizeView | undefined) || defaultAnswer
  const [internalValue, setInternalValue] = useState(getFontSizeFromFontStyle(answerView.size)!)
  const isDisabled = disabled || !!questionId

  const handleOpenQuestionClick = () => history.push(`/questions/${questionId}`)

  const updateFontSize = useCallback(
    (value: number) => {
      if (!disabled) {
        dispatch(answersActions.patchAnswer(answer, { views: { [currentView]: { size: `${value}px` } } }))
      }
    },
    [answer?.id, currentView, isDisabled]
  )

  const updateFontSizeThrottled = useCallback(throttle(updateFontSize, 200), [updateFontSize])

  useEffect(() => {
    if (getFontSizeFromFontStyle(answerView.size) !== internalValue) updateFontSizeThrottled(internalValue)
  }, [internalValue])

  useEffect(() => {
    const fontSize = getFontSizeFromFontStyle(answerView.size)!
    if (fontSize !== internalValue) setInternalValue(fontSize)
  }, [answer?.id, currentView])

  return (
    <>
      <Tooltip
        containerClassName="flex"
        content={
          questionId ? (
            <div className="flex items-center space-x-2">
              <span>Font sizes are handled by a question.</span>
              <Button onClick={handleOpenQuestionClick} variant="text">
                Open
              </Button>
            </div>
          ) : (
            'Font size'
          )
        }
      >
        <PatchTextInput
          className="!w-20 !h-7 px-1.5"
          inputClassName="appearance-number-none"
          value={String(internalValue)}
          onChange={e => setInternalValue(+e.target.value)}
          type="number"
          min="0"
          validator={min(0)}
          rightAddon="px"
          disabled={isDisabled}
        />
        <button
          className={classNames('ml-2', { 'fill-neutral-800/40': isDisabled })}
          disabled={isDisabled}
          {...dropdownButton.referenceProps}
        >
          <Icons.ChevronDown className="w-2" />
        </button>
      </Tooltip>
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
        <div className="px-2 py-4">
          <input
            type="range"
            min="0"
            max="500"
            value={String(internalValue)}
            onChange={e => setInternalValue(+e.target.value)}
            className="w-full mb-4"
          />
          <AddQuestionButton onClick={dropdownButton.close} part={part} type={QuestionType.FontSize} />
        </div>
      </Popover>
    </>
  )
}

export default DefaultFontSizeSection
