import { Button, IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Modal, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Answer, EntityType, Question } from '@packages/types'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

import * as answerActions from 'builder/build/answers/actions'
import AnswerPanel from 'builder/build/answers/components/AnswerPanel'
import AnswersDimensionsWarning from 'builder/build/answers/components/AnswersDimensionsWarning'
import Card from 'builder/build/common/components/cards/Card'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as rulesActions from 'builder/build/rules/actions'
import { useDispatch, useSelector } from 'cms/hooks'
import * as customizationActions from 'customizer/customization/actions'

import { removeAnswer, setDefaultAnswer, addDefaultAnswer, removeDefaultAnswer, unlinkAnswer } from '../../actions'
import { isAnswerLinkedSelector } from '../../selectors'
import AnswerCardThumbnail from './AnswerCardThumbnail'
export interface AnswerCardProps {
  answer: Answer
  defaultAnswer?: string | string[]
  question: Question
  onClick: () => void
  closePanel: () => void
  isSelected: boolean
  isDragged: boolean
  isDragOverlay: boolean
}

export const AnswerCard = forwardRef<HTMLDivElement, AnswerCardProps>(
  ({ answer, defaultAnswer, question, onClick, closePanel, isSelected, isDragOverlay, ...props }, ref) => {
    const dispatch = useDispatch()
    const customizerDispatch = customizerHooks.useCustomizerDispatch()
    const archiveModal = useModal()
    const restoreModal = useModal()
    const dropdownButton = usePopover({ placement: 'bottom-end' })

    const isLinked = useSelector(state =>
      (isAnswerLinkedSelector as any)(state, { answerId: answer.id, questionsToIgnore: [question.id] })
    )

    const isDefault = question.isMultiAnswer ? defaultAnswer?.includes(answer.id) : answer.id === defaultAnswer

    const isAddToDefaultDisabled =
      question.isMultiAnswer &&
      typeof question.maxSelections === 'number' &&
      !isNaN(question.maxSelections) &&
      defaultAnswer != null &&
      question.maxSelections <= defaultAnswer.length

    const withClose = (callback: () => void) => () => {
      dropdownButton.close()
      callback()
    }

    const handleRemove = () => {
      dispatch(removeAnswer(question.id, answer.id))
      dispatch(rulesActions.removeAnswerFromRules(question.id, answer.id))
    }

    const handleRestore = () => dispatch(answerActions.restoreAnswer(answer.id))

    const handleRestoreLinkedAnswer = () => {
      const { payload } = dispatch(unlinkAnswer(question.id, answer.id))
      dispatch(answerActions.restoreAnswer(payload.newAnswerId))
    }

    const handleArchive = withClose(() => dispatch(answerActions.archiveAnswer(answer.id)))

    const handleArchiveLinkedAnswer = () => {
      const { payload } = dispatch(unlinkAnswer(question.id, answer.id))
      dispatch(answerActions.archiveAnswer(payload.newAnswerId))
    }

    const handleSetDefault = () => {
      question.isMultiAnswer
        ? dispatch(addDefaultAnswer(question.id, answer.id))
        : dispatch(setDefaultAnswer(question.id, answer.id))

      customizerDispatch(customizationActions.setQuestionDefaultConfiguration(question.id))
    }

    const handleRemoveDefault = () => {
      dispatch(removeDefaultAnswer(question.id, answer.id))
      customizerDispatch(customizationActions.setQuestionDefaultConfiguration(question.id))
    }

    const dropdownOptions = []

    if (question.isMultiAnswer && !answer.archived) {
      if (isDefault) {
        dropdownOptions.push(
          <Popover.Action key="set-default" onClick={withClose(handleRemoveDefault)}>
            Remove from default values
          </Popover.Action>
        )
      } else {
        dropdownOptions.push(
          <Popover.Action key="set-default" onClick={withClose(handleSetDefault)} disabled={isAddToDefaultDisabled}>
            Add to default values
          </Popover.Action>
        )
      }
    }

    if (!question.isMultiAnswer && !answer.archived && !isDefault) {
      dropdownOptions.push(
        <Popover.Action key="set-default" onClick={withClose(handleSetDefault)}>
          Set as default
        </Popover.Action>
      )
    }

    if (answer.archived) {
      dropdownOptions.push(
        <Popover.Action key="restore" onClick={isLinked ? withClose(restoreModal.open) : withClose(handleRestore)}>
          Restore
        </Popover.Action>
      )
    } else {
      dropdownOptions.push(
        <Popover.Action key="archive" onClick={isLinked ? withClose(archiveModal.open) : withClose(handleArchive)}>
          Archive
        </Popover.Action>
      )
    }

    dropdownOptions.push(
      <Popover.Action key="remove" onClick={withClose(handleRemove)}>
        Remove
      </Popover.Action>
    )

    return (
      <>
        <Card
          className={classNames('group shadow-xs border border-solid border-neutral-100 overflow-hidden relative', {
            '!text-primary-600 !font-medium ring-primary-300 ring-2 border-transparent': isSelected,
            '!text-opacity-50': answer.archived,
            'opacity-50': props.isDragged,
            'border-tertiary-green-600 border-opacity-20': isDefault,
            'mx-4': !isDragOverlay,
          })}
          ref={ref}
          onClick={onClick}
          {...props}
        >
          <div className="flex">
            <AnswerCardThumbnail answer={answer} />
            <AnswersDimensionsWarning
              className="!absolute translate-x-[14px] -translate-y-[2px]"
              entityId={answer.id}
              entityType={EntityType.Answer}
              placement="top-start"
            />
          </div>
          <span className="mr-auto">{answer.name}</span>
          <div className="flex items-center space-x-1">
            <IconButton
              aria-label="More options"
              variant="subtle"
              small
              id={`${answer.id}-more`}
              Icon={Icons.VerticalEllipsis}
              {...dropdownButton.referenceProps}
              className={classNames('group-hover:opacity-100', {
                'opacity-0': !dropdownButton.isOpen,
                'opacity-100': dropdownButton.isOpen,
              })}
            />
            {isDefault && <Icons.Checkmark className="w-3 h-3 fill-tertiary-green-400" />}
            {answer.archived && <Icons.SubtractCircle className="w-3 h-3 fill-tertiary-red-300" />}
          </div>
          <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
            {dropdownOptions}
          </Popover>
        </Card>
        {isSelected && <AnswerPanel answer={answer} close={closePanel} />}
        {archiveModal.isVisible && (
          <Modal onBackdropClick={archiveModal.close} {...archiveModal.modalProps}>
            <Modal.CloseButton onClick={archiveModal.close} />
            <Modal.Title>Archive answer {answer.name}</Modal.Title>

            <Modal.Details>
              This answer is used by other questions. If you want to archive this answer in other questions, select
              ARCHIVE ALL.
            </Modal.Details>

            <Modal.Actions>
              <Button type="button" variant="default" className="px-4" onClick={archiveModal.close}>
                Cancel
              </Button>
              <Button
                type="button"
                variant="default"
                className="px-4"
                onClick={() => {
                  archiveModal.close()
                  handleArchiveLinkedAnswer()
                }}
              >
                Archive
              </Button>
              <Button
                type="button"
                variant="primary"
                className="px-4"
                onClick={() => {
                  archiveModal.close()
                  handleArchive()
                }}
              >
                Archive all
              </Button>
            </Modal.Actions>
          </Modal>
        )}
        {restoreModal.isVisible && (
          <Modal onBackdropClick={restoreModal.close} {...restoreModal.modalProps}>
            <Modal.CloseButton onClick={restoreModal.close} />
            <Modal.Title>Restore answer {answer.name}</Modal.Title>

            <Modal.Details>
              This answer is used by other questions. If you want to restore this answer in other questions, select
              RESTORE ALL.
            </Modal.Details>

            <Modal.Actions>
              <Button type="button" variant="default" className="px-4" onClick={restoreModal.close}>
                Cancel
              </Button>
              <Button
                type="button"
                variant="default"
                className="px-4"
                onClick={() => {
                  restoreModal.close()
                  handleRestoreLinkedAnswer()
                }}
              >
                Restore
              </Button>
              <Button
                type="button"
                variant="primary"
                className="px-4"
                onClick={() => {
                  restoreModal.close()
                  handleRestore()
                }}
              >
                Restore all
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </>
    )
  }
)

export default AnswerCard
