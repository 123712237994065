import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import { BetaTag } from '@packages/sk8/tag'
import { Tooltip } from '@packages/sk8/tooltip'
import { BulkOrder } from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import { questionsSelector } from 'builder/build/questions/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import * as bulkOrderActions from 'customizer/bulkOrder/actions'
import { bulkOrderIsFormVisibleSelector } from 'customizer/bulkOrder/selectors'

import { addChild } from '../../actions'
import GroupAddSection from '../GroupAddSection'
import NewQuestionMenu from '../NewQuestionMenu'
import TitleSection from '../TitleSection'
import AddToBulkOrderMenu from './AddToBulkOrderMenu'
import BulkOrderElementList from './BulkOrderElementList'
import BulkOrderQuantityCard from './BulkOrderQuantityCard'

export interface BulkOrderTabProps {
  group: BulkOrder
}

const BulkOrderTab = ({ group }: BulkOrderTabProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false)

  const questions = useSelector(questionsSelector)

  const popover = usePopover({
    placement: 'left-start',
    offsetConfig: 8,
    shiftConfig: { mainAxis: true, crossAxis: false, padding: 16 },
  })

  const handleTogglePopover = () => {
    if (popover.isOpen) {
      popover.close()
    } else {
      setIsCreatingQuestion(false)
      popover.open()
    }
  }

  const handleAddToBulkOrder = (questions: string[]) => {
    popover.close()

    questions.reverse().forEach(questionId => {
      dispatch(addChild(group.id, questionId, group.children.length))
      return history.push(`/questions/${questionId}`)
    })
  }
  const isBulkOrderModalVisible = useCustomizerSelector(bulkOrderIsFormVisibleSelector)

  const handleOpenModal = () =>
    customizerDispatch(isBulkOrderModalVisible ? bulkOrderActions.closeModal() : bulkOrderActions.openModal())

  return (
    <>
      <div className="absolute w-full px-4 pt-2 z-[2] flex justify-end">
        <BetaTag />
      </div>
      <TitleSection group={group} />
      <hr className="border-neutral-100" />
      <div className="py-6">
        <GroupAddSection label="Bulk questions">
          <div className="flex space-x-1">
            <Tooltip content="Preview">
              <IconButton
                variant="subtle"
                aria-label="preview bulk order modal"
                Icon={Icons.Show}
                onClick={handleOpenModal}
              />
            </Tooltip>

            <IconButton
              iconClassName={classNames('transition-transform', { 'rotate-45': popover.isOpen })}
              Icon={Icons.Add}
              smallIcon
              {...popover.referenceProps}
              onClick={handleTogglePopover}
            />
          </div>

          <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
            {!isCreatingQuestion && (
              <AddToBulkOrderMenu
                bulkOrderGroup={group}
                onAddQuestions={handleAddToBulkOrder}
                onCreateQuestion={() => setIsCreatingQuestion(true)}
                questions={Object.values(questions)}
                onClose={popover.close}
              />
            )}

            {isCreatingQuestion && (
              <NewQuestionMenu groupId={group.id} onClose={popover.close} gifPlacement="left-start" />
            )}
          </Popover>
        </GroupAddSection>

        <div className="p-2">
          <BulkOrderElementList group={group} />
          <hr className="flex-grow h-[1px] border-neutral-75 m-2" />
          <BulkOrderQuantityCard group={group} />
        </div>
      </div>
    </>
  )
}

export default BulkOrderTab
