import { Icons } from '@packages/sk8/icons'
import { TextVerticalAlign } from '@packages/types'
import classNames from 'classnames'
import { Draggable, MoveableManagerInterface, MoveableProps, Renderer, Rotatable } from 'react-moveable'

import { Point, getRotationFromPoints } from 'utils/math'

export interface RenderableProps {
  style: {
    editionColor: string
    editionIconColor: string
  }
}

const getPosition = (
  topLeft: Point,
  topRight: Point,
  bottomLeft: Point,
  bottomRight: Point,
  verticalAlign: TextVerticalAlign
) => {
  switch (verticalAlign) {
    case TextVerticalAlign.Top:
      return { from: topLeft, to: topRight }
    case TextVerticalAlign.Bottom:
      return { from: bottomLeft, to: bottomRight }
    case TextVerticalAlign.Middle:
    default:
      return {
        from: { x: topLeft.x + (bottomLeft.x - topLeft.x) / 2, y: topLeft.y + (bottomLeft.y - topLeft.y) / 2 },
        to: { x: topRight.x + (bottomRight.x - topRight.x) / 2, y: topRight.y + (bottomRight.y - topRight.y) / 2 },
      }
  }
}

const iconsByVerticalAlign = {
  top: Icons.VerticalAlignTop,
  middle: Icons.VerticalAlignMiddle,
  bottom: Icons.VerticalAlignBottom,
}

const Renderable = {
  name: 'customRenderable',
  props: ['verticalAlign'] as const,
  events: [] as const,
  always: true,
  render: (
    moveable: MoveableManagerInterface<MoveableProps & { verticalAlign: TextVerticalAlign }>,
    React: Renderer
  ) => {
    const { verticalAlign } = moveable.props
    const { renderPoses } = moveable.getState()
    const [topLeft, topRight, bottomLeft, bottomRight] = renderPoses.map(pos => ({ x: pos[0], y: pos[1] }))
    const { from, to } = getPosition(topLeft, topRight, bottomLeft, bottomRight, verticalAlign)
    const rad = getRotationFromPoints([from, to])
    const vec1 = to.x - from.x
    const vec2 = to.y - from.y
    const width = Math.sqrt(vec1 * vec1 + vec2 * vec2)

    const Icon = iconsByVerticalAlign[verticalAlign]

    return (
      <div
        key="line-multiline"
        className="moveable-line"
        data-rotation={-1}
        style={{
          transform: `translateY(-50%) translate(${from.x}px, ${from.y}px) rotate(${rad}rad) scaleY(${moveable.props.zoom})`,
          width: `${width}px`,
          height: '2px',
        }}
      >
        {!moveable.isDragging(Draggable.name) && !moveable.isDragging(Rotatable.name) && (
          <Icon
            className={classNames(
              'w-3.5 h-3.5 -translate-x-full -left-2 absolute stroke-primary-500 !fill-primary-500',
              {
                '-translate-y-full top-0.5': verticalAlign === TextVerticalAlign.Bottom,
                '-translate-y-1/2 top-[1px]': verticalAlign === TextVerticalAlign.Middle,
              }
            )}
          />
        )}
      </div>
    )
  },
} as const

export default Renderable
