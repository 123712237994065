import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { HelperText, Input, InputField, Select } from '@packages/sk8/input'
import { Popover, usePopover } from '@packages/sk8/popover'
import {
  Equation,
  EquationLine,
  EquationLineType,
  NormalizedCustomizerProduct,
  QuestionInputType,
} from '@packages/types'
import classNames from 'classnames'
import { FieldArrayRenderProps, useFormikContext } from 'formik'
import { get } from 'lodash'
import React from 'react'

import ProductQuestionsDropdown from 'common/components/ProductQuestionsDropdown'

import EquationModalAnswers from './ModalAnswers'
import { operatorOptions } from './utils'

interface EquationLineFieldsProps {
  index: number
  line: EquationLine
  arrayHelpers: FieldArrayRenderProps
  excludedQuestions: string[]
  customizerProduct: NormalizedCustomizerProduct
}

const EquationModalLine = ({
  index,
  line,
  arrayHelpers,
  excludedQuestions,
  customizerProduct,
}: EquationLineFieldsProps) => {
  const dropdownButton = usePopover({ placement: 'bottom-end' })

  const toggleClasses = (isActive: boolean) =>
    classNames('rounded-md focus:outline-none px-3', {
      'ring-1 ring-neutral-200 bg-neutral-50': isActive,
    })

  const fadingInClasses =
    'transition border-transparent group-hover:!border-neutral-100 !shadow-[0_0_0_transparent] group-hover:!shadow-[0px_1px_2px_rgba(0,0,0,0.07)] group-hover:hover:!shadow'

  const nameComposer = (name: string) => `lines.${index}.${name}`

  const formik = useFormikContext<Equation>()
  const { lines } = formik.values

  const handleMoveDown = () => {
    const isLastButOneLine = index === lines.length - 2
    const isIndexValid = index >= 0
    const shouldUpdateNextLineOperator = isLastButOneLine && isIndexValid

    if (shouldUpdateNextLineOperator) {
      formik.setFieldValue(`lines.${index + 1}.operator`, line.operator)
    }

    arrayHelpers.move(index, index + 1)
    dropdownButton.close()
  }

  const handleMoveUp = () => {
    const isLastLine = index === lines.length - 1
    const isIndexValid = index > 0
    const shouldUpdateCurrentLineOperator = isLastLine && isIndexValid

    if (shouldUpdateCurrentLineOperator) {
      const prevLine = lines[index - 1]
      formik.setFieldValue(nameComposer('operator'), prevLine.operator)
    }

    arrayHelpers.move(index, index - 1)
    dropdownButton.close()
  }

  return (
    <>
      <div className="grid grid-cols-[76px_200px_auto_32px] mb-3 gap-2 group">
        <div className="h-8 flex border-neutral-50 shadow-xs rounded-md">
          <button
            className={toggleClasses(get(formik.values, nameComposer('type')) === EquationLineType.Question)}
            onClick={() => {
              if (line.type === EquationLineType.Question) return
              formik.setFieldValue(nameComposer('type'), EquationLineType.Question)
              formik.setFieldTouched(nameComposer('value'), false)
              formik.setFieldValue(nameComposer('value'), '')
              formik.setFieldValue(nameComposer('answersMap'), {})
            }}
            data-testid={`toggle-question-${index}`}
          >
            <Icons.Question />
          </button>
          <button
            className={toggleClasses(get(formik.values, nameComposer('type')) === EquationLineType.Constant)}
            onClick={() => {
              if (line.type === EquationLineType.Constant) return
              formik.setFieldValue(nameComposer('type'), EquationLineType.Constant)
              formik.setFieldTouched(nameComposer('value'), false)
              formik.setFieldValue(nameComposer('value'), '')
              formik.setFieldValue(nameComposer('answersMap'), {})
            }}
            data-testid={`toggle-constant-${index}`}
          >
            <Icons.Number />
          </button>
        </div>
        <InputField>
          {get(formik.values, nameComposer('type')) === EquationLineType.Constant ? (
            <Input
              id={nameComposer('value')}
              name={nameComposer('value')}
              type="number"
              min={0}
              placeholder="0"
              value={line.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-label={'equation line ' + index}
              hasError={!!get(formik.touched, nameComposer('value')) && !!get(formik.errors, nameComposer('value'))}
            />
          ) : (
            <ProductQuestionsDropdown
              customizerProduct={customizerProduct}
              exclude={excludedQuestions}
              onSelect={({ value }) => {
                formik.setFieldValue(nameComposer('value'), value)
                formik.setFieldValue(nameComposer('answersMap'), {})

                if (line.type !== EquationLineType.Question) return
                const question = customizerProduct.questions[value]

                if (
                  !question ||
                  question.inputType === QuestionInputType.Text ||
                  question.inputType === QuestionInputType.Number
                )
                  return

                const answers = question.answers
                if (answers == null) return

                const answersMap = Object.fromEntries(answers.map(answer => [answer, 0]))
                formik.setFieldValue(nameComposer('answersMap'), answersMap)
              }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: base => {
                  return {
                    ...base,
                    zIndex: 9999,
                  }
                },
              }}
              value={get(formik.values, nameComposer('value'))}
              hasError={!!get(formik.touched, nameComposer('value')) && !!get(formik.errors, nameComposer('value'))}
              aria-label={'equation line ' + index}
            />
          )}
          {get(formik.touched, nameComposer('value')) && get(formik.errors, nameComposer('value')) && (
            <HelperText hasError>{get(formik.errors, nameComposer('value'))}</HelperText>
          )}
        </InputField>
        {index < lines.length - 1 && (
          <InputField>
            <Select
              id={nameComposer('operator')}
              name={nameComposer('operator')}
              aria-label={'operator line ' + index}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: base => {
                  return {
                    ...base,
                    zIndex: 9999,
                  }
                },
              }}
              value={operatorOptions.find(o => o?.value === get(formik.values, nameComposer('operator')))}
              onChange={option => {
                formik.setFieldValue(nameComposer('operator'), option?.value)
              }}
              onBlur={formik.handleBlur}
              options={operatorOptions}
              hasError={
                !!get(formik.touched, nameComposer('operator')) && !!get(formik.errors, nameComposer('operator'))
              }
              classNames={{
                control: () => classNames('w-16 !border-transparent', fadingInClasses),
                dropdownIndicator: () => 'opacity-0 group-hover:opacity-100 !transition',
                option: state => (state.isSelected ? 'fill-[white]' : ''),
              }}
            />
          </InputField>
        )}
        <div className="opacity-0 group-hover:opacity-100 transition">
          <IconButton
            data-testid="line-options"
            aria-label="Line options"
            Icon={Icons.VerticalEllipsis}
            iconClassName="w-3"
            {...dropdownButton.referenceProps}
          />
          <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
            <Popover.Action key="move-up" data-testid="move-up" onClick={handleMoveUp} disabled={index === 0}>
              Move up
            </Popover.Action>
            <Popover.Action
              key="move-down"
              data-testid="move-down"
              onClick={handleMoveDown}
              disabled={index === lines.length - 1}
            >
              Move down
            </Popover.Action>
            <Popover.Action
              key="delete"
              data-testid="remove-line"
              className={lines.length <= 2 ? '' : 'text-tertiary-red-700'}
              onClick={() => {
                arrayHelpers.remove(index)
                dropdownButton.close()
              }}
              disabled={lines.length <= 2}
            >
              Delete
            </Popover.Action>
          </Popover>
        </div>
      </div>
      <EquationModalAnswers line={line} customizerProduct={customizerProduct} nameBase={nameComposer('answersMap')} />
    </>
  )
}

export default EquationModalLine
