import { Icons } from '@packages/sk8/icons'
import classNames from 'classnames'
import React from 'react'

import useSharedSkus from '../../hooks/useSharedSkus'

export interface SharedCellProps {
  sku: string
  focusedSku?: string
}

const SharedCell = ({ sku, focusedSku }: SharedCellProps) => {
  const skuSharedData = useSharedSkus(sku)

  if (skuSharedData == null || skuSharedData?.variantIds.length < 2) return null

  return (
    <Icons.ArrowsDiagonal
      className={classNames('w-3 h-3 fill-neutral-300', {
        'fill-primary-500': focusedSku === sku,
      })}
    />
  )
}

export default SharedCell
