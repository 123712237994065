import { Button, IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Modal, useModal } from '@packages/sk8/modal'
import { Tooltip } from '@packages/sk8/tooltip'
import { PrintArea } from '@packages/types'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import MaskSection from 'builder/build/common/components/maskSection/MaskSection'
import MovablePanel from 'builder/build/common/components/panels/MovablePanel/MovablePanel'
import MovablePanelHeader from 'builder/build/common/components/panels/MovablePanel/MovablePanelHeader'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import * as partsActions from 'builder/build/parts/actions'
import * as partsSelectors from 'builder/build/parts/selectors'
import * as previewActions from 'builder/build/preview/actions'
import * as previewSelectors from 'builder/build/preview/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import { PreviewMode } from 'common/preview/types/previewMode'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'

import { setMask, deletePrintArea } from '../actions'
import DesignViewSection from './sections/DesignViewSection'
import PrintAreaDimensionsSection from './sections/PrintAreaDimensionsSection'
import PrintAreaPreviewSection from './sections/PrintAreaPreviewSection'
import ShowPrintAreaSection from './sections/ShowPrintAreaSection'
import TitleSection from './sections/TitleSection'
import ViewsSection from './sections/ViewsSection'

interface PrintAreaPanelProps {
  printArea: PrintArea
  onClose: () => void
}

const PrintAreaPanel = ({ printArea, onClose }: PrintAreaPanelProps) => {
  const deleteButtonRef = useRef<HTMLButtonElement>(null)
  const doneButtonRef = useRef<HTMLButtonElement>(null)
  const [dragRef, setDragRef] = useState<HTMLDivElement>()
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const deleteModal = useModal()
  const parts = useSelector(partsSelectors.partsAsArraySelector)
  const previewMode = useSelector(previewSelectors.previewModeSelector)
  const initialPreviewMode = useMemo(() => previewMode, [])

  const handleApplyMask = (masks: string[]) => {
    dispatch(setMask(printArea.id, masks))
  }

  const handleChangeView = (view: number) => customizerDispatch(twoDDisplayerActions.switchView(view))

  const handleDoneClick = () => {
    doneButtonRef.current?.focus()
    onClose()
  }

  const handleDeleteClick = () => {
    deleteButtonRef.current?.focus()
    deleteModal.open()
  }

  const handleDelete = () => {
    deleteModal.close()
    onClose()
    dispatch(partsActions.removePrintAreaFromParts(printArea.id, parts))
    dispatch(deletePrintArea(printArea.id))
  }

  useEffect(() => {
    customizerDispatch(previewActions.allowEditPrintArea(printArea.id))
    customizerDispatch(previewActions.changePreviewMode(PreviewMode.PRODUCT))
    dispatch(previewActions.changePreviewMode(PreviewMode.PRODUCT))
    dispatch(previewActions.setDisabledModes([PreviewMode.DESKTOP, PreviewMode.MOBILE]))

    return () => {
      customizerDispatch(previewActions.allowEditPrintArea(null))
      dispatch(previewActions.setDisabledModes([]))
      dispatch(previewActions.changePreviewMode(initialPreviewMode))
      customizerDispatch(previewActions.changePreviewMode(initialPreviewMode))
    }
  }, [])

  return (
    <MovablePanel dragRef={dragRef}>
      <div className="flex flex-col h-full">
        <MovablePanelHeader setDragRef={setDragRef}>
          <div className="popper-menu-header-section__title">{printArea.name}</div>
          <div className="flex">
            <Tooltip content="Delete">
              <IconButton
                ref={deleteButtonRef}
                className="!h-7 !w-7 !p-0.5 mr-1"
                onClick={handleDeleteClick}
                data-testid="header-close-button"
                iconClassName="text-error-default ml-[2px] w-4 h-4"
                Icon={Icons.Bin}
              />
            </Tooltip>
            <Button ref={doneButtonRef} className="!h-7" onClick={handleDoneClick} data-testid="header-close-button">
              Done
            </Button>
          </div>
        </MovablePanelHeader>
        <div className="overflow-y-auto">
          <TitleSection printArea={printArea} />
          <hr className="border-neutral-100" />
          <div className="relative px-4 py-6">
            <DesignViewSection printArea={printArea} onChangeView={handleChangeView} />
            <PrintAreaPreviewSection printArea={printArea} />
          </div>
          <hr className="border-neutral-100" />
          <PrintAreaDimensionsSection printArea={printArea} />
          <hr className="border-neutral-100" />
          <div className="relative px-4 py-6">
            <ShowPrintAreaSection printArea={printArea} />
          </div>
          <hr className="border-neutral-100" />
          <div className="relative px-4 py-6">
            <MaskSection
              onApply={handleApplyMask}
              entityMasks={printArea.productPreview.masks}
              maskMessage={`Your logo will be masked if it goes \nout of the selected components.`}
            />
          </div>
          <hr className="border-neutral-100" />
          <div className="relative px-4 py-6">
            <ViewsSection printArea={printArea} />
          </div>
        </div>
        {deleteModal.isVisible && (
          <Modal onBackdropClick={deleteModal.close} {...deleteModal.modalProps}>
            <Modal.CloseButton onClick={deleteModal.close} />
            <Modal.Title>Delete {printArea.name}</Modal.Title>

            <Modal.Details>
              Are you sure you want to delete the print area {printArea.name}? This action cannot be undone.
            </Modal.Details>

            <Modal.Actions>
              <Button type="button" variant="default" className="px-4" onClick={deleteModal.close}>
                Cancel
              </Button>
              <Button type="button" variant="error" className="px-4" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    </MovablePanel>
  )
}

export default PrintAreaPanel
