import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'

import useCopyToClipboard from 'common/hooks/useCopyToClipboard'

interface UsersActionsProps {
  email: string
  isInvited: boolean
  onDelete: () => void
  resendInvite?: () => void
}

const UsersActions = ({ email, isInvited, onDelete, resendInvite }: UsersActionsProps) => {
  const popover = usePopover({ placement: 'top-end', offsetConfig: 8 })
  const copyToClipboard = useCopyToClipboard()
  return (
    <div className="relative">
      <IconButton
        variant="subtle"
        {...popover.referenceProps}
        Icon={Icons.VerticalEllipsis}
        aria-label="More options"
        small
      />
      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        <Popover.Action
          onClick={() => {
            copyToClipboard(email)
            popover.close()
          }}
        >
          Copy email
        </Popover.Action>
        {isInvited && (
          <Popover.Action
            onClick={() => {
              popover.close()
              resendInvite!()
            }}
          >
            Resend invite
          </Popover.Action>
        )}
        <Popover.Action
          className="text-tertiary-red-700"
          onClick={() => {
            popover.close()
            onDelete()
          }}
        >
          Delete
        </Popover.Action>
      </Popover>
    </div>
  )
}

export default UsersActions
