import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Tooltip } from '@packages/sk8/tooltip'
import { ECommerce } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

interface LinkSectionProps {
  toolTipMessage: string
  eCommerce: ECommerce.Prestashop | ECommerce.Woocommerce
  handleSyncCodeClick: React.MouseEventHandler
  isLoading: boolean
  isOffline: boolean
  isConnected: boolean
  available?: boolean
}

const eCommerceDisplayName = {
  [ECommerce.Woocommerce]: 'WooCommerce',
  [ECommerce.Prestashop]: 'Prestashop',
}

const LinkSection = ({
  toolTipMessage,
  eCommerce,
  handleSyncCodeClick,
  available,
  isOffline,
  isLoading,
  isConnected,
}: LinkSectionProps) => {
  if (isLoading || (!isConnected && !isOffline)) return null

  if (isOffline) {
    return (
      <Button
        variant="subtle"
        icon={<Icons.Copy className="w-3.5 fill-current text-current" />}
        onClick={handleSyncCodeClick}
      >
        Sync code
      </Button>
    )
  }

  return (
    <Tooltip content={toolTipMessage} containerClassName="flex">
      <Icons.CheckCircle
        className={classNames('w-3.5 h-3.5', {
          'fill-success-default': available,
          'fill-warning-default': !available,
        })}
      />
      <span className="font-medium text-xs ml-2">Linked to {eCommerceDisplayName[eCommerce]} product</span>
    </Tooltip>
  )
}

export default LinkSection
