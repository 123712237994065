import { HelperText, InputField, Label, NumberInput, Select } from '@packages/sk8/input'
import { FormikProps } from 'formik'
import React from 'react'

import { CreateCustomPlanFormValues } from '../hooks/useCustomPlanForm'
export interface CustomPlanModalProps {
  formik: FormikProps<CreateCustomPlanFormValues>
}

const whiteLabelOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const CustomPlanForm = ({ formik }: CustomPlanModalProps) => {
  return (
    <div className="flex flex-col space-y-6 text-neutral-900 basis-1/2">
      <InputField>
        <Label htmlFor="price">Monthly price</Label>
        <NumberInput
          min={0}
          id="price"
          name="price.recurringAmount"
          placeholder="Enter monthly price"
          value={formik.values.price.recurringAmount}
          onValueChange={value => formik.setFieldValue('price.recurringAmount', value)}
          onBlur={formik.handleBlur}
          hasError={formik.touched.price?.recurringAmount && formik.errors.price?.recurringAmount != null}
          prefix="$ "
          allowNegative={false}
        />
        {formik.touched.price?.recurringAmount && formik.errors.price?.recurringAmount != null && (
          <HelperText hasError>{formik.errors.price?.recurringAmount}</HelperText>
        )}
      </InputField>

      <InputField>
        <Label htmlFor="transactionFeePercentage">Transaction fee</Label>
        <NumberInput
          min={0}
          id="transactionFeePercentage"
          name="price.transactionFeePercentage"
          placeholder="Enter transaction fee"
          value={formik.values.price?.transactionFeePercentage}
          onValueChange={value => formik.setFieldValue('price.transactionFeePercentage', value)}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.price?.transactionFeePercentage && formik.errors.price?.transactionFeePercentage != null
          }
          increment={0.1}
          suffix=" %"
          allowNegative={false}
        />
        {formik.touched.price?.transactionFeePercentage && formik.errors.price?.transactionFeePercentage != null && (
          <HelperText hasError>{formik.errors.price?.transactionFeePercentage}</HelperText>
        )}
      </InputField>

      <InputField>
        <Label htmlFor="productLimit">Product limit</Label>
        <NumberInput
          min={0}
          id="productLimit"
          name="features.productLimit"
          placeholder="Enter last product limit (leave empty for unlimited)"
          value={formik.values.features.productLimit}
          onValueChange={value => {
            formik.setFieldValue('features.productLimit', value)

            formik.setFieldValue(
              'attributesText[0]',
              !value ? 'Unlimited products' : `${value} product${value > 1 ? 's' : ''} `
            )
          }}
          onBlur={formik.handleBlur}
          hasError={formik.touched.features?.productLimit && formik.errors.features?.productLimit != null}
          allowNegative={false}
        />
        {formik.touched.features?.productLimit && formik.errors.features?.productLimit != null && (
          <HelperText hasError>{formik.errors.features.productLimit}</HelperText>
        )}
      </InputField>

      <InputField>
        <Label htmlFor="whiteLabel">White label</Label>
        <Select
          inputId="whiteLabel"
          name="features.whiteLabel"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          value={whiteLabelOptions.find(option => option.value === formik.values.features.whiteLabel)}
          options={whiteLabelOptions}
          onChange={option => {
            formik.setFieldValue('features.whiteLabel', option!.value)

            let attributesText = formik.values.attributesText

            const attributeTextIndex = attributesText.findIndex(text => text === 'White label')

            if (option!.value && attributeTextIndex === -1) {
              attributesText = [...attributesText, 'White label']
            }
            if (!option!.value && attributeTextIndex !== -1) {
              attributesText = attributesText.filter(text => text !== 'White label')
            }

            formik.setFieldValue('attributesText', attributesText)
          }}
          onBlur={formik.handleBlur}
          hasError={formik.touched.features?.whiteLabel && formik.errors.features?.whiteLabel != null}
        />
        {formik.touched.features?.whiteLabel && formik.errors.features?.whiteLabel != null && (
          <HelperText hasError>{formik.errors.features.whiteLabel}</HelperText>
        )}
      </InputField>
    </div>
  )
}

export default CustomPlanForm
