import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import React from 'react'

export interface LayersOrderingEmptyStateProps {
  onGoToQuestionsClick: () => void
}

const LayersOrderingEmptyState = ({ onGoToQuestionsClick }: LayersOrderingEmptyStateProps) => (
  <div className="flex flex-col h-full">
    <div className="px-6 mt-28">
      <div className="w-8 h-8 bg-neutral-75 rounded-md mb-4 p-2.5">
        <Icons.Layers />
      </div>
      <div className="font-medium mb-2">There are no layers, yet</div>
      <div className="text-neutral-600 mb-4">Your layers will be created as you create questions.</div>
      <div>
        <Button onClick={onGoToQuestionsClick}>Go to questions</Button>
      </div>
    </div>
  </div>
)

export default LayersOrderingEmptyState
