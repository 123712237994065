import { BlankState } from '@packages/sk8/blank-state'
import { Button } from '@packages/sk8/button'
import { ToastType, useToast } from '@packages/sk8/toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import React, { ComponentType, useState } from 'react'
import { useParams } from 'react-router'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { NetworkError } from 'common/api/types/error'
import useGoBack from 'common/hooks/useGoBack'
import { Icons } from '@packages/sk8/icons'
import type { Plugin as PluginType } from 'plugins/types/plugin'

import usePluginService from './../hooks/usePluginService'

const Plugin = () => {
  const { id } = useParams<{ id: string }>()
  const goBack = useGoBack()
  const [PluginSettings, setPluginSettings] = useState<ComponentType<{
    plugin: PluginType
    updateSettings: (updatedSettings: Record<string, any>) => void
  }> | null>(null)
  const { openToast, openGenericErrorToast } = useToast()
  const [settings, setSettings] = useState({})
  const [isLoadingSettings, setIsLoadingSettings] = useState(false)
  const pluginService = usePluginService()

  const { data: plugin, isLoading } = useQuery([...pluginService.fetch.queryKeys, id], () => pluginService.fetch(id), {
    onSuccess: plugin => {
      if (plugin.native) {
        setIsLoadingSettings(true)

        import(`plugins/${plugin.location}`).then(loadedPlugin => {
          setPluginSettings(loadedPlugin.default.Settings)
          setIsLoadingSettings(false)
        })
      }

      setSettings(plugin.settings)
    },
  })

  const { mutate: updatePlugin, isLoading: isSaving } = useMutation<PluginType, NetworkError, PluginType>(
    plugin => pluginService.update(plugin.id, plugin),
    {
      onSuccess: () => openToast('Plugin was successfully updated!', ToastType.success),
      onError: () => openGenericErrorToast('Plugin has not been updated.'),
    }
  )

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />
      {!isLoading && !isLoadingSettings && plugin && (
        <Page>
          <Page.Header>
            <h1>{plugin.name}</h1>
            <div className="flex relative">
              <Button type="button" onClick={goBack} className="mr-3">
                Cancel
              </Button>
              <Button
                variant="primary"
                type="button"
                disabled={isSaving || isEqual(settings, plugin.settings) || !PluginSettings}
                isLoading={isSaving}
                onClick={() => updatePlugin({ ...plugin, settings: { ...plugin.settings, ...settings } })}
              >
                Save
              </Button>
            </div>
          </Page.Header>

          {PluginSettings && (
            <PluginSettings
              plugin={{ ...plugin, settings: { ...plugin.settings, ...settings } }}
              updateSettings={updatedSettings => setSettings({ ...settings, ...updatedSettings })}
            />
          )}

          {plugin && !PluginSettings && (
            <div className="flex items-center justify-center py-32">
              <BlankState>
                <BlankState.Icon Icon={Icons.LegoBlock} />
                <BlankState.Title>There are no settings for this plugin</BlankState.Title>
                <BlankState.Details>
                  Other plugin settings will be here. For the moment, you can return to the plugin list
                </BlankState.Details>
                <BlankState.Action onClick={goBack}>Go back</BlankState.Action>
              </BlankState>
            </div>
          )}
        </Page>
      )}
    </main>
  )
}

export default Plugin
