import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { NormalizedCustomizerProduct, Rule } from '@packages/types'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import { bulkOrderGroupChildrenSelector } from 'builder/build/groups/selectors'
import { useSelector } from 'cms/hooks'

import isValidRule from '../isValidRule'
import RuleBuilder from './RuleBuilder/RuleBuilder'

export interface EditedRuleProps {
  rule: Rule
  customizerProduct: NormalizedCustomizerProduct
  updateRule: (rule: Rule) => void
  cancel: () => void
}

const EditedRule = ({ rule, customizerProduct, updateRule, cancel }: EditedRuleProps) => {
  const [editedRule, setEditedRule] = useState(rule)
  const [isEditedRuleValid, setIsEditedRuleValid] = useState(false)

  const bulkOrderGroupChildren = useSelector(bulkOrderGroupChildrenSelector)

  useEffect(() => {
    setIsEditedRuleValid(
      isValidRule({ rule: editedRule, groups: customizerProduct.groups, questions: customizerProduct.questions })
    )
  }, [editedRule, customizerProduct.questions])

  const isRuleInBulkOrder = editedRule.when?.some(({ path }) => bulkOrderGroupChildren.includes(path[1]))

  return (
    <RuleBuilder
      className="mt-[-1px]"
      rule={editedRule}
      updateRule={setEditedRule}
      customizerProduct={customizerProduct}
    >
      <div
        className={classNames('px-6 py-3 border-t border-solid border-neutral-75 flex space-x-1', {
          'justify-between': isRuleInBulkOrder,
          'justify-end': !isRuleInBulkOrder,
        })}
      >
        {isRuleInBulkOrder && (
          <div className="flex items-center space-x-2 text-neutral-500 fill-neutral-500">
            <Icons.UserAdd className="w-4 h-4" />
            <span>You’re editing a rule within a bulk order</span>
          </div>
        )}
        <div className="flex space-x-1">
          <Button type="button" onClick={cancel}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!isEditedRuleValid} onClick={() => updateRule(editedRule)}>
            Done
          </Button>
        </div>
      </div>
    </RuleBuilder>
  )
}

export default EditedRule
