import { Tag } from '@packages/sk8/tag'
import { Tooltip } from '@packages/sk8/tooltip'
import classNames from 'classnames'
import React from 'react'

import { Icons } from '@packages/sk8/icons'

const ContinueSellingTag = ({ className }: { className?: string }) => (
  <Tooltip content="Continue selling when out of stock">
    <Tag className={classNames('bg-tertiary-green-100 text-tertiary-green-700', className)}>
      <Icons.TimerReset className="text-tertiary-green-700 w-3 h-3 fill-current" aria-label="Continue selling" />
    </Tag>
  </Tooltip>
)

export default ContinueSellingTag
