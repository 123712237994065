import { Addon, CustomPlan, Plan } from '@packages/types'
import React from 'react'

import { Icons } from '@packages/sk8/icons'

export interface TotalCardContentProps {
  plan: Plan | CustomPlan
  addons: Addon[]
}

const Reviews = () => {
  return (
    <>
      <div className="mt-4 flex flex-col space-y-4 items-left text-sm leading-[18px] font-medium w-full">
        <div className="flex items-center justify-center w-full">
          <Icons.G2 className="w-6 h-6 mr-4" />
          <div className="h-7 border-r border-neutral-100 mr-5" />
          <Icons.Capterra className="w-14 mr-5" />
          <div className="h-7 border-r border-neutral-100 mr-5" />
          <Icons.FourPointFiveStar className="h-4" />
        </div>
      </div>
    </>
  )
}

export default Reviews
