import { Icons } from '@packages/sk8/icons'
import { UniversalSearchList } from '@packages/sk8/universal-search'
import React from 'react'

import * as navigationActions from 'builder/build/navigation/actions'
import { LeftPanel } from 'builder/build/navigation/types/leftPanel'
import { useDispatch } from 'cms/hooks'

interface QuickActionManageViewsProps {
  onClick: () => void
  query: string
}

const QuickActionManageViews = ({ onClick, query }: QuickActionManageViewsProps) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(navigationActions.setLeftPanel(LeftPanel.settings))
    onClick()
  }

  return (
    <UniversalSearchList.Result onClick={handleClick} className="text-primary-500">
      <UniversalSearchList.HighlightableText text="Manage views" highlight={query} />
      <div className="ml-auto">
        <div className="flex space-x-1 text-xs text-neutral-300 items-center">
          <Icons.Cog className="h-2.5 w-2.5 fill-neutral-300" aria-label="Settings icon" />
          <Icons.ChevronRight className="h-2 w-2 fill-neutral-300" /> <div>Product views</div>
        </div>
      </div>
    </UniversalSearchList.Result>
  )
}

export default QuickActionManageViews
