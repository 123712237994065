import { Button } from '@packages/sk8/button'
import { HelperText, InputField, NumberInput } from '@packages/sk8/input'
import { Popover, usePopover } from '@packages/sk8/popover'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as yup from 'yup'

import { Icons } from '@packages/sk8/icons'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import { PriceBreak } from '../types'

export interface AddBulkOrderPriceBreakPopoverProps {
  addPriceBreak: ({ quantity, price }: PriceBreak) => void
  currenciesInputProps: { leftAddon?: string; rightAddon?: string }
  bulkPrices: PriceBreak[]
  disabledButton: boolean
}

const AddBulkOrderPriceBreakPopover = ({
  addPriceBreak,
  currenciesInputProps,
  bulkPrices,
  disabledButton,
}: AddBulkOrderPriceBreakPopoverProps) => {
  const popover = usePopover({
    placement: 'bottom',
    useArrow: true,
    shiftConfig: { mainAxis: false, crossAxis: true, padding: 54 },
  })

  const handleAddPriceBreak = (priceBreak: PriceBreak) => addPriceBreak(priceBreak)

  const handleAddPrice = (priceBreak: PriceBreak, shouldClosePopup?: boolean): void => {
    if (shouldClosePopup) popover.close()

    handleAddPriceBreak(priceBreak)
    formik.resetForm()
  }

  const validatingExistingQuantity = (quantity: number | null | undefined | object) => {
    return !bulkPrices?.some(prices => parseInt(prices.quantity) === quantity)
  }

  const BulkPriceBreakSchema = yup.object().shape({
    quantity: yup
      .number()
      .required('Required')
      .typeError('Please enter a valid quantity')
      .min(1, 'Quantity must be positive')
      .moreThan(1, 'Quantity must be greater than 1')
      .test('price', 'This quantity break already exist', validatingExistingQuantity),
    price: yup
      .number()
      .required('Required')
      .typeError('Please enter a valid price')
      .min(0, 'Price must be positive')
      .moreThan(0, 'Price must be greater than 0')
      .noWhitespace(),
  })

  const formik = useFormik({
    initialValues: { quantity: '', price: '' },
    onSubmit: handleAddPriceBreak,
    validationSchema: BulkPriceBreakSchema,
    validateOnChange: true,
  })

  useEffect(() => {
    if (!popover.isOpen) formik.resetForm()
  }, [popover.isOpen])

  return (
    <>
      <Button
        disabled={disabledButton}
        icon={<Icons.Add className="w-2.5 h-2.5" />}
        type="button"
        {...popover.referenceProps}
      >
        Price Break
      </Button>
      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        <form>
          <div className="flex flex-col p-2">
            <div className="flex items-center space-x-4 ml-1">
              <span>From</span>
              <InputField>
                <NumberInput
                  id="quantity"
                  name="quantity"
                  placeholder="Quantity"
                  value={formik.values.quantity}
                  onValueChange={value => formik.setFieldValue('quantity', value)}
                  min={2}
                  hasError={!!formik.touched.quantity && !!formik.errors.quantity}
                />
              </InputField>
            </div>

            {formik.touched.quantity && formik.errors.quantity && (
              <HelperText hasError className="ml-1">
                {formik.errors.quantity}
              </HelperText>
            )}

            <InputField className="ml-1 mt-3">
              <NumberInput
                id="price"
                name="price"
                placeholder="Price"
                value={formik.values.price}
                onValueChange={value => formik.setFieldValue('price', value)}
                hasError={!!formik.touched.price && !!formik.errors.price}
                min={0}
                rightAddon={currenciesInputProps.rightAddon ? `${currenciesInputProps?.rightAddon}/unit` : ''}
                showArrows={false}
              />
            </InputField>

            {formik.touched.price && formik.errors.price && (
              <HelperText hasError className="ml-1">
                {formik.errors.price}
              </HelperText>
            )}

            <div className="flex justify-end mt-3">
              <Button
                id="new-prices-break-add"
                className="mr-2"
                onClick={async () => {
                  formik.setTouched({ price: true, quantity: true })
                  formik.validateForm(formik.values).then(errors => {
                    if (isNullOrEmpty(errors)) {
                      handleAddPrice(formik.values)
                    }
                  })
                }}
                type="button"
              >
                Add another
              </Button>
              <Button
                id="new-price-break-add"
                variant="primary"
                type="button"
                onClick={async () => {
                  formik.setTouched({ price: true, quantity: true })
                  formik.validateForm(formik.values).then(errors => {
                    if (isNullOrEmpty(errors)) {
                      handleAddPrice(formik.values, true)
                    }
                  })
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </form>
      </Popover>
    </>
  )
}

export default AddBulkOrderPriceBreakPopover
